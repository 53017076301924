import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AxiosBase from 'axios';

const BaseURL = process.env.REACT_APP_SERVER_URL;
const history = createBrowserHistory();

const Axios = AxiosBase.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

//企業情報取得用
const GetPartnerCompanyListOptions = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_partner_company_list`,
}

//企業情報追加用
const AddCompanyInfoOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/add_company_info`,
};

//企業情報更新用
const updateCompanyInfoOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/update_company_info`,
};

//企業情報削除用
const deleteCompanyInfoOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/delete_company_info`,
};

const getCompanySurveyList = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_company_survey_list`,
}

const updateCompanySurveyList = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/update_company_survey_list`,
}

const GetManagerList = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_manager_list`,
}

const UpdateManagerList = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/update_manager_list`,
}

const DeleteManagerInfoOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/delete_manager_info`,
};

const RegistManagerOptions = {
  method: 'POST',
  withCredentials: true,
  data: null,
  url: `${BaseURL}/admin/regist_manager`,
};

const RequestSendMailOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/send_verification_mail`,
};

const styles = (theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  bottomButtons: {
    marginTop: theme.spacing(4),
  },
  muiDialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 2,
    zIndex: theme.zIndex.drawer + 2000,
    color: '#fff',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


class CheckboxRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }
  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.props.column.colId;
    console.log(this.props.node);
    this.props.node.setDataValue(colId, checked);
  }
  render() {
    return (
      <input type="checkbox" onClick={this.checkedHandler} checked={this.props.value} />
    );
  }
}

//ドロップダウンの表記フォーマット設定
//企業側のサービス「稼動・停止」を表示
const cellEditorParamsStatus = { values: ["running", "stopped"] };
const refDataStatus = { running: "稼働中", stopped: "停止中" };

//AG-Gridデータ管理用カラム
//企業情報リスト
const company_columns = [
  { field: 'company_id', headerName: '企業番号', sortable: true, filter: true, resizable: true, editable: false, checkboxSelection: true, },
  { field: 'company_name', headerName: '企業名', sortable: true, filter: true, resizable: true, editable: true, },
  { field: 'status', headerName: 'ステータス', sortable: true, filter: false, resizable: true, editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsStatus, refData: refDataStatus },
  {
    field: 'survey', headerName: 'アンケート管理', sortable: false, filter: false, resizable: true,
    cellRenderer: function (params) {
      return '<div>アンケート管理</div>'
    }
  },
  {
    field: 'manager', headerName: 'マネージャ管理', sortable: false, filter: false, resizable: true,
    cellRenderer: function (params) {
      return '<div>マネージャ管理</div>'
    }
  },
];

const company_servey_info_columns = [
  { field: 'registed', headerName: '使用中', sortable: true, filter: false, resizable: true, cellRenderer: "checkboxRenderer", },
  { field: 'title', headerName: '登録アンケート', sortable: true, filter: true, resizable: true, },
  { field: 'name', headerName: 'questions_name', sortable: true, filter: true, resizable: true },
];

const manager_list_columns = [
  { field: 'detail_info.company_info.company_id', headerName: '企業番号', sortable: true, filter: true, resizable: true, hide: true, },
  { field: 'detail_info.company_info.company_name', headerName: '企業名', sortable: true, filter: true, resizable: true, hide: true, },
  { field: 'username', headerName: 'ユーザ名', sortable: true, filter: true, resizable: true, checkboxSelection: true, },
  { field: 'lastname', headerName: '姓', sortable: true, filter: true, resizable: true, },
  { field: 'firstname', headerName: '名前', sortable: true, filter: true, resizable: true, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate = '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

const textLabels = {
  LABEL_USER_NAME: "ユーザ名（Eメール形式）",
  LABEL_COMPANY_NAME: "企業名",
  LABEL_USER_AUTHORITY: "ユーザ権限",

  LABEL_INPUT_COMPANY_ID: "企業ID",
  LABEL_INPUT_COMPANY_NAME: "企業名",
  LABEL_INPUT_COMPANY_ADDRESS: "住所",
  LABEL_INPUT_COMPANY_PHONE_NUMBER: "連絡先",
}

//ページの全体構造
class AdminCompanyManagerPage extends React.Component {
  constructor(props) {
    super(props);

    //ステータス初期化
    this.state = {
      company_list_columns: company_columns,
      company_list: null,
      complete_load: false,

      frameworkComponents: {
        checkboxRenderer: CheckboxRenderer
      },

      //各企業アンケートデータ関連
      selected_company_id: null,
      selected_company_name: null,
      company_object_id: null,

      company_servey_manager_open: false,
      company_servey_info: null,
      company_servey_info_columns: company_servey_info_columns,

      manager_management_dialog_open: false,
      manager_list: null,
      manager_list_columns: manager_list_columns,

      add_manager_info_dialog_open: false,
      add_company_info_dialog_open: false,

      delete_company_confirm_dialog_open: false,
      delete_manager_confirm_dialog_open: false,

      add_company_id: "",
      add_company_name: "",
      add_company_address: "",
      add_company_phone_number: "",

      alert_dialog_title: "",
      alert_dialog_message: "",
      alert_dialog_open: false,

      backdrop_open: false,
    };
  }

  componentDidMount(props) {
    //企業情報リスト取得
    Axios(GetPartnerCompanyListOptions).then((res) => {
      if (res.data !== "Unauthorized") {
        const tempCompanyList = res.data;

        Object.keys(tempCompanyList).forEach(function (key) {
          var company = tempCompanyList[key];
          company.id = company._id;
          console.log(company);
        });

        const companyList = tempCompanyList;

        this.setState({
          complete_load: true,
          company_list: companyList,
        });

      } else {
        this.setState({
          complete_load: true,
        })
        console.log("認証失敗")
      }
    }).catch(err => console.log(err));
  }

  //企業情報リスト画面で、「追加」ボタン押下時のイベント
  addCompanyInfo = () => {
    const add_company_id = this.state.add_company_id;
    const add_company_name = this.state.add_company_name;
    const add_company_address = this.state.add_company_address;
    const add_company_phone_number = this.state.add_company_phone_number;

    if (add_company_id === "" || add_company_name === "" || add_company_address === "" || add_company_phone_number === "") {
      this.showAlertDialog("お知らせ", "情報記入欄に空欄があります");
      return;
    }

    const nowDate = new Date();

    const newRowData = {
      company_id: add_company_id,
      company_name: add_company_name,
      regist_date: nowDate,
      status: "stopped",
      company_info: {
        address: add_company_address,
        phone_number: add_company_phone_number,
      },
      questions: [],
    };

    AddCompanyInfoOptions.data = newRowData;

    this.showBackdrop();
    Axios(AddCompanyInfoOptions).then((res) => {
      console.log(res);
      const result = res.data.result;
      if (result == "success") {
        const newCompanyData = res.data.data;

        const newCompanyList = this.state.company_list.slice();
        const newId = this.state.company_list.length === 0 ? 0 : this.state.company_list[this.state.company_list.length - 1].id + 1;
        newCompanyData.id = newId;

        newCompanyList.push(newCompanyData);
        this.setState({
          company_list: newCompanyList,
          add_company_info_dialog_open: false,
        });
        this.showAlertDialog("お知らせ", "企業情報を登録しました");
        this.handleCloseBackdrop();
        this.setState({
          add_company_id: "",
          add_company_name: "",
          add_company_address: "",
          add_company_phone_number: "",
        });
      } else if (result == "failed") {
        const reason = res.data.reason;
        if (reason == "Company Already Exists") {
          this.showAlertDialog("お知らせ", "すでに存在する企業情報です");
          this.handleCloseBackdrop();
        }
      }
    }).catch(err => {
      console.log(err);
      this.showAlertDialog("お知らせ", "企業情報登録に失敗しました");
      this.handleCloseBackdrop();
    });
  }

  //企業情報リスト画面で、「削除」ボタン押下時のイベント
  deleteCompanyInfo = () => {
    const selectedNodes = this.gridApiCompanyList.getSelectedNodes();
    // const selectedNodes = this.gridApiCompanyList.getSelectedRows();
    const selectedData = selectedNodes.map(node => node.data);

    const data = {
      company_data: selectedData,
    }

    deleteCompanyInfoOptions.data = data;

    Axios(deleteCompanyInfoOptions).then((res) => {
      console.log(res);
      if (res.data === "Delete Success") {
        console.log("Delete Success");
        window.location.reload();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  //企業情報リスト画面で、「更新」ボタン押下時のイベント
  updateCompanyInfo = () => {
    const rowDatas = [];
    const gridApiData = this.gridApiCompanyList;

    gridApiData.forEachNode(node => {
      const company_id = node.data.company_id;
      const company_name = node.data.company_name;
      const status = node.data.status;
      rowDatas.push(node.data)
    });

    console.log(rowDatas);

    const data = {
      company_data: rowDatas,
    }

    updateCompanyInfoOptions.data = data;

    Axios(updateCompanyInfoOptions).then((res) => {
      console.log(res);
      if (res.data === "Update Success") {
        console.log("Update Success");
        window.location.reload();
      }
    }).catch(err => {
      console.log(err);
      console.log(err.response.data)
      if (err.response.data === "Company Name Null") {
        this.showAlertDialog("お知らせ", "会社名が空欄です")
      }
    });
  }

  deleteCompanyInfo_DialogOpen = (event) => {
    const selectedRows = this.gridApiCompanyList.getSelectedRows();

    if (selectedRows == false) {
      this.showAlertDialog("お知らせ", "項目が選択されていません");
    } else {
      this.setState({
        delete_company_confirm_dialog_open: true,
      });
    }
  };

  deleteCompanyInfo_DialogClose = (event) => {
    this.setState({
      delete_company_confirm_dialog_open: false,
    });
  };

  //
  handleCellClicked = (event) => {
    if (event.column.getColId() === 'survey') {
      const rowData = event.data;

      const params = {
        company_name: rowData.company_name,
      }

      getCompanySurveyList.params = params;

      Axios(getCompanySurveyList).then((res) => {
        console.log(res.data);

        const result_data = res.data;

        console.log(result_data.question_result);
        console.log(result_data.company_name);

        if (res.data === "Unauthorized") {
          this.setState({
            complete_load: true,
          })
          console.log("認証失敗");
        } else if (res.data === "") {
        } else {
          this.setState({
            complete_load: true,
            selected_company_name: result_data.company_name,
            company_object_id: result_data.object_id,
            company_servey_info: result_data.question_result //メインデータ             
          });
        }
      }).catch(err => {
        console.log(err)
      });

      this.setState({
        company_servey_manager_open: true,
      });
    } else if (event.column.getColId() === 'manager') {
      const rowData = event.data;
      const selected_company_id = rowData.company_id;
      const selected_company_name = rowData.company_name;

      const params = {
        company_id: rowData.company_id,
        company_name: rowData.company_name,
      }

      GetManagerList.params = params;

      Axios(GetManagerList).then((res) => {
        const manager_list = res.data.manager_list;
        this.setState({
          complete_load: true,
          selected_company_id: selected_company_id,
          selected_company_name: selected_company_name,
          manager_list: manager_list,
        });
      }).catch(err => {
        console.log(err)
      });

      this.setState({
        manager_management_dialog_open: true,
      });
    }
  }

  //
  updateCompanySurvey = () => {

    this.setState({
      closeUpdateCompanySurveyDialogOpen: true,
    });

    const rowDatas = [];
    const gridApiData = this.getCompanySurveyList;

    const use_questions = [];
    gridApiData.forEachNode(node => {
      rowDatas.push(node.data)
      if (node.data.registed == true) {
        use_questions.push(node.data.name)
      }
    });

    console.log(rowDatas);

    // const company_name = this.state.selected_company_name;
    const object_id = this.state.company_object_id;

    const data = {
      // company_data:rowDatas,
      use_questions: use_questions,
      // company_name: company_name,
      object_id: object_id,
    }

    updateCompanySurveyList.data = data;

    Axios(updateCompanySurveyList).then((res) => {
      console.log(res);
      if (res.data === "Update Success") {
        console.log("Update Success");
        // window.location.reload();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  //
  updateManagerList = () => {

    this.setState({
      closeUpdateCompanySurveyDialogOpen: true,
    });

    const rowDatas = [];
    const gridApiData = this.getCompanySurveyList;

    const use_questions = [];
    gridApiData.forEachNode(node => {
      rowDatas.push(node.data)
      if (node.data.registed == true) {
        use_questions.push(node.data.name)
      }
    });

    console.log(rowDatas);

    // const company_name = this.state.selected_company_name;
    const object_id = this.state.company_object_id;

    const data = {
      // company_data:rowDatas,
      use_questions: use_questions,
      // company_name: company_name,
      object_id: object_id,
    }

    updateCompanySurveyList.data = data;

    Axios(updateCompanySurveyList).then((res) => {
      console.log(res);
      if (res.data === "Update Success") {
        console.log("Update Success");
        // window.location.reload();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  deleteManagerInfo = () => {
    const selectedNodes = this.gridApiManagerList.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);

    const data = {
      manager_data: selectedData,
    }

    DeleteManagerInfoOptions.data = data;

    Axios(DeleteManagerInfoOptions).then((res) => {
      console.log(res);
      if (res.data === "Delete Success") {
        console.log("Delete Success");
        window.location.reload();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  deleteManagerInfo_DialogOpen = (event) => {
    const selectedRows = this.gridApiManagerList.getSelectedRows();

    if (selectedRows == false) {
      this.showAlertDialog("お知らせ", "項目が選択されていません");
    } else {
      this.setState({
        delete_manager_confirm_dialog_open: true,
      });
    }
  };

  deleteManagerInfo_DialogClose = (event) => {
    this.setState({
      delete_manager_confirm_dialog_open: false,
    });
  };

  sendVerificationMail = () => {
    const selectedNodes = this.gridApiManagerList.getSelectedNodes();

    if (selectedNodes.length == 0) {
      this.showAlertDialog("注意", "ユーザを選択してください");
      return;
    }

    const selectedData = selectedNodes.map(node => node.data);

    if (selectedData.length > 1)
      return;

    const data = {
      user_data: selectedData[0],
    }

    RequestSendMailOptions.data = data;

    this.showBackdrop();
    Axios(RequestSendMailOptions).then((res) => {
      console.log(res);
      this.showAlertDialog("お知らせ", "認証メールを送信しました");
      this.handleCloseBackdrop();
    }).catch(err => {
      console.log(err);
      this.showAlertDialog("注意", "認証メール送信に失敗しました");
      this.handleCloseBackdrop();
    });

    this.setState({
      backdrop_open: true,
    })
  }

  registManager = () => {
    const username = this.state.add_username;

    if (username === undefined || username === "") {
      this.showAlertDialog("お知らせ", "メールアドレス欄が空欄です");
      return;
    }

    const manager_info = {
      username: username,
      firstname: "",
      lastname: "",
      authority: "manager_authority",
      detail_info: {
        questions: [
          "basic_question",
        ],
        company_info: {
          company_id: this.state.selected_company_id,
          company_name: this.state.selected_company_name,
        },
      }
    }

    const data = {
      user_data: manager_info,
    }

    RegistManagerOptions.data = data;

    this.showBackdrop();
    Axios(RegistManagerOptions).then((res) => {
      if (res.data.result === "success") {
        this.showAlertDialog("お知らせ", "登録に成功しました");

        const newRowData = this.state.manager_list.slice();
        const newId = this.state.manager_list.length === 0 ? 0 : this.state.manager_list[this.state.manager_list.length - 1].id + 1;
        const newRow = res.data.user;
        newRow.id = newId;

        newRowData.push(newRow);
        this.setState({
          manager_list: newRowData,
          add_manager_info_dialog_open: false,
        });

        this.setState({
          add_username: "",
        })

      } else if (res.data.result === "failed") {
        if (res.data.reason === "User Info Already Exists") {
          this.showAlertDialog("お知らせ", "すでに登録されているアカウント名です");
        } else {
          this.showAlertDialog("お知らせ", "マネージャ登録に失敗しました");
        }
      }

      this.handleCloseBackdrop();
    }).catch((err) => {
      console.log(err);
      this.handleCloseBackdrop();
    });
  }

  //
  closeUpdateCompanySurvey_DialogClose = (event) => {
    this.setState({
      closeUpdateCompanySurveyDialogOpen: false,
      company_servey_manager_open: false,
    });
  };

  //
  handleCloseCompanyServeyManager = (event) => {
    this.setState({
      company_servey_manager_open: false,
      company_servey_info: null,
    });
  };

  //
  handleCloseManageManagementDialog = (event) => {
    this.setState({
      manager_management_dialog_open: false,
      manager_list: null,
    });
  };

  handleCloseManagerInfoDialog = (event) => {
    this.setState({
      add_manager_info_dialog_open: false,
    });
  }

  handleCloseAddCompanyInfoDialog = (event) => {
    this.setState({
      add_company_info_dialog_open: false,
    })
  }

  showAddManagerInfoDialog = () => {
    this.setState({
      add_manager_info_dialog_open: true,

    });
  }

  showAddCompanyInfoDialog = () => {
    this.setState({
      add_company_info_dialog_open: true,
    });
  }

  showAlertDialog = (title, message) => {
    this.setState({
      alert_dialog_title: title,
      alert_dialog_message: message,
      alert_dialog_open: true,
    });
  }

  showBackdrop = () => {
    this.setState({
      backdrop_open: true,
    })
  }

  handleChangeAddManagerUserName = (e) => {
    this.setState({
      add_username: e.target.value,
    })
  }

  handleChangeAddCompanyName = (e) => {
    this.setState({
      add_company_name: e.target.value,
    })
  }

  handleCloseAlertDialog = (event) => {
    this.setState({
      alert_dialog_open: false,
    })
  }

  handleCloseBackdrop = () => {
    this.setState({
      backdrop_open: false,
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={12}>
              <div
                className="ag-theme-material"
                style={{
                  height: 400,
                }}>
                <Button onClick={this.showAddCompanyInfoDialog} color="primary" >企業情報追加</Button>
                <Button onClick={this.deleteCompanyInfo_DialogOpen} color="primary" >企業情報削除</Button>
                <Button onClick={this.updateCompanyInfo} color="primary">企業リスト更新</Button>
                <AgGridReact
                  columnDefs={this.state.company_list_columns}
                  rowData={this.state.company_list}
                  enableMultiRowDragging={true}
                  onGridReady={(params) => {
                    this.gridApiCompanyList = params.api
                    this.gridApiCompanyColumns = params.columnApi
                  }}
                  loadingCellRendererParams
                  singleClickEdit={true}
                  frameworkComponents={this.state.frameworkComponents}
                  overlayLoadingTemplate={overlayLoadingTemplate}
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                  onCellClicked={this.handleCellClicked}
                  onRowDataChanged={(params) => {
                    const allColumnIds = [];
                    this.gridApiCompanyColumns.getAllColumns().forEach(function (column) {
                      allColumnIds.push(column.colId);
                    });
                    this.gridApiCompanyColumns.autoSizeColumns(allColumnIds, false);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={this.goBack} variant="outlined" color="primary" >
                戻る
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Backdrop className={classes.backdrop} open={this.state.backdrop_open} >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog maxWidth="xs" onClose={this.handleCloseAddCompanyInfoDialog} aria-labelledby="customized-dialog-title" open={this.state.add_company_info_dialog_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseAddCompanyInfoDialog}>
            企業情報追加
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={(e) => {
                    this.setState({
                      add_company_id: e.target.value,
                    })
                  }}
                  label={textLabels.LABEL_INPUT_COMPANY_ID}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={(e) => {
                    this.setState({
                      add_company_name: e.target.value,
                    })
                  }}
                  label={textLabels.LABEL_INPUT_COMPANY_NAME}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={(e) => {
                    this.setState({
                      add_company_address: e.target.value,
                    })
                  }}
                  label={textLabels.LABEL_INPUT_COMPANY_ADDRESS}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={(e) => {
                    this.setState({
                      add_company_phone_number: e.target.value,
                    })
                  }}
                  label={textLabels.LABEL_INPUT_COMPANY_PHONE_NUMBER}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.addCompanyInfo} color="primary">
              登録
            </Button>
            <Button onClick={this.handleCloseAddCompanyInfoDialog} color="primary">
              戻る
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.closeUpdateCompanySurveyDialogOpen} onClose={this.closeUpdateCompanySurvey_DialogClose}>
          <DialogTitle id="alert-dialog-title">{"案内"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              使用するアンケート選択を更新しました。
              元の画面に戻ります。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeUpdateCompanySurvey_DialogClose} color="primary">
              戻る
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.delete_company_confirm_dialog_open}
          onClose={this.deleteCompanyInfo_DialogClose}
        >
          <DialogTitle id="alert-dialog-title">{"注意"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              削除した内容は復元できません。本当に削除してもよろしいでしょうか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteCompanyInfo_DialogClose} color="primary">
              いいえ
            </Button>
            <Button onClick={this.deleteCompanyInfo} color="primary" autoFocus>
              はい
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.delete_manager_confirm_dialog_open}
          onClose={this.deleteManagerInfo_DialogClose}
        >
          <DialogTitle id="alert-dialog-title">{"注意"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              削除した内容は復元できません。本当に削除してもよろしいでしょうか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteManagerInfo_DialogClose} color="primary">
              いいえ
            </Button>
            <Button onClick={this.deleteManagerInfo} color="primary" autoFocus>
              はい
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="md" onClose={this.handleCloseCompanyServeyManager} aria-labelledby="customized-dialog-title" open={this.state.company_servey_manager_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseCompanyServeyManager}>
            企業アンケート管理
          </DialogTitle>
          <DialogContent dividers>
            <div
              className="ag-theme-material"
              style={{
                width: 800,
                height: 400,
              }}
            >
              <AgGridReact
                columnDefs={this.state.company_servey_info_columns}
                rowData={this.state.company_servey_info}
                enableMultiRowDragging={true}
                onGridReady={(params) => {
                  this.getCompanySurveyList = params.api
                  this.getCompanySurveyColumns = params.columnApi
                }}
                loadingCellRendererParams
                singleClickEdit={true}
                rowSelection="multiple"
                overlayLoadingTemplate={overlayLoadingTemplate}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
                frameworkComponents={this.state.frameworkComponents}
                onRowDataChanged={(params) => {
                  const allColumnIds = [];
                  this.getCompanySurveyColumns.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                  });
                  this.getCompanySurveyColumns.autoSizeColumns(allColumnIds, false);
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.updateCompanySurvey} color="primary">
              更新
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="md" onClose={this.handleCloseManageManagementDialog} aria-labelledby="customized-dialog-title" open={this.state.manager_management_dialog_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseManageManagementDialog}>
            マネージャ管理
          </DialogTitle>
          <DialogContent dividers>
            {/* <Button onClick={this.addCompanyServey} color="primary">追加</Button> */}
            <div
              className="ag-theme-material"
              style={{
                width: 800,
                height: 400,
              }}
            >
              <Button onClick={this.showAddManagerInfoDialog} color="primary">マネージャ追加</Button>
              <Button onClick={this.deleteManagerInfo_DialogOpen} color="primary">マネージャ削除</Button>
              <Button onClick={this.sendVerificationMail} color="primary">認証メール送信</Button>
              <AgGridReact
                columnDefs={this.state.manager_list_columns}
                rowData={this.state.manager_list}
                enableMultiRowDragging={true}
                onGridReady={(params) => {
                  this.gridApiManagerList = params.api
                  this.gridApiManagerListColumns = params.columnApi
                }}
                loadingCellRendererParams
                singleClickEdit={true}
                rowSelection="multiple"
                overlayLoadingTemplate={overlayLoadingTemplate}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
                frameworkComponents={this.state.frameworkComponents}
                onRowDataChanged={(params) => {
                  const allColumnIds = [];
                  this.gridApiManagerListColumns.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                  });
                  this.gridApiManagerListColumns.autoSizeColumns(allColumnIds, false);
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.updateCompanySurvey} color="primary">
              更新
            </Button> */}
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="md" onClose={this.handleCloseManagerInfoDialog} aria-labelledby="customized-dialog-title" open={this.state.add_manager_info_dialog_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseManagerInfoDialog}>
            マネージャ追加
          </DialogTitle>
          <DialogContent dividers>
            <div
              className="ag-theme-material"
              style={{
                width: 400,
                height: 200,
              }}
            >
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    onChange={(e) => {
                      this.setState({
                        add_username: e.target.value,
                      })
                    }}
                    label={textLabels.LABEL_USER_NAME}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} className={classes.margin}>
                  <Typography variant="h7" color="primary">
                    登録するユーザ名(Eメール形式)を入力してください。
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.registManager} color="primary">
              登録
            </Button>
            <Button onClick={() => { this.setState({ add_manager_info_dialog_open: false, }) }} color="primary">
              戻る
            </Button>
          </DialogActions>
        </Dialog>

        {/* 単純なお知らせのダイアログ */}
        <Dialog
          open={this.state.alert_dialog_open}
          onClose={this.handleCloseAlertDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <MuiDialogTitle id="alert-dialog-title">{this.state.alert_dialog_title}</MuiDialogTitle>
          <MuiDialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert_dialog_message}
            </DialogContentText>
          </MuiDialogContent>
          <MuiDialogActions>

            <Button onClick={this.handleCloseAlertDialog} color="primary" autoFocus>
              確認
            </Button>
          </MuiDialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AdminCompanyManagerPage);