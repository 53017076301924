import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import AxiosBase from 'axios';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';


const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',   
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

const styles = (theme => ({
    form: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    buttons: {
        marginTop: theme.spacing(4),
    },
    textField: {
        padding: theme.spacing(0, 0, 4),
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
    },
}
}));

class UserQuestionPage extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
          model: null,
          duplicates: null,
          surveyExist: false,
          surveyNotExist: false,
          loading: true
        };
    }
    
    componentDidMount(props){

        this.setState({
            loading: true
        })

        var defaultThemeColors = Survey
        .StylesManager
        .ThemeColors["default"];
        defaultThemeColors["$main-color"] = "#ff96b4";
        defaultThemeColors["$main-hover-color"] = "#ff9696";
        defaultThemeColors["$text-color"] = "#4a4a4a";
        defaultThemeColors["$header-color"] = "#ff96b4";

        defaultThemeColors["$header-background-color"] = "#4a4a4a";
        // defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

        Survey
            .StylesManager
            .applyTheme("default");

        // var json = {};
        var json = { "completedHtml": "<h3>アンケートにご協力いただきありがとうございました</h3>", pages: [] };

        const newDate = new Date();
        const yearmonth = `${newDate.getFullYear()}-${(('00' + (newDate.getMonth()+1)).slice(-2))}`;
        
        Axios({
            method: "POST",
            data: {
                answer_date: yearmonth
            },
            withCredentials: true,
            url: `${BaseURL}/user/get_survey_questions`            
        }).then(results => {
            if (results.data === "Survey Already Exists") {
                console.log("今月のアンケートが登録されています。")                
                //   window.location.href = "/usermain";

                this.setState({
                    duplicates: "今月のアンケートが登録されています。",
                    surveyExist: true,
                    loading: false
                });

            } else {
                console.log(results.data);
                const json = results.data;

                const modelOption = new Survey.Model(json);

                modelOption
                    .onComplete
                    .add(function(result) {
                        result = result.data;

                        Axios({
                            method: "POST",
                            data: {
                                result
                            },
                            withCredentials: true,
                            url: `${BaseURL}/user/update_survey_result`                             
                        }).then((res) => {
                            console.log(res.data);
                            if (res.data === "Survey Result Saved") {
                                //   window.location.href = "/usermain";
                            }
                        });
                        document
                            .querySelector('#surveyResult')
                            // .textContent = "Result JSON:\n" + JSON.stringify(result, null, 10);
                    });

                this.setState({
                    model: modelOption,
                    surveyNotExist: true,
                    loading: false                    
                });
            }
        }).catch(err => console.log(err));    
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { classes } = this.props;
        
        const surveyExist = this.state.surveyExist;
        const surveyNotExist = this.state.surveyNotExist;
        const loading = this.state.loading;
        return (
            <div className={classes.form}>
                <Container maxWidth="md" >
                    {loading &&
                        <Grid container justify="center">
                        <CircularProgress />
                        </Grid>
                    }
                    <div className={classes.buttons}>
                        {surveyExist &&
                            <Paper className={classes.paper}>
                                {this.state.duplicates}
                            </Paper>  
                        }       
                        {surveyNotExist &&
                            <Paper className={classes.paper}>
                                <Survey.Survey model={this.state.model} />
                                <div id="surveyResult"></div>
                            </Paper>
                        }              
                        <Grid container spacing={2} justify="space-between">
                            <Grid item xs={12}>
                                <Button onClick={this.goBack} variant="outlined" color="primary" >
                                    戻る
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(UserQuestionPage);














