import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AxiosBase from 'axios';

const BaseURL = process.env.REACT_APP_SERVER_URL;
const history = createBrowserHistory();

const Axios = AxiosBase.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

//AXIOS Options
const AxiosOptions = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_running_partner_company_list`,
}

const styles = (theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
}));

//ドロップダウンの表記フォーマット設定
//企業側のサービス「稼動・停止」を表示
const cellEditorParamsStatus = { values: ["running", "stopped"] };
const refDataStatus = { running: "稼働中", stopped: "停止中" };

//AG-Gridデータ管理用カラム
//企業情報リスト
const company_columns = [
  { field: 'company_id', headerName: '企業番号', sortable: true, filter: true, resizable: true, },
  { field: 'company_name', headerName: '企業名', sortable: true, filter: true, resizable: true, },
  { field: 'status', headerName: 'ステータス', sortable: true, filter: false, resizable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsStatus, refData: refDataStatus },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate =  '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

//ページの全体構造
class AdminCompanyList_UserManagerPage extends React.Component {
  constructor(props) {
    super(props);

    //ステータス初期化
    this.state = {
      columnDefs: company_columns,
      rowData: null,
      complete_load: false,
    };
  }

  componentDidMount(props) {
    Axios(AxiosOptions).then((res) => {
      if (res.data !== "Unauthorized") {
        const tempCompanyList = res.data;

        Object.keys(tempCompanyList).forEach(function (key) {
          var company = tempCompanyList[key];
          company.id = company._id;
          console.log(company);
        });

        const companyList = tempCompanyList;

        this.setState({
          complete_load: true,
          rowData: companyList,
        });
        console.log(this.state.rowData);
      } else {
        this.setState({
          complete_load: true,
        })
        console.log("認証失敗")
      }
    }).catch(err => console.log(err));
  }

  showAdminUserManager = (event) =>  {
    const rowAnswerStatus = event.data;
    this.props.history.push({
        pathname:'/adminusermanager',
        state: {
            company_id:event.data.company_id,
            company_name:event.data.company_name,
        }
    }); 
  }   

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={12}>
              <div
                className="ag-theme-material"
                style={{
                  height: 400,
                }}>
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  enableMultiRowDragging={true}
                  onGridReady={(params) =>{ 
                    this.gridApiCompanyList = params.api
                  }}
                  loadingCellRendererParams
                  singleClickEdit={true}
                  overlayLoadingTemplate={overlayLoadingTemplate}
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                  onRowClicked={this.showAdminUserManager}
                  onRowDataChanged={(params) => {
                    const allColumnIds = [];
                    const columnApi = params.columnApi
                    columnApi.getAllColumns().forEach(function (column) {
                      allColumnIds.push(column.colId);
                    });
                    columnApi.autoSizeColumns(allColumnIds, false);
                  }}
                />                
              </div>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={this.goBack} variant="outlined" color="primary" >
                戻る
                  </Button>
            </Grid>
            <Grid item xs={9} />
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(AdminCompanyList_UserManagerPage);