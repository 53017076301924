import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter, Route, Router, Switch, Link } from "react-router-dom";
import AxiosBase from 'axios';
import { createBrowserHistory } from 'history';
import { CssBaseline, } from '@material-ui/core';

import './App.css';

import SignIn from './page/SignIn';
import SignUp from './page/SignUp';
import Header from './components/Header';
import Footer from './components/Footer';

import PrivacyPolicyPage from './page/PrivacyPolicy';

import UserMain from './page/user/UserMain';
import UserVideo from './page/user/UserVideo';
import UserFeedbackList from './page/user/UserFeedbackList';
import UserFeedbackMain from './page/user/UserFeedbackMain';
import UserQuestion from './page/user/UserQuestion';
import UserSignUp from './page/user/UserSignUp';
import UserSignUpManual from './page/user/UserSignUpManual';

import ManagerMain from './page/manager/ManagerMain';
import ManagerUserManager from './page/manager/ManagerUserManager';
import ManagerFeedbackManager from './page/manager/ManagerFeedbackManager';

import AdminMain from './page/admin/AdminMain';
import AdminCompanyManager from './page/admin/AdminCompanyManager';
import AdminHairManager from './page/admin/AdminHairManager';
import AdminFeedbackRegister from './page/admin/AdminFeedbackRegister';
import AdminVideoList from './page/admin/AdminVideoList';
import AdminEnquete from './page/admin/AdminEnquete';
import AdminVideoRegister from './page/admin/AdminVideoRegister';
import AdminUserManager from './page/admin/AdminUserManager';
import AdminFeedbackManager from './page/admin/AdminFeedbackManager';
import AdminFeedbackUpdate from './page/admin/AdminFeedbackUpdate';

//test
import AdminCompanyList_UserManager from './page/admin/AdminCompanyList_UserManager';
import AdminCompanyList_HairManager from './page/admin/AdminCompanyList_HairManager';
import AdminCompanyList_FeedbackManager from './page/admin/AdminCompanyList_FeedbackManager';

// import { userContext } from './UserContext';

import { PrivateRoute } from './components/PrivateRoute';
import { Authority } from './_helper/Authority';
import AuthService from './_services/authService';

const history = createBrowserHistory();

class App extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      currentUser: null,
      authority: null,
    }
    this.logout = this.logout.bind(this);
  }

  componentDidMount(){
    AuthService.currentUser.subscribe(x => {
      // console.log(x);
      this.setState({
        currentUser: x && x.username,
        authority: x && x.authority,
      });
    });
    
    // console.log(AuthService.getUser());
    // AuthService.getUser(x => {
    //   console.log("GetUser");
    //   console.log(x);
    //   this.setState({
    //     currentUser: x && x.username,
    //     authority: x && x.authority,
    //   });
    // });
  }

  logout() {
    AuthService.logout();

    this.props.history.push('/signin');
  }

  render(){
    const { currentUser, authority } = this.state;
    // console.log(currentUser);
    return (
      <main>
        
      <BrowserRouter history={history}>
        <CssBaseline />
        <div>
          {
            currentUser && <Route component={Header}/>
          }          
        </div>
        
        
        <Switch>
          <Route path="/signin" component={SignIn}/>
          <Route path="/signup" component={SignUp}/>
          <Route path="/usersignup" component={UserSignUp}/>
          <Route path="/usersignup_manual" component={UserSignUpManual}/>

          <PrivateRoute exact path="/" roles={[Authority.User, Authority.Manager, Authority.Admin]} component={MainPage}/>
          
          <PrivateRoute path="/privacypolicy" roles={[Authority.User, Authority.Manager, Authority.Admin]} component={PrivacyPolicyPage}/>

          <PrivateRoute path="/usermain" roles={[Authority.User]} component={UserMain}/>
          <PrivateRoute path="/userfeedbacklist" roles={[Authority.User]} component={UserFeedbackList}/>
          <PrivateRoute path="/userfeedbackmain" roles={[Authority.User]} component={UserFeedbackMain}/>
          <PrivateRoute path="/uservideo" roles={[Authority.User]} component={UserVideo}/>
          <PrivateRoute path="/userquestion" roles={[Authority.User]} component={UserQuestion}/>

          <PrivateRoute path="/managermain" roles={[Authority.Manager]} component={ManagerMain}/>
          <PrivateRoute path="/managerfeedbackmanager" roles={[Authority.Manager]} component={ManagerFeedbackManager}/>
          <PrivateRoute path="/managerusermanager" roles={[Authority.Manager]} component={ManagerUserManager}/>
          
          <PrivateRoute path="/adminmain" roles={[Authority.Admin]} component={AdminMain}/>
          <PrivateRoute path="/admincompanymanager" roles={[Authority.Admin]} component={AdminCompanyManager}/>
          <PrivateRoute path="/adminusermanager" roles={[Authority.Admin]} component={AdminUserManager}/>
          <PrivateRoute path="/adminhairmanager" roles={[Authority.Admin]} component={AdminHairManager}/>
          <PrivateRoute path="/adminfeedbackmanager" roles={[Authority.Admin]} component={AdminFeedbackManager}/>
          <PrivateRoute path="/adminfeedbackregister" roles={[Authority.Admin]} component={AdminFeedbackRegister}/>
          <PrivateRoute path="/adminfeedbackupdate" roles={[Authority.Admin]} component={AdminFeedbackUpdate}/>
          <PrivateRoute path="/adminvideolist" roles={[Authority.Admin]} component={AdminVideoList}/>
          <PrivateRoute path="/AdminEnquete" roles={[Authority.Admin]} component={AdminEnquete}/>
          <PrivateRoute path="/adminvideoregister" roles={[Authority.Admin]} component={AdminVideoRegister}/>

          {/* test */}
          <PrivateRoute path="/admincompanylist_usermanager" roles={[Authority.Admin]} component={AdminCompanyList_UserManager}/>
          <PrivateRoute path="/admincompanylist_hairmanager" roles={[Authority.Admin]} component={AdminCompanyList_HairManager}/>
          <PrivateRoute path="/admincompanylist_feedbackmanager" roles={[Authority.Admin]} component={AdminCompanyList_FeedbackManager}/>

          <Route component={NotFound}></Route>  

        </Switch>
        
        <Footer/>
      </BrowserRouter>
      
      </main>
    )
  }
}

function NotFound(){
  return (
    <div>
      <h2>Not Found</h2>
    </div>
  );
}

class MainPage extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    // redirect to home if already logged in
    if (AuthService.currentUserValue) { 
      const currentAuthority = AuthService.currentUserValue.authority;
      if(currentAuthority === Authority.User){
        this.props.history.push('/usermain');
      }else if(currentAuthority === Authority.Manager){
        this.props.history.push('/managermain');
      }else if(currentAuthority === Authority.Admin){
        this.props.history.push('/adminmain');
      }
    }
  }

  render(){
    return (
      <div className="App">
        Main Page
      </div>
    )
  }
}

export default App;
