import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AxiosBase from 'axios';

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

const GetPartnerUserListOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BaseURL}/manager/get_partner_user_list`,
}

const registUserOptions = {
    method: 'POST',
    withCredentials: true,
    data: null,
    url: `${BaseURL}/manager/add_user_info`,
}

const updateUserInfoOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/manager/update_user_info`,
};

const deleteUserInfoOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/manager/delete_user_info`,
};

const RequestSendMailOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/send_verification_mail`,
}

const RequestIssueRegisterUrlOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/issue_register_url`,
}

const styles = (theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    margin: {
        padding: theme.spacing(2),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: 'none',
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    // form: {
    //     width: '100%', // Fix IE 11 issue.
    //     marginTop: theme.spacing(3),
    // },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const user_list_columns = [
    { field: 'detail_info.company_info.company_id', headerName: '企業番号', sortable: true, filter: true, resizable: true, hide: true, },
    { field: 'detail_info.company_info.company_name', headerName: '企業名', sortable: false, filter: false, resizable: true, checkboxSelection: true, },
    { field: 'username', headerName: 'ユーザ名', sortable: true, filter: true, resizable: true, editable: false, },
    { field: 'lastname', headerName: '姓', sortable: true, filter: true, resizable: true, editable: true, editable: false, },
    { field: 'firstname', headerName: '名前', sortable: true, filter: true, resizable: true, editable: true, editable: false, },
];

const textLabels = {
    LABEL_USER_NAME: "ユーザ名（Eメール形式）",
    LABEL_COMPANY_NAME: "企業名",
    LABEL_USER_AUTHORITY: "ユーザ権限",
}

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate =  '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

class ManagerUserManagerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_list_columns: user_list_columns,
            user_list: null,
            complete_load: false,
            dialog_open: false,
            company_id: null,
            company_name: null,

            add_username: "",
            
            alert_dialog_open: false,
            alert_dialog_message: "",
            alert_dialog_title: "",

            backdrop_open: false,

        };
    }

    componentDidMount(props) {
        Axios(GetPartnerUserListOptions).then((res) => {
            if (res.data !== "Unauthorized") {
                const tempUserList = res.data.user_list_result;
                const partner_company_result = res.data.partner_company_result;

                Object.keys(tempUserList).forEach(function (key) {
                    var user = tempUserList[key];
                    user.id = user._id;
                    console.log(user);
                });

                const userList = tempUserList;

                this.setState({
                    complete_load: true,
                    user_list: userList,
                    company_id: partner_company_result.company_id,
                    company_name: partner_company_result.company_name
                });

            } else {
                this.setState({
                    complete_load: true,
                })

                console.log("認証失敗")
            }
        }).catch(err => console.log(err));
    }

    ///////////////start
    addUserInfoDailog_open = (event) => {
        this.setState({
            add_user_info_dialog_open: true,
        })
    }

    register = () => {
        const add_username = this.state.add_username;

        if(add_username === ""){
            this.showAlertDialog("お知らせ", "情報記入欄に空欄があります");
            return;
          }

        const userinfo = {
            username: add_username,
            authority: "user_authority",
            detail_info: {
                questions: [
                    "basic_question",
                ],
                company_info: {
                    company_id: this.state.company_id,
                    company_name: this.state.company_name
                },
            }
        }

        const data = {
            user_data: userinfo,
        }

        registUserOptions.data = data;

        Axios(registUserOptions).then((res) => {
            const res_result = res.data;

            if (res_result.result === "success") {
                this.showAlertDialog("お知らせ", "登録に成功しました");
                
                const newRowData = this.state.user_list.slice();
                const newId = this.state.user_list.length === 0 ? 0 : this.state.user_list[this.state.user_list.length - 1].id + 1;
                const newRow = res_result.user;
                newRow.id = newId;

                newRowData.push(newRow);
                this.setState({
                    user_list: newRowData,
                    add_user_info_dialog_open: false,
                });

                this.setState({
                    add_username: "",
                  })

            }else if (res_result.result === "failed") {
                if (res_result.reason === "User Info Already Exists") {
                    this.showAlertDialog("お知らせ", "すでに登録されているアカウント名です");
                } 
            }
        });
    }

    addUserInfoDailog_close = (event) => {
        this.setState({
            add_user_info_dialog_open: false,
        });
    };

    addUserInfoRow = () => {
        console.log(this.gridApiUserList);
        const newRowData = [{
            firstname: "",
            lastname: "",
            username: "",
            password: "",
            authority: "user_authority",
            birthday: "",
            gender: "",
            detail_info: {
                questions: [],
                company_info: {
                    company_id: this.state.company_id,
                    company_name: this.state.company_name
                },
            }
        }];
        const res = this.gridApiUserList.applyTransaction({
            add: newRowData,
        });
        console.log(newRowData);
    }

    updateUserInfo = () => {
        const rowDatas = [];
        const gridApiData = this.gridApiUserList;

        gridApiData.forEachNode(node => { 
            rowDatas.push(node.data)
        });

        console.log(rowDatas);

        const data = {
            user_data: rowDatas,
        }

        updateUserInfoOptions.data = data;

        Axios(updateUserInfoOptions).then((res) => {
            console.log(res);
            if (res.data === "Update Success") {
                console.log("Update Success");
                window.location.reload();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    deleteUserInfo = () => {
        const selectedNodes = this.gridApiUserList.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);

        const data = {
            user_data: selectedData,
        }

        deleteUserInfoOptions.data = data;

        Axios(deleteUserInfoOptions).then((res) => {
            console.log(res);
            const res_result = res.data;

            if (res_result.result === "success") {
                window.location.reload();
            }else if(res_result.result === "failed"){
            }
        }).catch(err => {
            console.log(err);
        });
    }

    deleteUserInfo_DialogOpen = (event) => {
        const selectedRows = this.gridApiUserList.getSelectedRows();
        if (selectedRows == false) {
            this.showAlertDialog("お知らせ", "項目が選択されていません");
            this.setState({
                dialog_open: false,
            });
        } else {
            this.setState({
                dialog_open: true,
            });
        }
    };

    deleteUserInfo_DialogClose = (event) => {
        this.setState({
            dialog_open: false,
        });
    };
    
    sendVerificationMail = () => {    
        const selectedNodes = this.gridApiUserList.getSelectedNodes();

        if(selectedNodes.length == 0){
            this.showAlertDialog("注意", "ユーザを選択してください");
            return;
        }

        const selectedData = selectedNodes.map(node => node.data);

        if(selectedData.length > 1)
            return;

        const data = {
            user_data:selectedData[0],
        }
  
        RequestSendMailOptions.data = data;
        
        Axios(RequestSendMailOptions).then((res) => {
            console.log(res);
            this.showAlertDialog("お知らせ", "認証メールを送信しました");
            this.handleCloseBackdrop();
        }).catch(err => {
            console.log(err);
            this.showAlertDialog("注意", "認証メール送信に失敗しました");
            this.handleCloseBackdrop();
        });

        this.setState({
            backdrop_open: true,
        })
    }

    issueRegistUrl = () => {
        const company_id = this.state.company_id;
        const company_name = this.state.company_name;

        const company_data = {
            company_id: company_id,
            company_name: company_name,
        }

        RequestIssueRegisterUrlOptions.data = {
            company_data: company_data,
        }

        Axios(RequestIssueRegisterUrlOptions).then((res) => {
            const responseData = res.data;
            const url = responseData.data.url;

            // this.showAlertDialog("お知らせ", "URLが発行されました"+url);
            this.showAlertDialog("お知らせ", url);
            this.handleCloseBackdrop();
        }).catch(err => {
            console.log(err);
            this.showAlertDialog("お知らせ", "URL発行に失敗しました");
            this.handleCloseBackdrop();
        });

        this.setState({
            backdrop_open: true,
        })
    }

    handleChangeAddUserName = (e) => {
        this.setState({
            add_username: e.target.value,
        })
    }
    
    showAlertDialog = (title, message) => {
        this.setState({
            alert_dialog_title: title,
            alert_dialog_message: message,
            alert_dialog_open: true,
        })
    }

    showBackdrop = () => {
        this.setState({
            backdrop_open: true,
        })
    }
    

    handleCloseAlertDialog = (event) => {
        this.setState({
            alert_dialog_open: false,
        })
    }

    handleCloseBackdrop = () => {
        this.setState({
            backdrop_open: false,
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Container maxWidth="md">
                    <Grid container spacing={6} justify="space-between">
                        <Grid item xs={12}>
                            <div
                                className="ag-theme-material"
                                style={{
                                    height: 400,
                                    width: 800,
                                }}
                            >
                                <Button onClick={this.addUserInfoDailog_open} color="primary">ユーザ追加</Button>
                                <Button onClick={this.deleteUserInfo_DialogOpen} color="primary" >ユーザ削除</Button>
                                {/* <Button onClick={this.updateUserInfo} color="primary" >更新</Button> */}
                                <Button onClick={this.sendVerificationMail} color="primary">認証メール送信</Button> 
                                <Button onClick={this.issueRegistUrl} color="primary">ユーザ登録URL発行</Button> 
                                <AgGridReact
                                    columnDefs={this.state.user_list_columns}
                                    rowData={this.state.user_list}
                                    // rowSelection="multiple"
                                    enableMultiRowDragging={true}
                                    singleClickEdit={true}
                                    onGridReady={(params) =>{ 
                                        this.gridApiUserList = params.api
                                        this.gridApiUserListColumns = params.columnApi
                                    }}
                                    overlayLoadingTemplate={overlayLoadingTemplate}
                                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    onRowDataChanged={(params) => {
                                        const allColumnIds = [];
                                        this.gridApiUserListColumns.getAllColumns().forEach(function (column) {
                                            allColumnIds.push(column.colId);
                                        });
                                        this.gridApiUserListColumns.autoSizeColumns(allColumnIds, false);
                                    }}
                                />

                            </div>
                        </Grid>         
                        <Grid item xs={12}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" className={classes.bottomButtons}>
                                戻る
                            </Button>
                        </Grid>               
                    </Grid>
                </Container>

                
                <Dialog maxWidth="xs" onClose={this.addUserInfoDailog_close} aria-labelledby="customized-dialog-title" open={this.state.add_user_info_dialog_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.addUserInfoDailog_close}>
                        ユーザ追加
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2} justify="space-between">
                            <Grid item xs={12}>
                                <Typography variant="h7" color="primary">
                                    登録するユーザ名(Eメール形式)を入力してください。
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    onChange={this.handleChangeAddUserName}
                                    label={textLabels.LABEL_USER_NAME}
                                    autoComplete="email"
                                />
                            </Grid>                        
                        </Grid>                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.register} color="primary">
                            登録
                        </Button>
                        <Button onClick={this.addUserInfoDailog_close} color="primary">
                            戻る
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.dialog_open} onClose={this.deleteUserInfo_DialogClose}>
                    <DialogTitle id="alert-dialog-title">{"注意"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            削除した内容は復元できません。本当に削除してもよろしいでしょうか？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.deleteUserInfo} color="primary" autoFocus>
                            はい
                            </Button>
                        <Button onClick={this.deleteUserInfo_DialogClose} color="primary">
                            いいえ
                            </Button>
                    </DialogActions>
                </Dialog>                
                <Dialog
                    open={this.state.alert_dialog_open}
                    onClose={this.handleCloseAlertDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle id="alert-dialog-title">{this.state.alert_dialog_title}</MuiDialogTitle>
                    <MuiDialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.alert_dialog_message}
                    </DialogContentText>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        
                    <Button onClick={this.handleCloseAlertDialog} color="primary" autoFocus>
                        確認
                    </Button>
                    </MuiDialogActions>
                </Dialog>

                <Backdrop className={classes.backdrop} open={this.state.backdrop_open} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default withStyles(styles)(ManagerUserManagerPage);