// import React from 'react';
import React, { useEffect, useContext, useState } from 'react';
import AxiosBase from 'axios';
import { Formik } from 'formik';
import { createBrowserHistory } from 'history';

import { withStyles } from '@material-ui/core/styles';


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import AuthService from '../_services/authService';
import LogoImage from '../img/logo.png';

const styles = ((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  warning: {
    color: 'red',
  },
}));

const textLabels = {
  LABEL_EMAIL_ADDRESS : "Eメールアドレス",
  LABEL_PASSWORD : "パスワード",
  LABEL_SIGNIN : "サインイン",
}

class SignInPage extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      authResult: "",
    }

    if(AuthService.currentUserValue){
      console.log(AuthService.currentUserValue);
      this.props.history.push('/');
    }
  }

  login = (data) => {
    AuthService.login(data.email, data.password).then((user) => {
        // console.log(this);
        // console.log("AuthService login");
        // console.log(user);
        
        this.props.history.push('/');
      },
      (error) => {
        console.log("Auth Error");
        this.setState({
          authResult:"認証失敗",
        });
      }
    );
  };

  render(){
    const { classes } = this.props;

    return (
      // <PageView />
      <Formik
        initialValues={{ email:"", password:"" }}
        onSubmit={async (data, {setSubmitting}) => {
          setSubmitting(true);
          
          await this.login(data);
          
          setSubmitting(false);
        }}
        onChange={(values) => {
          console.log("onChange");
          console.log(values);
        }}
        render={(props) => (          
          <Container maxWidth="xs">
            <div className={classes.paper}>
              <img src={LogoImage} alt="Evcare Logo" width={"300"} />
              <Typography component="h1" variant="h5">
                サインイン
              </Typography>
              <form className={classes.form} onSubmit={props.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={textLabels.LABEL_EMAIL_ADDRESS}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={props.values.email}
                  onChange={props.handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={textLabels.LABEL_PASSWORD}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={props.values.password}
                  onChange={props.handleChange}
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="アカウント情報を記憶する"
                /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={props.isSubmitting}
                >
                  {textLabels.LABEL_SIGNIN}
                </Button>

                <Grid container justify="flex-end">
                  <Box className={classes.warning} display="block" displayPrint="none">
                    {this.state.authResult}
                  </Box>
                </Grid>                
              </form>
            </div>
          </Container>
        )}      
      />
    );
  }
}

export default withStyles(styles)(SignInPage);