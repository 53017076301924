import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import AuthService from '../../_services/authService';

const styles = (theme => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    media: {
        width: '100%',
        height: '300px',
        // paddingBottom: '56.25%',
        position: 'relative',
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    }
}));


class UserVideoPage extends React.Component {
    constructor(props){
      super(props);
      
    }
    
    goBack = () => {
        this.props.history.goBack();
    }
  
    render(){
        const { classes } = this.props; 
        return (
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <div className={classes.heroButtons}>
                        <Grid container spacing={2} justify="center" direction="column">
                            <CardMedia
                                className={classes.media}
                                component="iframe"
                                src="https://www.youtube.com/embed/evCrxxtslVU"
                            />
                        </Grid>

                        <Grid container justify="space-between" spacing={2}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" align="left">
                                戻る
                            </Button>
                        </Grid>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(UserVideoPage);