import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AxiosBase from 'axios';
import { RowCount } from '@material-ui/data-grid';

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

//AXIOS Options
//動画リスト取得用
const GetVideoListOptions = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_video_list`,
}

//動画追加用
const AddVideoInfoOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/add_video_info`,
}

const UpdateVideoInfoOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/update_video_info`,
};

const DeleteVideoInfoOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/delete_video_info`,
};

const styles = (theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  bottomButtons: {
    marginTop: theme.spacing(4),
  },
}));

// //データ管理用カラム
const video_columns = [
  { field: 'video_id', headerName: '動画番号', sortable: true, filter: true, resizable: true, checkboxSelection: true, /*rowGroup: true*/ },
  { field: 'video_name', headerName: '動画名', sortable: true, filter: true, resizable: true, editable: true, },
  { field: 'url', headerName: 'URL', sortable: true, filter: true, resizable: true, editable: true, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate = '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

const textLabels = {
  LABEL_VIDEO_ID: "動画番号",
  LABEL_VIDEO_NAME: "動画名",
  LABEL_VIDEO_URL: "動画URL",
}

class AdminVideoListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video_list_column: video_columns,
      video_list: null,
      complete_load: false,
      dialog_open: false,

      add_video_dialog_open: false,

      add_video_id: "",
      add_video_name: "",
      add_video_url: "",

      alert_dialog_title: "",
      alert_dialog_message: "",
      alert_dialog_open: false,

      backdrop_open: false,

      value_video_id: 1
    };
  }

  componentDidMount(props) {
    Axios(GetVideoListOptions).then((res) => {
      if (res.data !== "Unauthorized") {
        const tempVideoList = res.data;

        Object.keys(tempVideoList).forEach(function (key) {
          var video = tempVideoList[key];
          video.id = video._id;
          console.log(video);
        });

        const videoList = tempVideoList;

        this.setState({
          complete_load: true,
          video_list: videoList,
        });

        let value_video_id = 0
        console.log(this.state.video_list);
        console.log(this.state.video_list.length)
        console.log(this.state.video_list[this.state.video_list.length - 1].video_id + 1)
        value_video_id = this.state.video_list[this.state.video_list.length - 1].video_id + 1
        console.log(value_video_id)
        
        this.setState({
          value_video_id: value_video_id,
        });

      } else {
        this.setState({
          complete_load: true,
        })
        console.log("認証失敗")
      }
    }).catch(err => console.log(err));
  }

  addVideoInfo = () => {
    // const add_video_id = this.state.add_video_id;
    const add_video_id = this.state.value_video_id;
    const add_video_name = this.state.add_video_name;
    const add_video_url = this.state.add_video_url;

    if (add_video_id === "" || add_video_name === "" || add_video_url === "") {
      this.showAlertDialog("お知らせ", "情報記入欄に空欄があります");
      return;
    }

    const nowDate = new Date();

    const newRowData = {
      video_id: add_video_id,
      video_name: add_video_name,
      url: add_video_url,
      regist_date: nowDate,
    };

    AddVideoInfoOptions.data = newRowData;

    this.showBackdrop();
    Axios(AddVideoInfoOptions).then((res) => {
      console.log(res);
      const result = res.data.result;
      if (result == "success") {
        const newVideoInfo = res.data.data;

        const newVideoList = this.state.video_list.slice();
        const newId = this.state.video_list.length === 0 ? 0 : this.state.video_list[this.state.video_list.length - 1].id + 1;
        newVideoInfo.id = newId;

        newVideoList.push(newVideoInfo);
        this.setState({
          video_list: newVideoList,
          add_video_dialog_open: false,
        });
        //this.showAlertDialog("お知らせ", "動画情報を追加しました");
        window.location.reload();
        this.handleCloseBackdrop();
        this.setState({
          add_video_id: "",
          add_video_name: "",
          add_video_url: "",
        });
      } else if (result == "failed") {
        const reason = res.data.reason;
        if (reason == "Video Info Already Exists") {
          this.showAlertDialog("お知らせ", "すでに存在する動画情報です");
          this.handleCloseBackdrop();
        }
      }
    }).catch(err => {
      console.log(err);
      this.showAlertDialog("お知らせ", "動画追加に失敗しました");
      this.handleCloseBackdrop();
    });
  }

  removeVideoInfo = () => {
    const selectedNodes = this.gridApiVideoList.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);

    const data = {
      video_data: selectedData,
    }

    DeleteVideoInfoOptions.data = data;

    Axios(DeleteVideoInfoOptions).then((res) => {
      console.log(res);
      if (res.data === "Delete Success") {
        console.log("Delete Success");
        window.location.reload();
      }

    }).catch(err => {
      console.log(err);
    });
  }

  updateVideoInfo = () => {
    const rowDatas = [];
    const gridApiData = this.gridApiVideoList;

    gridApiData.forEachNode(node => {
      const video_id = node.data.video_id;
      const video_name = node.data.video_name;
      const url = node.data.url;
      rowDatas.push(node.data)
    });

    console.log(rowDatas);

    const data = {
      video_data: rowDatas,
    }

    UpdateVideoInfoOptions.data = data;

    Axios(UpdateVideoInfoOptions).then((res) => {
      console.log(res);
      if (res.data === "Update Success") {
        console.log("Update Success");
        window.location.reload();
      }

    }).catch(err => {
      console.log(err);
    });
  }

  deleteVideoInfoDialogOpen = (event) => {
    const selectedRows = this.gridApiVideoList.getSelectedNodes();

    if (selectedRows == false) {
      this.showAlertDialog("お知らせ", "項目が選択されていません")
      return;
      // this.setState({
      //   dialog_open: false,
      // });
    } else {
      this.setState({
        dialog_open: true,
      });
    }
  };

  deleteVideoInfoDialogClose = (event) => {
    this.setState({
      dialog_open: false,
    });
  };

  showAddVideoDialog = () => {
    this.setState({
      add_video_dialog_open: true,

    });
  }

  showAlertDialog = (title, message) => {
    this.setState({
      alert_dialog_title: title,
      alert_dialog_message: message,
      alert_dialog_open: true,
    })
  }

  showBackdrop = () => {
    this.setState({
      backdrop_open: true,
    })
  }

  handleCloseAddVideoInfoDialog = (event) => {
    this.setState({
      add_video_id: "",
      add_video_name: "",
      add_video_url: "",
      add_video_dialog_open: false,
    })
  }

  handleChangeAddVideoId = (e) => {
    this.setState({
      add_video_id: e.target.value,
    })
  }

  handleChangeAddVideoName = (e) => {
    this.setState({
      add_video_name: e.target.value,
    })
  }

  handleChangeAddVideoUrl = (e) => {
    this.setState({
      add_video_url: e.target.value,
    })
  }

  handleCloseAlertDialog = (event) => {
    this.setState({
      alert_dialog_open: false,
    })
  }

  handleCloseBackdrop = () => {
    this.setState({
      backdrop_open: false,
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={12}>
              <div
                className="ag-theme-material"
                style={{
                  height: 400,
                }}>
                <Button onClick={this.showAddVideoDialog} color="primary">動画追加</Button>
                <Button onClick={this.deleteVideoInfoDialogOpen} color="primary" >動画削除</Button>
                <Button onClick={this.updateVideoInfo} color="primary">動画一覧更新</Button>
                <AgGridReact
                  columnDefs={this.state.video_list_column}
                  rowData={this.state.video_list}
                  enableMultiRowDragging={true}
                  onGridReady={(params) => {
                    this.gridApiVideoList = params.api
                    this.gridApiVideoListColumns = params.columnApi
                  }}
                  loadingCellRendererParams
                  singleClickEdit={true}
                  onRowSelected={false}
                  onSelectionChanged
                  overlayLoadingTemplate={overlayLoadingTemplate}
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                  onRowDataChanged={(params) => {
                    const allColumnIds = [];
                    this.gridApiVideoListColumns.getAllColumns().forEach(function (column) {
                      allColumnIds.push(column.colId);
                    });
                    this.gridApiVideoListColumns.autoSizeColumns(allColumnIds, false);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={this.goBack} variant="outlined" color="primary" >
                戻る
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          open={this.state.dialog_open}
          onClose={this.deleteVideoInfoDialogClose}
        >
          <DialogTitle id="alert-dialog-title">{"注意"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              削除した内容は復元できません。本当に削除してもよろしいでしょうか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteVideoInfoDialogClose} color="primary">
              いいえ
            </Button>
            <Button onClick={this.removeVideoInfo} color="primary" autoFocus>
              はい
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="sm" onClose={this.handleCloseAddVideoInfoDialog} aria-labelledby="customized-dialog-title" open={this.state.add_video_dialog_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseAddVideoInfoDialog}>
            動画追加
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  disabled
                  fullWidth
                  //onChange={this.handleChangeAddVideoId}
                  value={this.state.value_video_id}
                  label={textLabels.LABEL_VIDEO_ID}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={this.handleChangeAddVideoName}
                  label={textLabels.LABEL_VIDEO_NAME}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={this.handleChangeAddVideoUrl}
                  label={textLabels.LABEL_VIDEO_URL}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.addVideoInfo} color="primary">
              登録
            </Button>
            <Button onClick={this.handleCloseAddVideoInfoDialog} color="primary">
              戻る
            </Button>
          </DialogActions>
        </Dialog>

        {/* 単純なお知らせのダイアログ */}
        <Dialog
          open={this.state.alert_dialog_open}
          onClose={this.handleCloseAlertDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.alert_dialog_title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert_dialog_message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={this.handleCloseAlertDialog} color="primary" autoFocus>
              確認
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AdminVideoListPage);
