import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AuthService from '../../_services/authService';

import AxiosBase from 'axios';


const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
 headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',   
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

const AxiosOptions = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_selected_user_list`,
};

const updateUserInfoOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/update_user_info`,
};

const RequestSendMailOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/send_verification_mail`,
}

const styles = (theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
}));

// const local_company_id = {};
// const local_company_name = {};

// const cellEditorParamsStatus = { values: ["running", "stopped"] };
// const refDataStatus = {running:"稼働中", stopped:"停止中"};

const company_columns = [
    // { field: 'detail_info.company_info.company_id', headerName: '企業番号', sortable:true, filter:true, resizable:true, /*hide:true*/ },
    // { field: 'detail_info.company_info.company_name', headerName: '企業名', sortable:true, filter:true, resizable:true, /*rowGroup:true*/},
    // { field: 'detail_info.company_info.company_name', headerName: '企業名', sortable:true, filter:true, resizable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsStatus, refData: refDataStatus /*rowGroup:true*/},
    // { field: 'detail_info.company_info.company_id', headerName: '企業番号', sortable:true, filter:true, resizable:true, checkboxSelection: true, },
    // { field: 'detail_info.company_info.company_name', headerName: '企業名', sortable:true, filter:true, resizable:true, },
    { field: 'username', headerName: 'ユーザ名', sortable:true, filter:true, resizable:true, },
    { field: 'lastname', headerName: '姓', sortable:true, filter:true, resizable:true, },
    { field: 'firstname', headerName: '名前', sortable:true, filter:true, resizable:true, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate =  '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

class AdminUserManagerPage extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
          columnDefs: company_columns,
          rowData: null,
          complete_load: false,
        };
    }
    
    componentDidMount(props){
        const { classes } = this.props;
        const company_id = this.props.location.state.company_id;
        const company_name = this.props.location.state.company_name;
        
        console.log(`company_id:${company_id}`);
        console.log(`company_name:${company_name}`);

        const params = {
            company_id:company_id,
            company_name:company_name,
        }
        AxiosOptions.params = params;
        
        Axios(AxiosOptions).then((res) => {
            if(res.data !== "Unauthorized"){
                const tempUserList = res.data;
                
                Object.keys(tempUserList).forEach(function (key) {
                    var user = tempUserList[key];
                    user.id = user._id;
                    // if (user.detail_info.company_info = null) {
                    //     user.detail_info.company_info.company_id = "0000";
                    //     user.detail_info.company_info.company_name = "test";  
                    // }
                    console.log(user);
                });

                const userList = tempUserList;

				// local_company_id = tempUserList.user.detail_info.company_info.company_id;
                // local_company_name = tempUserList.user.detail_info.company_info.company_name;
                // console.log(local_company_id);

                this.setState({
                    complete_load: true,
                    rowData: userList, 
                });
                
            }else{
                this.setState({
                    complete_load: true, 
                })

                console.log("認証失敗")
            }            
        }).catch(err => console.log(err));    
    }

    onButtonClick = () => {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
    //   const selectedDataStringPresentation = selectedData.map(node => node.company_id + ' ' + node.company_name).join(', ');
    //   alert(`Selected Nodes: ${selectedDataStringPresentation}`);
    }

	// addUserInfo = () => {
    //     const newRowData = [{
    //       firtname: "",
    //       lastname: "",
    //       username: "",
    //       password: "",         
    //       detail_info: {
    //           questions:{},
    //           company_info:{
    //               company_id:"",
    //               company_name:""
    //           }

    //       }
    //     }];
  
    //     const res = this.gridApiCompanyList.applyTransaction({
    //         add: newRowData,
    //     });
        
    //     console.log(newRowData);
    // }

    updateUserInfo = () => {
        const rowDatas = [];
        const gridApiData = this.gridApiUserList;
    
        gridApiData.forEachNode(node => {
            const company_id = node.data.company_id;
            const company_name = node.data.company_name;
            // const company_id = node.data.detail_info.company_info.company_id;
            // const company_name = node.data.detail_info.company_info.company_name;
            const username = node.data.username;
            const lastname = node.data.lastname;
            const firstname = node.data.firstname;        
            rowDatas.push(node.data)
        });
        
        console.log(rowDatas);
        
        const data = {
            user_data:rowDatas,
        }
  
        updateUserInfoOptions.data = data;
  
        Axios(updateUserInfoOptions).then((res) => {
            console.log(res);
            if(res.data === "Update Success"){
              console.log("Update Success");
              window.location.reload();
            }
            
        }).catch(err => {
            console.log(err);
        });
    }

    sendVerificationMail = () => {    
        const selectedNodes = this.gridApiUserList.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);

        if(selectedData.length > 1)
            return;

        const data = {
            user_data:selectedData[0],
        }
  
        RequestSendMailOptions.data = data;
  
        Axios(RequestSendMailOptions).then((res) => {
            console.log(res);
            
        }).catch(err => {
            console.log(err);
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { classes } = this.props;
        
        return (
            <div className={classes.root}>
                <Container maxWidth="md">
                    <Grid container spacing={6} justify="space-between">
                        <Grid item xs={12}>
                            <div 
                                className="ag-theme-material" 
                                style={{
                                    // width:800,
                                    height:400,
                                }}
                            >
                                {/* <Button onClick={this.addUserInfo} color="primary">ユーザ追加</Button>
                                <Button onClick={this.updateUserInfo} color="primary">更新</Button> 
                                <Button onClick={this.sendVerificationMail} color="primary">認証メール送信</Button>  */}
                                
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}                                
                                    rowData={this.state.rowData}
                                    enableMultiRowDragging={true}
                                    onGridReady={(params) =>{ 
                                        this.gridApiUserList = params.api
                                        this.gridApiUserListColumns = params.columnApi
                                    }}
                                    singleClickEdit={true}
                                    overlayLoadingTemplate={overlayLoadingTemplate}
                                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    // loadingCellRendererParams
                                    
                                    onRowDataChanged={(params) => {
                                        const allColumnIds = [];
                                        this.gridApiUserListColumns.getAllColumns().forEach(function (column) {
                                            allColumnIds.push(column.colId);
                                        });
                                        this.gridApiUserListColumns.autoSizeColumns(allColumnIds, false);
                                    }}                   
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" >
                                戻る
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(AdminUserManagerPage);