import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
    ResponsiveContainer,
    ComposedChart, Line, XAxis, YAxis, CartesianGrid, Legend, Bar,
    RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Label, Tooltip as RechartTooltip
}
    from 'recharts';

import FeedbackImage from '../../img/Feedback.jpg';
import LogoImage from '../../img/logo.png';

import ArrowYourPosition from '../../img/arrow_yourposition.png'
import MonitoringAnswer from '../../img/monitor/monitoring_answer.png'
import MonitoringDraw from '../../img/monitor/monitoring_draw.png'
import MonitoringBlank from '../../img/monitor/monitoring_blank.png'
import MonitoringHair from '../../img/monitor/monitoring_hair.png'
import IconObjectiveStress from '../../img/icon_objective_stress.png';
import IconSubjectiveStress from '../../img/icon_subjective_stress.png';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactPlayer from 'react-player';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AuthService from '../../_services/authService';

import AxiosBase from 'axios';

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

//AXIOS Options
//フィードバック計算結果情報取得用
const GetUserFeedbackDataOptions = {
    method: 'GET',
    params: null,
    withCredentials: true,
    url: `${BaseURL}/user/get_feedback_result`,
}

const styles = (theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

        border: '5px solid #fccbcc',
        borderRadius: 20,
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),

        border: '2px solid #fccbcc',
        borderRadius: 20,

        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: 'none',
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    box: {
        borderRadius: 5,
        border: 1,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: 40,
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: '#fca2cc',
    },
    objective_box_1: {
        // background: 'linear-gradient(#fc8ccc, #8a8a8a)',
        backgroundColor: '#fccbcc'
    },
    objective_box_2: {
        // background: 'linear-gradient(#f968a8, #fc8ccc)',
        backgroundColor: '#fca2cc'
    },
    objective_box_3: {
        // background: 'linear-gradient(#f968a8, #f70790, #f968a8)',
        backgroundColor: '#fc72cc'
    },
    objective_box_4: {
        // background: 'linear-gradient(#fc8ccc, #f968a8)',
        backgroundColor: '#fca2cc'
    },
    objective_box_5: {
        // background: 'linear-gradient(#8a8a8a, #fc8ccc)',
        backgroundColor: '#fccbcc',
    },
    //主観的ボックス
    subjective_box_1: {
        // background: 'linear-gradient(#fcaacf, #dcdcdc)',
        backgroundColor: '#fccbcc',
    },
    subjective_box_2: {
        // background: 'linear-gradient(#f968a8, #fcaacf)',
        backgroundColor: '#fca2cc',
    },
    subjective_box_3: {
        // background: 'linear-gradient(#fc8ccc, #f968a8)',
        backgroundColor: '#fc72cc',
    },
    subjective_box_4: {
        // background: 'linear-gradient(#8a8a8a, #fc8ccc)',
        backgroundColor: '#fca2cc',
    },
    subjective_box_5: {
        // background: 'linear-gradient(#787878, #8a8a8a)',
        backgroundColor: '#fccbcc',
    },
    fullScreen: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            fullWidth: true,
        },
        [theme.breakpoints.up('md')]: {
            fullWidth: false,
            maxWidth: 'md',
        },
    }
}));

// //データ管理用カラム
const video_columns = [
    { field: 'video_id', headerName: '動画番号', sortable: true, filter: true, resizable: true, hide: true, },
    { field: 'video_name', headerName: '動画名', sortable: true, filter: true, resizable: true, },
    { field: 'url', headerName: 'URL', sortable: true, filter: true, resizable: true, hide: true, },
];

const objective_stress_explain = "この項目では、毛髪に蓄積されたストレスホルモンを測定し３つの視点から評価を行っています。\n黄色い矢印で、母集団と比較したときのあなたの位置を示しています。";
const subjective_stress_explain = "この項目では、質問紙によって測定された主観的ストレス評価を４つの視点から行っています。\n棒グラフでは、黄色い矢印で、母集団と比較したときのあなたの位置を示しています。";
const monitoring_explain = "この項目では、客観的ストレスと主観的ストレスのバランスで、あなたのストレスモニタリングタイプを評価しています。モニタリングタイプは、最適モニタリング、過少モニタリング、過剰モニタリングの３つ分類されます。最適モニタリングタイプの方は、主観的なストレス評価と客観的なストレス評価がある程度一致されている方です。過少モニタリングタイプの方は、客観的なストレス評価に、過剰モニタリングタイプの方は主観的なストレス評価に偏りがある傾向を示しています。";

const cortidol_level_explain = "この項目では、測定した期間の間にどのくらいストレスに曝されたかという、ストレス曝露量を示しています。このホルモンは、免疫系や、代謝系などに作用を及ぼすと言われています。中央が平均で、そこから上に向かうほどストレスホルモン値が高く、下に向かうほどストレスホルモン値が低いことを示しています。";
const dhea_level_explain = "ストレス反応と対になる作用をもたらす抗ストレス反応の項目です。抗ストレス反応とは、ストレス反応による身体への影響を和らげようとする力の大きさを示すものです。平均が中央で、上に向かうほど抗ストレスホルモン値が高く、下に向かうほど抗ストレスホルモン値が低いことを示しています。";
const cortidol_dhea_level_explain = "これは、ストレス反応と抗ストレス反応の比率より算出した値です。ストレス反応が高いと良くないのでは、と思われる方は多くいらっしゃるかもしれません。しかし、実はストレス反応がたとえ高くても、抗ストレス反応がきちんと作用をしていれば、問題はないと言われています。そのため、この指標は、私たちの心身の健康を守るうえで最も重要な指標とされています。ストレスに打ち勝ち、逆境を跳ね返す力を「レジリエンス」といいますが、そのレジリエンスの指標であるとも言えます。平均が中央で、上に向かうほどレジリエンスが高く、下に向かうほどレジリエンスが低いことを示しています。"

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate = '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const textLabels = {
    LABELS_STRESS_LEVEL: [
        "低め", "やや低め", "標準", "やや高め", "高め",
    ],
    LABEL_OBJECTIVE_COMMENT: "所見",
    LABEL_SUBJECTIVE_COMMENT: "所見",
    LABEL_TOTAL_COMMENT: "総評",
}

class UserFeedbackMainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            objective_stress_box_list_cortidol: null,
            objective_stress_box_list_dhea: null,
            objective_stress_box_list_cortidol_dhea: null,
            subjective_stress_box_list_total_stress: null,

            subjective_stress_box_list_total_stress_position: null,

            feedback_loading: true,
            feedback_result: null,
            convert_to_format_date: null,

            monitoring_image: null,
            objective_stress_data: [],
            subjective_stress_data: [],

            previous_hair_infos: [],
            username: null,
            company_name: null,
            age: null,

            video_list_columns: video_columns,
            video_list: null,
            video_list_dialog_open: false,

            selected_video_url: null,
            video_player_dialog_open: false,

            pip: false,
            playing: true,
            muted: true,
        };

        // this.showUserHairManager = this.showUserHairManager.bind(this);
    }

    componentDidMount(props) {
        const { classes } = this.props;

        if (this.props.location.state != undefined) {
            const username = this.props.location.state.username;
            const pertinent_month = this.props.location.state.pertinent_month;
            console.log(`User Name:${username}`);
            console.log(`Pertinent Month:${pertinent_month}`);

            const params = {
                username: username,
                pertinent_month: pertinent_month,
            }
            GetUserFeedbackDataOptions.params = params;
        }

        //フィードバック結果取得
        Axios(GetUserFeedbackDataOptions).then((res) => {
            const feedback_result = res.data.feedback_result;
            const previous_hair_infos = res.data.previous_hair_infos;
            const video_list = feedback_result.video_list;
            console.log(previous_hair_infos);

            const objectiveBoxClasses = [classes.objective_box_1, classes.objective_box_2, classes.objective_box_3, classes.objective_box_4, classes.objective_box_5,];
            const subjectiveBoxClasses = [classes.subjective_box_1, classes.subjective_box_2, classes.subjective_box_3, classes.subjective_box_4, classes.subjective_box_5,];

            const objective_stress_box_list_cortidol = [];
            const objective_stress_box_list_dhea = [];
            const objective_stress_box_list_cortidol_dhea = [];
            const subjective_stress_box_list_total_stress = [];

            // const objective_stress_position_list_cortidol = [];
            // const objective_stress_position_list_dhea = [];
            // const objective_stress_position_list_cortidol_dhea = [];
            // const subjective_stress_position_list_total_stress = [];

            // const subjective_stress_box_list_total_stress_position = [];
            const labels_stress_level = textLabels.LABELS_STRESS_LEVEL;

            for (var i = 4; i >= 0; i--) {
                objective_stress_box_list_cortidol.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {feedback_result.objective_stress.cortidol_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);

                objective_stress_box_list_dhea.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {feedback_result.objective_stress.dhea_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);

                objective_stress_box_list_cortidol_dhea.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {feedback_result.objective_stress.cortidol_dhea_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);

                // objective_stress_box_list_cortidol.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`}/>);

                // objective_stress_position_list_cortidol.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`}>
                //     {feedback_result.objective_stress.cortidol_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right"/>}
                // </Box>);

                // objective_stress_box_list_dhea.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`}>
                //     {feedback_result.objective_stress.dhea_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right"/>}
                // </Box>);

                // objective_stress_box_list_cortidol_dhea.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`}>
                //     {feedback_result.objective_stress.cortidol_dhea_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right"/>}
                // </Box>);
            }

            for (var i = 4; i >= 0; i--) {
                subjective_stress_box_list_total_stress.push(<Box className={`${classes.box} ${subjectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {feedback_result.subjective_stress.total_stress === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);
                // subjective_stress_box_list_total_stress.push(<Box className={`${classes.box} ${subjectiveBoxClasses[i]}`}/>);
                // subjective_stress_box_list_total_stress_position.push(<Box className={`${classes.box}`}>
                //     {feedback_result.subjective_stress.total_stress === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right"/>}
                // </Box>);
            }

            const objective_stress_data = [];

            previous_hair_infos.map((user_hair_info) => {
                const cortidol = user_hair_info.hair_info.cortidol;
                const dhea = user_hair_info.hair_info.dhea;
                const cortidol_dhea = Math.round((cortidol / dhea) * 1000) / 1000;
                const graphRowData = {
                    month: user_hair_info.pertinent_month,
                    ストレス反応値: cortidol,
                    抗ストレス反応値: dhea,
                    反応のバランス値: cortidol_dhea,
                }
                objective_stress_data.push(graphRowData);
            });
            console.log(objective_stress_data);

            const subjective_stress_data = [
                { status: `身体バランス調整不全`, level: feedback_result.subjective_stress.body_balance, standard_level: 3, fullMark: 5 },
                { status: `疲労身体反応`, level: feedback_result.subjective_stress.fatigue, standard_level: 3, fullMark: 5 },
                { status: `不安不確実感`, level: feedback_result.subjective_stress.anxiety, standard_level: 3, fullMark: 5 },
                { status: 'うつ気分不全感', level: feedback_result.subjective_stress.fell_depression, standard_level: 3, fullMark: 5 },
            ];

            var monitoring_image = MonitoringDraw;

            if (feedback_result.subjective_stress.total_stress == -1 || feedback_result.objective_stress.cortidol_level == -1) {
                
                monitoring_image = MonitoringBlank
            } else {
                if (feedback_result.subjective_stress.total_stress > feedback_result.objective_stress.cortidol_level) {
                    monitoring_image = MonitoringAnswer;
                } else if (feedback_result.subjective_stress.total_stress < feedback_result.objective_stress.cortidol_level) {
                    monitoring_image = MonitoringHair;
                }

            }

            this.setState({
                objective_stress_box_list_cortidol: objective_stress_box_list_cortidol,
                objective_stress_box_list_dhea: objective_stress_box_list_dhea,
                objective_stress_box_list_cortidol_dhea: objective_stress_box_list_cortidol_dhea,
                subjective_stress_box_list_total_stress: subjective_stress_box_list_total_stress,

                monitoring_image: monitoring_image,
                objective_stress_data: objective_stress_data,
                subjective_stress_data: subjective_stress_data,

                feedback_loading: false,
                feedback_result: feedback_result,
                convert_to_format_date: new Date(feedback_result.release_date),
                previous_hair_infos: previous_hair_infos,

                username: feedback_result.username,
                company_name: feedback_result.user_info.detail_info.company_info.company_name,
                age: feedback_result.age,

                video_list: video_list,
            })

            console.log(this.state.video_list);
        }).catch(err => {
            console.log(err)
        });
    }

    showAddManagerInfoDialog = () => {
        this.setState({
            video_list_dialog_open: true,

        });
    }

    showVideoList = () => {
        // this.props.history.push("/uservideo");
        this.setState({
            // video_list: video_
            video_list_dialog_open: true,
        });
    }

    showVideoDialog = (event) => {
        const data = event.data;
        const video_url = data.url;

        this.setState({
            selected_video_url: video_url,
            video_player_dialog_open: true,
        })
    }

    handleCloseVideoListDialog = () => {
        this.setState({
            video_list_dialog_open: false,

        });
    }

    handleCloseVideoPlayerDialog = () => {
        this.setState({
            video_player_dialog_open: false,
        })
    }

    // onEnablePIP = () => {
    //     console.log('onEnablePIP')
    //     this.setState({ pip: true })
    // }

    // onDisablePIP = () => {
    //     console.log('onDisablePIP')
    //     this.setState({ pip: false })
    // }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Container maxWidth="md" className={classes.container}>
                    {this.state.feedback_loading && <Grid container justify="center"><CircularProgress /></Grid>}
                    {this.state.feedback_result &&
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8} md={8} >
                                    <Typography variant="h4" display="inline" color="primary">
                                        Stress Monitoring&nbsp;
                                    </Typography>
                                    <Typography variant="h4" display="inline">
                                        Feedback Sheet
                                    </Typography>
                                    <Typography variant="h7" display="block">
                                        ストレスモニタリング・フィードバックシート
                                    </Typography>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableHead} component="th" scope="row">
                                                    <div style={{ color: "#ffffff" }}>ID</div>
                                                </TableCell>
                                                <TableCell align="left">{this.state.username}</TableCell>
                                                <TableCell className={classes.tableHead} component="th" scope="row">
                                                    <div style={{ color: "#ffffff" }}>公開日</div>
                                                </TableCell>
                                                <TableCell align="left">{`${this.state.convert_to_format_date.getFullYear()}-${this.state.convert_to_format_date.getMonth() + 1}-${this.state.convert_to_format_date.getDate()}`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableHead} component="th" scope="row">
                                                    <div style={{ color: "#ffffff" }}>年代</div>
                                                </TableCell>
                                                <TableCell align="left">{this.state.age}</TableCell>
                                                <TableCell className={classes.tableHead} component="th" scope="row">
                                                    <div style={{ color: "#ffffff" }}>分析期間</div>
                                                </TableCell>
                                                <TableCell align="left">{this.state.feedback_result.pertinent_month}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>

                                <Grid item xs={12} sm={4} md={4} >
                                    <Table style={{ border: '1px solid #fccbcc', borderRadius: 20, }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" className={classes.tableHead} component="th" scope="row">
                                                    <div style={{ color: "#ffffff" }}>
                                                        モニタリング・タイプ
                                                        <Tooltip title={monitoring_explain}>
                                                            <LiveHelpIcon />
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center"><img src={this.state.monitoring_image} alt="Show Result" height="80" /></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                {/* <Grid item xs={12} md={12} lg={12} >
                                  <Typography variant="subtitle2" align="right" border={2} borderRadius={16} >
                                      公開日：{`${this.state.convert_to_format_date.getFullYear()}-${this.state.convert_to_format_date.getMonth() + 1}-${this.state.convert_to_format_date.getDate()}`}
                                  </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} >
                                  <img src={LogoImage} alt="Evcare Logo" width={"300"} />
                              </Grid>
                              <Grid item xs={12}>
                                  <Typography variant="h5" >
                                      ストレスモニタリング・フィードバックシート
                                  </Typography>
                              </Grid>
                              
                              <Grid item xs={12} md={8} lg={8}>
                                  <Table>
                                      <TableHead>
                                          <TableRow>
                                              <TableCell align="center">ID</TableCell>
                                              <TableCell align="center">企業名</TableCell>
                                              <TableCell align="center">年代</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          <TableRow>
                                              <TableCell align="center">{this.state.username}</TableCell>
                                              <TableCell align="center">{this.state.company_name}</TableCell>
                                              <TableCell align="center">{this.state.age}</TableCell>
                                          </TableRow>
                                      </TableBody>
                                  </Table>
                              </Grid>

                              <Grid item xs={12} md={4} lg={4}>
                                  <Table>
                                      <TableBody> 
                                      <TableRow>
                                          <TableCell align="center">分析期間</TableCell>
                                          <TableCell align="center">
                                            最適モニタリング・タイプ
                                              
                                            <Tooltip title={monitoring_explain}>
                                                <LiveHelpIcon/>
                                            </Tooltip>
                                          </TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell align="center">{this.state.feedback_result.pertinent_month}</TableCell>
                                          <TableCell align="center"><img src={this.state.monitoring_image} alt="Show Result" height="80"/></TableCell>
                                      </TableRow>
                                      </TableBody>
                                  </Table>
                              </Grid> */}
                            </Grid>

                            <Paper className={classes.paper} variant="outlined" >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography component="h2" variant="h6" color="primary" gutterBottom inline>
                                            <img src={IconObjectiveStress} height="30" />
                                            客観的ストレス
                                            <Tooltip title={objective_stress_explain}>
                                                <LiveHelpIcon />
                                            </Tooltip>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4} >
                                        {this.state.objective_stress_box_list_cortidol}
                                        <Typography variant="subtitle1" align="center" >
                                            ストレス反応
                                            <Tooltip title={cortidol_level_explain}>
                                                <LiveHelpIcon />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={1} md={1} lg={1} >
                                      {this.state.objective_stress_position_list_cortidol}
                                  </Grid> */}
                                    <Grid item xs={4} md={4} lg={4} >
                                        {this.state.objective_stress_box_list_dhea}
                                        <Typography variant="subtitle1" align="center" >
                                            抗ストレス反応
                                            <Tooltip title={dhea_level_explain}>
                                                <LiveHelpIcon />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={1} md={1} lg={1} >
                                      {this.state.objective_stress_box_list_dhea}
                                  </Grid> */}
                                    <Grid item xs={4} md={4} lg={4} >
                                        {this.state.objective_stress_box_list_cortidol_dhea}
                                        <Typography variant="subtitle1" align="center" >
                                            反応のバランス
                                            <Tooltip title={cortidol_dhea_level_explain}>
                                                <LiveHelpIcon />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* {this.state.previous_feedback_list != undefined && */}
                                        <ResponsiveContainer height={300}>
                                            <ComposedChart data={this.state.objective_stress_data} >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="month" dy={10} />
                                                <YAxis yAxisId="left" tick={{ fontSize: 10 }}>
                                                    <Label angle={-90} value="バランス値" />
                                                </YAxis>
                                                <YAxis type="number" domain={[0, 1]} yAxisId="right" orientation="right" tick={{ fontSize: 10 }} >
                                                    <Label angle={-90} value="反応値" />
                                                </YAxis>
                                                <RechartTooltip />
                                                <Legend />
                                                <Bar yAxisId="right" dataKey="ストレス反応値" fill="#8884d8" barSize={20} />
                                                <Bar yAxisId="right" dataKey="抗ストレス反応値" fill="#82ca9d" barSize={20} />
                                                <Line yAxisId="left" type="monotone" dataKey="反応のバランス値" stroke="#ff7300" />
                                            </ComposedChart>
                                        </ResponsiveContainer>
                                        {/* } */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Typography variant="h6">
                                          所見
                                      </Typography> */}
                                        <TextField
                                            fullWidth
                                            id="standard-multiline-static"
                                            name="objective_comment"
                                            multiline
                                            rows={4}
                                            defaultValue={this.state.feedback_result.objective_comment}
                                            label={textLabels.LABEL_OBJECTIVE_COMMENT}
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper className={classes.paper} variant="outlined" >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography align="middle" component="h2" variant="h6" color="primary" gutterBottom inline>
                                            <img src={IconSubjectiveStress} height="30" />
                                            主観的ストレス
                                            <Tooltip title={subjective_stress_explain}>
                                                <LiveHelpIcon />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        {this.state.subjective_stress_box_list_total_stress}
                                        <Typography variant="subtitle1" align="center" margin={50}>
                                            ストレス反応
                                            <Tooltip title={cortidol_level_explain}>
                                                <LiveHelpIcon />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={7} textAlign="center">
                                        {this.state.subjective_stress_data != undefined &&
                                            <ResponsiveContainer height={250}>
                                                <RadarChart data={this.state.subjective_stress_data} >
                                                    <PolarGrid />
                                                    <PolarAngleAxis dataKey="status" />
                                                    <PolarRadiusAxis orientation="left" scale="linear" angle={90} domain={[0, 5]} />
                                                    <Radar name="あなた" dataKey="level" stroke="#fc72cc" fill="#fccbcc" fillOpacity={0.3} />
                                                    <Radar name="標準" dataKey="standard_level" strokeDasharray="3 3" stroke="#21ff35" fillOpacity={0.0} />
                                                    <Legend />
                                                </RadarChart>
                                            </ResponsiveContainer>
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* <Typography variant="h6">
                                          所見
                                      </Typography> */}
                                        <TextField
                                            fullWidth
                                            id="standard-multiline-static"
                                            name="subjective_comment"
                                            multiline
                                            rows={4}
                                            defaultValue={this.state.feedback_result.subjective_comment}
                                            label={textLabels.LABEL_SUBJECTIVE_COMMENT}
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* <Typography variant="h6">
                                          総評
                                      </Typography> */}
                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="total_comment"
                                            multiline
                                            rows={4}
                                            defaultValue={this.state.feedback_result.total_comment}
                                            label={textLabels.LABEL_TOTAL_COMMENT}
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Grid container justify="space-between" spacing={2}>
                                <Button onClick={this.goBack} variant="outlined" color="primary" align="left">
                                    戻る
                                </Button>
                                <Button onClick={this.showVideoList} variant="outlined" color="primary" align="right">
                                    動画一覧
                                </Button>
                            </Grid>
                        </div>
                    }
                </Container>
                <Dialog maxWidth="sm" onClose={this.handleCloseVideoListDialog} aria-labelledby="customized-dialog-title" open={this.state.video_list_dialog_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseVideoListDialog}>
                        動画一覧
                    </DialogTitle>
                    <DialogContent dividers>
                        <div
                            className="ag-theme-material"
                            style={{
                                width: 400,
                                height: 400,
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.video_list_columns}
                                rowData={this.state.video_list}
                                enableMultiRowDragging={true}
                                onGridReady={(params) => {
                                    this.gridApiVideoList = params.api
                                    this.gridApiVideoListColumns = params.columnApi
                                }}
                                loadingCellRendererParams
                                singleClickEdit={true}
                                rowSelection="multiple"
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                overlayNoRowsTemplate={overlayNoRowsTemplate}
                                frameworkComponents={this.state.frameworkComponents}
                                // onRowDoubleClicked={this.showVideoDialog}
                                onRowClicked={this.showVideoDialog}
                                onRowDataChanged={(params) => {
                                    // const allColumnIds = [];
                                    // this.gridApiVideoListColumns.getAllColumns().forEach(function (column) {
                                    //     allColumnIds.push(column.colId);
                                    // });
                                    // this.gridApiVideoListColumns.autoSizeColumns(allColumnIds, false);
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog fullScreen={true} onClose={this.handleCloseVideoPlayerDialog} aria-labelledby="customized-dialog-title" open={this.state.video_player_dialog_open}>
                    <DialogTitle id="customized" onClose={this.handleCloseVideoPlayerDialog}>
                        動画再生
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ReactPlayer
                                    className='react-player'
                                    controls
                                    // width='auto'
                                    // height='auto'
                                    width='100%'
                                    // height='100%'
                                    url={this.state.selected_video_url}
                                // pip={this.state.pip}
                                // muted={this.statemuted}
                                // playing={this.state.playing}
                                // onEnablePIP={this.onEnablePIP}
                                // onDisablePIP={this.onDisablePIP}
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(UserFeedbackMainPage);