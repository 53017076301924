import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import AuthService from '../../_services/authService';

const useStyles = makeStyles(theme => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(4, 0, 6),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    }
}));
  

class AdminVideoRegisterPage extends React.Component {
    constructor(props){
      super(props);
    }
  
    render(){
      return (
        <PageView />
      );
    }
}

function PageView() {    
    const classes = useStyles();
    
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Grid container spacing={2} justify="space-between">
                    <Grid item xs={3}>
                        <Button disabled>
                            動画タイトル：
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                        variant="outlined"
                        size="small"                                
                        fullWidth={true}
                        />          
                    </Grid>                            
                </Grid>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item xs={3}>
                            <Button disabled>
                            動画URL:
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item xs={12}>
                            <TextField
                            variant="outlined"
                            size="small"                                
                            fullWidth={true}
                            />           
                        </Grid>
                    </Grid>
                <Grid className={classes.bottomButtons} container spacing={2} justify="space-between">
                    <Grid item xs={3}>
                        <Link href="/adminmain">
                            <Button variant="outlined" color="primary" fullWidth={true} >
                                戻る
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={3}>
                        <Link href="/adminmain">
                            <Button variant="outlined" color="primary" fullWidth={true}>
                                保存
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
      );
}

export default AdminVideoRegisterPage;