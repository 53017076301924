import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AuthService from '../../_services/authService';

import AxiosBase from 'axios';


const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',   
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

//AXIOS Options
//フィードバックリスト取得用
const GetUserFeedbackListOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BaseURL}/user/get_feedback_result_list`,
}

const styles = (theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: 'none',
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

//データ管理用カラム
//毛髪情報・アンケート回答状態カラム
const user_feedback_list_columns = [
    { field: 'username', headerName: 'ユーザ名', sortable:true, filter:true, resizable:true, hide:true, },
    { field: 'pertinent_month', headerName: '該当年月', sortable:true, filter:true, resizable:true, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate =  '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

class UserFeedbackListPage extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            complete_load: false,
            //フィードバックリスト
            user_feedback_list_columns: user_feedback_list_columns,
            user_feedback_list: null,
        };
        
        // this.showUserHairManager = this.showUserHairManager.bind(this);
    }
    
    componentDidMount(props){
        //ユーザ情報リスト取得
        Axios(GetUserFeedbackListOptions).then((res) => {
            console.log(res);
            const user_feedback_list = res.data;
            this.setState({
                user_feedback_list: user_feedback_list,
                
            })
        }).catch(err => {
            console.log(err)
        });    
    }

    showUserFeedbackResult = (event) =>  {
        const rowData = event.data;
        
        console.log(event.data);
        this.props.history.push({
            pathname:'/userfeedbackmain',
            state: {
                username:event.data.username,
                pertinent_month:event.data.pertinent_month,
            }
        });
    }

    handleCloseAnswerStatusManager = (event) => {

        this.setState({
            user_answer_status_manager_open: false,
            user_answer_status_infos: null,
        });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { classes } = this.props;
        
        return (
            <div className={classes.root}>
                <Container maxWidth="sm">
                    <Grid container spacing={2} justify="space-between">
                        <Grid item xs={12}>
                            <div 
                                className="ag-theme-material" 
                                style={{
                                    // width:800, 
                                    height:400,
                                }}
                            >
                                <AgGridReact
                                    columnDefs={this.state.user_feedback_list_columns}
                                    rowData={this.state.user_feedback_list}
                                    onRowDoubleClicked={this.showUserFeedbackResult}
                                    //   rowSelection="multiple"
                                    overlayLoadingTemplate={overlayLoadingTemplate}
                                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    enableMultiRowDragging={true}
                                    onGridReady={(params) =>{ 
                                        this.gridApi = params.api
                                        this.gridApiColumns = params.columnApi
                                    }}
                                    onRowDataChanged={(params) => {
                                        const allColumnIds = [];
                                        this.gridApiColumns.getAllColumns().forEach(function (column) {
                                            allColumnIds.push(column.colId);
                                        });
                                        this.gridApiColumns.autoSizeColumns(allColumnIds, false);
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" >
                                戻る
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(UserFeedbackListPage);
