import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { AgGridReact } from 'ag-grid-react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AxiosBase from 'axios';

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

//AXIOS Options
const AxiosOptions = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_running_partner_company_list`
}

//基準毛髪データ取得用
const GetReferenceHairInfosOptions = {
  method: 'GET',
  params: null,
  withCredentials: true,
  url: `${BaseURL}/admin/get_reference_hair_infos`,
};

//基準Cortidolデータ取得用
const GetReferenceCortidolInfosOptions = {
  method: 'GET',
  params: null,
  withCredentials: true,
  url: `${BaseURL}/admin/get_reference_cortidol_infos`,
};

//基準DHEA取得用
const GetReferenceDheaInfosOptions = {
  method: 'GET',
  params: null,
  withCredentials: true,
  url: `${BaseURL}/admin/get_reference_dhea_infos`,
};

//基準Cortidol/DHEAデータ取得用
const GetReferenceCortidolDheaInfosOptions = {
  method: 'GET',
  params: null,
  withCredentials: true,
  url: `${BaseURL}/admin/get_reference_cortidol_dhea_infos`,
};

//基準Cortidol更新用
const UpdateReferenceCortidolInfosOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/update_reference_cortidol_infos`,
};

//基準DHEA更新用
const UpdateReferenceDheaInfosOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/update_reference_dhea_infos`,
};

//基準Cortidol/DHEA更新用
const UpdateReferenceCortidolDheaInfosOptions = {
  method: 'POST',
  data: null,
  withCredentials: true,
  url: `${BaseURL}/admin/update_reference_cortidol_dhea_infos`,
};


const styles = (theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 2,
    zIndex: theme.zIndex.drawer + 2000,
    color: '#fff',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
      <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
          </IconButton>
      ) : null}
      </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
      padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
      margin: 0,
      padding: theme.spacing(1),
  },
}))(MuiDialogActions);

//ドロップダウンの表記フォーマット設定
//企業側のサービス「稼動・停止」を表示
const cellEditorParamsStatus = { values: ["running", "stopped"] };
const refDataStatus = { running: "稼働中", stopped: "停止中" };

const cellEditorParamsGender = { values: ["male", "female",] };
const refDataGender = { male:"男性", female:"女性", };

const cellEditorParamsLevel = { values: [1, 2, 3, 4, 5] };
const refDataLevel = { 1:"レベル1", 2:"レベル2", 3:"レベル3", 4:"レベル4", 5:"レベル5", };

const cellEditorParamsAgeGroup = { values: [0, 10, 20, 30, 40, 50,60, 70, 80, 90, 100,] };
const refDataAgeGroup = {0:"0代", 10:"10代", 20:"20代", 30:"30代", 40:"40代", 50:"50代", 60:"60代", 70:"70代", 80:"80代", 90:"90代", 100:"100代", };



//AG-Gridデータ管理用カラム
//企業情報リスト
const company_columns = [
  { field: 'company_id', headerName: '企業番号', sortable: true, filter: true, resizable: true, },
  { field: 'company_name', headerName: '企業名', sortable: true, filter: true, resizable: true, },
  { field: 'status', headerName: 'ステータス', sortable: true, filter: false, resizable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsStatus, refData: refDataStatus },
];

//基準毛髪データカラム
const reference_hair_info_columns = [
  { field: 'gender', headerName: '性別', sortable:true, filter:true, resizable:true, editable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsGender, refData: refDataGender, },
  { field: 'age_group', headerName: '年代', sortable:true, filter:true,  resizable:true, editable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAgeGroup, refData: refDataAgeGroup},
  { field: 'cortidol', headerName: 'Cortidol', sortable:true, filter:true, resizable:true, editable:true, hide:true, cellRenderer: formatDouble, },
  { field: 'dhea', headerName: 'DHEA', sortable:true, filter:true, resizable:true, editable:true, hide:true, cellRenderer: formatDouble, },
  { field: 'average_cortidol', headerName: '平均Cortidol', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
  { field: 'average_dhea', headerName: '平均DHEA', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
  { field: 'sd_cortidol', headerName: 'SD Cortidol', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
  { field: 'sd_dhea', headerName: 'SD DHEA', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
];

//基準Cortidolデータカラム
const reference_cortidol_info_columns = [
  { field: 'level', headerName: 'レベル', sortable:true, filter:true, resizable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsLevel, refData: refDataLevel, },
  { field: 'cortidol', headerName: 'Cortidol', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
];

//基準DHEAデータカラム
const reference_dhea_info_columns = [
  { field: 'level', headerName: 'レベル', sortable:true, filter:true, resizable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsLevel, refData: refDataLevel, },
  { field: 'dhea', headerName: 'DHEA', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
];

//基準Cortidol/DHEAデータカラム
const reference_cortidol_dhea_info_columns = [
  { field: 'level', headerName: 'レベル', sortable:true, filter:true, resizable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsLevel, refData: refDataLevel, },
  { field: 'cortidol_dhea', headerName: 'Cortidol/DHEA', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate =  '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

function formatDouble(data) {
  // console.log(data.value);
  return parseFloat(data.value);
}

//ページの全体構造
class AdminCompanyList_HairManagerPage extends React.Component {
  constructor(props) {
    super(props);

    //ステータス初期化
    this.state = {
      alert_dialog_title: "",
      alert_dialog_message: "",
      alert_dialog_open: false,
      backdrop_open: false,

      columnDefs: company_columns,
      rowData: null,
      complete_load: false,
      
      //基準毛髪データ関連
      reference_hair_manager_open: false,
      reference_hair_data_columns: reference_hair_info_columns,
      reference_hair_data: null,

      //基準Cortidolデータ関連
      reference_cortidol_manager_open: false,
      reference_cortidol_data_columns: reference_cortidol_info_columns,
      reference_cortidol_data: null,
      
      //基準DHEAデータ関連
      reference_dhea_manager_open: false,
      reference_dhea_data_columns: reference_dhea_info_columns,
      reference_dhea_data: null,
      
      //基準Cortidol/DHEAデータ関連
      reference_cortidol_dhea_manager_open: false,
      reference_cortidol_dhea_data_columns: reference_cortidol_dhea_info_columns,
      reference_cortidol_dhea_data: null,      
    };
  }

  componentDidMount(props) {
    Axios(AxiosOptions).then((res) => {
      if (res.data !== "Unauthorized") {
        const tempCompanyList = res.data;

        Object.keys(tempCompanyList).forEach(function (key) {
          var company = tempCompanyList[key];
          company.id = company._id;
        });

        const companyList = tempCompanyList;

        this.setState({
          complete_load: true,
          rowData: companyList,
        });
        
      } else {
        this.setState({
          complete_load: true,
        })
        console.log("認証失敗")
      }
    }).catch(err => console.log(err));
  }

  showAlertDialog = (title, message) => {
    this.setState({
        alert_dialog_title: title,
        alert_dialog_message: message,
        alert_dialog_open: true,
    })
  }

  showBackdrop = () => {
    this.setState({
        backdrop_open: true,
    })
  }

  showAdminHairManager = (event) =>  {
    const rowAnswerStatus = event.data;
    this.props.history.push({
        pathname:'/adminhairmanager',
        state: {
            company_id:event.data.company_id,
            company_name:event.data.company_name,
        }
    }); 
  }   
  
  showReferenceHairManager = () => {
    Axios(GetReferenceHairInfosOptions).then((res) => {
        // console.log(res);
        this.setState({
            reference_hair_data: res.data,
        })
    }).catch(err => { 
        console.log(err)
    
    });    

    this.setState({
        reference_hair_manager_open:true,
    })
  };

  showReferenceCortidolManager = () => {
    Axios(GetReferenceCortidolInfosOptions).then((res) => {
      this.setState({
          reference_cortidol_data: res.data,
      })
    }).catch(err => { 
      console.log(err)    
    });

    this.setState({
      reference_cortidol_manager_open:true,
    })
  };

  showReferenceDheaManager = () => {
    Axios(GetReferenceDheaInfosOptions).then((res) => {
      this.setState({
          reference_dhea_data: res.data,
      })
    }).catch(err => { 
      console.log(err)    
    });

    this.setState({
      reference_dhea_manager_open:true,
    })
  };

  showReferenceCortidolDheaManager = () => {
    Axios(GetReferenceCortidolDheaInfosOptions).then((res) => {
      this.setState({
          reference_cortidol_dhea_data: res.data,
      })
    }).catch(err => { 
      console.log(err)    
    });

    this.setState({
      reference_cortidol_dhea_manager_open:true,
    })
  };

  updateReferenceCortidolData = () => {
    const rowData = [];
    this.gridApiReferenceCortidol.forEachNode(node => {rowData.push(node.data)});
    
    console.log(rowData);
    UpdateReferenceCortidolInfosOptions.data = rowData;
    
    this.showBackdrop();
    Axios(UpdateReferenceCortidolInfosOptions).then((res) => {
      this.showAlertDialog("お知らせ", "データを更新しました");
      this.handleCloseBackdrop();
    }).catch(err => { 
      console.log(err)
      this.showAlertDialog("お知らせ", "データ更新を失敗しました");
      this.handleCloseBackdrop();
    });
  }

  updateReferenceDheaData = () => {
    const rowData = [];
    this.gridApiReferenceDhea.forEachNode(node => {rowData.push(node.data)});
    
    console.log(rowData);
    UpdateReferenceDheaInfosOptions.data = rowData;
    
    this.showBackdrop();
    Axios(UpdateReferenceDheaInfosOptions).then((res) => {
      this.showAlertDialog("お知らせ", "データを更新しました");
      this.handleCloseBackdrop();
    }).catch(err => { 
      console.log(err)
      this.showAlertDialog("お知らせ", "データ更新を失敗しました");
      this.handleCloseBackdrop();
    });
  }

  updateReferenceCortidolDheaData = () => {
    const rowData = [];
    this.gridApiReferenceCortidolDhea.forEachNode(node => {rowData.push(node.data)});
    
    console.log(rowData);
    UpdateReferenceCortidolDheaInfosOptions.data = rowData;
    
    this.showBackdrop();
    Axios(UpdateReferenceCortidolDheaInfosOptions).then((res) => {
      this.showAlertDialog("お知らせ", "データを更新しました");
      this.handleCloseBackdrop();
    }).catch(err => { 
      console.log(err)
      this.showAlertDialog("お知らせ", "データ更新を失敗しました");
      this.handleCloseBackdrop();
    });
  }
  
  handleCloseAlertDialog = (event) => {
    this.setState({
      alert_dialog_open: false,
    });
  };

  handleCloseBackdrop = () => {
    this.setState({
      backdrop_open: false,
    });
  }
  
  handleCloseReferenceHairManager = (event) => {      
    this.setState({
        reference_hair_manager_open: false,
        reference_hair_data: null,
    });
  };

  handleCloseReferenceCortidolManager = (event) => {
    this.setState({
      reference_cortidol_manager_open: false,
      reference_cortidol_data: null,
    });
  }

  handleCloseReferenceDheaManager = (event) => {
    this.setState({
      reference_dhea_manager_open: false,
      reference_dhea_data: null,
    });
  }

  handleCloseReferenceCortidolDheaManager = (event) => {
    this.setState({
      reference_cortidol_dhea_manager_open: false,
      reference_cortidol_dhea_data: null,
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={12}>
              {/* <AgGridDialog title="Cortidol管理" column_def={this.state.reference_cortidol_data_columns} row_data={this.state.reference_cortidol_data} open={this.state.reference_cortidol_manager_open} handleClose={this.handleCloseReferenceCortidolManager}>
                <Button onClick={this.updateReferenceCortidolData} color="primary">基準Cortidol更新</Button>
                <Button onClick={this.removeReferenceCortidolData} color="primary">基準Cortidol削除</Button>
              
              </AgGridDialog> */}
              
              
              {/* <Button onClick={this.showReferenceHairManager} color="primary">基準毛髪データ管理</Button> */}
              <Button onClick={this.showReferenceCortidolManager} color="primary">Cortidol管理</Button>
              <Button onClick={this.showReferenceDheaManager} color="primary">DHEA管理</Button>
              <Button onClick={this.showReferenceCortidolDheaManager} color="primary">Cortidol/DHEA管理</Button>
              
              <div
                className="ag-theme-material"
                style={{
                  height: 400,
                }}>
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  enableMultiRowDragging={true}
                  onGridReady={(params) =>{ 
                    this.gridApiCompanyList = params.api
                  }}
                  loadingCellRendererParams
                  singleClickEdit={true}
                  overlayLoadingTemplate={overlayLoadingTemplate}
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                  onRowClicked={this.showAdminHairManager}
                  onRowDataChanged={(params) => {
                    const allColumnIds = [];
                    const columnApi = params.columnApi
                    columnApi.getAllColumns().forEach(function (column) {
                      allColumnIds.push(column.colId);
                    });
                    columnApi.autoSizeColumns(allColumnIds, false);
                  }}                  
                />                
              </div>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={this.goBack} variant="outlined" color="primary" >
                戻る
              </Button>
            </Grid>
            <Grid item xs={9} />
          </Grid>
        </Container>
        {/* 基準毛髪データ管理ダイアログ */}
        {/* <Dialog maxWidth="md" onClose={this.handleCloseReferenceHairManager} aria-labelledby="customized-dialog-title" open={this.state.reference_hair_manager_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseReferenceHairManager}>
              基準毛髪データ管理
          </DialogTitle>
          <DialogContent dividers>                        
              <Button onClick={this.updateReferenceHairData} color="primary">基準毛髪データ更新</Button>
              <div 
                  className="ag-theme-material" 
                  style={{
                      width:800, 
                      height:400,
                  }}
              >
                  <AgGridReact
                      columnDefs={this.state.reference_hair_data_columns}
                      rowData={this.state.reference_hair_data}
                      enableMultiRowDragging={true}
                      onGridReady={(params) =>{ 
                          this.gridApiReferenceHair = params.api
                          this.gridApiReferenceHairColumns = params.columnApi
                      }}
                      loadingCellRendererParams
                      singleClickEdit={true}     
                      overlayLoadingTemplate={overlayLoadingTemplate}
                      overlayNoRowsTemplate={overlayNoRowsTemplate}
                      onRowDataChanged={(params) => {
                        const allColumnIds = [];
                        this.gridApiReferenceHairColumns.getAllColumns().forEach(function (column) {
                          allColumnIds.push(column.colId);
                        });
                        this.gridApiReferenceHairColumns.autoSizeColumns(allColumnIds, false);
                      }}                     
                  />
              </div>
          </DialogContent>
        </Dialog> */}

        {/* 基準Cortidol管理ダイアログ */}
        <Dialog maxWidth="md" onClose={this.handleCloseReferenceCortidolManager} aria-labelledby="customized-dialog-title" open={this.state.reference_cortidol_manager_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseReferenceCortidolManager}>
              基準Cortidol管理
          </DialogTitle>
          <DialogContent dividers>                        
              <Button onClick={this.updateReferenceCortidolData} color="primary">基準毛髪データ更新</Button>
              <div 
                className="ag-theme-material" 
                style={{
                    width:800, 
                    height:400,
                }}
              >
                  <AgGridReact
                    columnDefs={this.state.reference_cortidol_data_columns}
                    rowData={this.state.reference_cortidol_data}
                    enableMultiRowDragging={true}
                    onGridReady={(params) =>{ 
                        this.gridApiReferenceCortidol = params.api
                    }}
                    loadingCellRendererParams
                    singleClickEdit={true}     
                    overlayLoadingTemplate={overlayLoadingTemplate}
                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                    onRowDataChanged={(params) => {
                      const allColumnIds = [];
                      const columnApi = params.columnApi
                      columnApi.getAllColumns().forEach(function (column) {
                        allColumnIds.push(column.colId);
                      });
                      columnApi.autoSizeColumns(allColumnIds, false);
                    }}                     
                  />
              </div>
          </DialogContent>
        </Dialog>

        {/* 基準DHEA管理ダイアログ */}
        <Dialog maxWidth="md" onClose={this.handleCloseReferenceDheaManager} aria-labelledby="customized-dialog-title" open={this.state.reference_dhea_manager_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseReferenceDheaManager}>
              基準DHEA管理
          </DialogTitle>
          <DialogContent dividers>                        
              <Button onClick={this.updateReferenceDheaData} color="primary">基準毛髪データ更新</Button>
              <div 
                className="ag-theme-material" 
                style={{
                    width:800, 
                    height:400,
                }}
              >
                  <AgGridReact
                    columnDefs={this.state.reference_dhea_data_columns}
                    rowData={this.state.reference_dhea_data}
                    enableMultiRowDragging={true}
                    onGridReady={(params) =>{ 
                        this.gridApiReferenceDhea = params.api
                    }}
                    loadingCellRendererParams
                    singleClickEdit={true}     
                    overlayLoadingTemplate={overlayLoadingTemplate}
                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                    onRowDataChanged={(params) => {
                      const allColumnIds = [];
                      const columnApi = params.columnApi
                      columnApi.getAllColumns().forEach(function (column) {
                        allColumnIds.push(column.colId);
                      });
                      columnApi.autoSizeColumns(allColumnIds, false);
                    }}                     
                  />
              </div>
          </DialogContent>
        </Dialog>

        {/* 基準Cortidol/DHEA管理ダイアログ */}
        <Dialog maxWidth="md" onClose={this.handleCloseReferenceCortidolDheaManager} aria-labelledby="customized-dialog-title" open={this.state.reference_cortidol_dhea_manager_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseReferenceCortidolDheaManager}>
              基準Cortidol/DHEA管理
          </DialogTitle>
          <DialogContent dividers>                        
              <Button onClick={this.updateReferenceCortidolDheaData} color="primary">基準毛髪データ更新</Button>
              <div 
                className="ag-theme-material" 
                style={{
                    width:800, 
                    height:400,
                }}
              >
                  <AgGridReact
                    columnDefs={this.state.reference_cortidol_dhea_data_columns}
                    rowData={this.state.reference_cortidol_dhea_data}
                    enableMultiRowDragging={true}
                    onGridReady={(params) =>{ 
                        this.gridApiReferenceCortidolDhea = params.api
                    }}
                    loadingCellRendererParams
                    singleClickEdit={true}     
                    overlayLoadingTemplate={overlayLoadingTemplate}
                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                    onRowDataChanged={(params) => {
                      const allColumnIds = [];
                      const columnApi = params.columnApi
                      columnApi.getAllColumns().forEach(function (column) {
                        allColumnIds.push(column.colId);
                      });
                      columnApi.autoSizeColumns(allColumnIds, false);
                    }}                     
                  />
              </div>
          </DialogContent>
        </Dialog>

        {/* 単純なお知らせのダイアログ */}
        <Dialog
          open={this.state.alert_dialog_open}
          onClose={this.handleCloseAlertDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <MuiDialogTitle id="alert-dialog-title">{this.state.alert_dialog_title}</MuiDialogTitle>
          <MuiDialogContent>
              <DialogContentText id="alert-dialog-description">
                  {this.state.alert_dialog_message}
              </DialogContentText>
          </MuiDialogContent>
          <MuiDialogActions>                        
              <Button onClick={this.handleCloseAlertDialog} color="primary" autoFocus>
                  確認
              </Button>
          </MuiDialogActions>
        </Dialog>
        
        {/* プログレスバー */}
        <Backdrop className={classes.backdrop} open={this.state.backdrop_open} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

// class AgGridDialog extends React.Component {
//   constructor(props){
//     super(props);
//   }

//   componentDidMount() {
    
//   }

//   render() {
//     const title = this.props.title != null ? this.props.title : "";
//     const open = this.props.open == true ? true : false;

//     const column_def = this.props.column_def;
//     const row_data = this.props.row_data;

//     const handleClose = this.props.handleClose;

//     const children = this.props.children;
//     child
//     console.log(children);

//     return (
//       <Dialog maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
//         <DialogTitle id="customized-dialog-title" onClose={handleClose} >
//             {title}
//         </DialogTitle>
//         <DialogContent dividers>
//           {children}             
//           <div 
//               className="ag-theme-material" 
//               style={{
//                   width:800, 
//                   height:400,
//               }}
//           >
//               <AgGridReact
//                   columnDefs={column_def}
//                   rowData={row_data}
//                   enableMultiRowDragging={true}
//                   onGridReady={(params) =>{ 
//                       this.gridApi = params.api
//                       this.gridColumnApi = params.columnApi
//                   }}
//                   loadingCellRendererParams
//                   singleClickEdit={true}     
//                   overlayLoadingTemplate={overlayLoadingTemplate}
//                   overlayNoRowsTemplate={overlayNoRowsTemplate}
//                   onRowDataChanged={(params) => {
//                     const allColumnIds = [];
//                     this.gridColumnApi.getAllColumns().forEach(function (column) {
//                       allColumnIds.push(column.colId);
//                     });
//                     this.gridColumnApi.autoSizeColumns(allColumnIds, false);
//                   }}                     
//               />
//           </div>
//         </DialogContent>
//       </Dialog>
//     );
//   }    
// }

export default withStyles(styles)(AdminCompanyList_HairManagerPage);