import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import AxiosBase from 'axios';

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',   
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

//AXIOS Options
//ユーザリスト取得用
const GetUserListOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BaseURL}/manager/get_manager_user_list`,
}
      
//ユーザ回答状況取得用
const GetUserAnswerStatusOptions = {
    method: 'GET',
    params: null,
    withCredentials: true,
    url: `${BaseURL}/manager/get_user_answer_status`,
};

//全てのフィードバックリスト取得用
const GetAllFeedbackListOptions = {
    method: 'GET',
    params: null,
    withCredentials: true,
    url: `${BaseURL}/manager/get_all_feedback_list`,
};

const styles = (theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: 'none',
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const cellEditorParamsAnswerStatus = { values: [true, false] };
const refDataAnswerStatus = { true:"あり", false:"なし", };
const refDataReleaseStatus = {released:"公開中", private:"非公開"};
const cellStyleAnswerStatus = (data) => {
    return data.value ? {color: "green"} : {color: "red"};
}

//データ管理用カラム
//ユーザリストカラム
const partner_user_columns = [
    { field: 'detail_info.company_info.company_id', headerName: '企業番号', /*width: 150,*/ sortable:true, filter:true, hide:true, },
    { field: 'detail_info.company_info.company_name', headerName: '企業名', /*width: 150,*/ sortable:true, filter:false, },
    { field: 'username', headerName: 'ユーザ名', /*width: 150,*/ sortable:true, filter:true, resizable:true, },
    { field: 'lastname', headerName: '姓', /*width: 150,*/ sortable:true, filter:true, resizable:true, },
    { field: 'firstname', headerName: '名前', /*width: 150,*/ sortable:true, filter:true, resizable:true, },
];

//毛髪情報・アンケート回答状態カラム
const user_answer_status_columns = [
    { field: 'username', headerName: 'ユーザ名', sortable:true, filter:true, resizable:true, hide:true, },
    { field: 'pertinent_month', headerName: '該当年月', sortable:true, filter:true,  resizable:true, },
    { field: 'hair_info_exist', headerName: '毛髪データ登録状態', sortable:true, filter:false, resizable:true, cellStyle: cellStyleAnswerStatus, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataAnswerStatus, },
    { field: 'survey_answer_status', headerName: 'アンケート回答状態', sortable:true, filter:false, resizable:true, cellStyle: cellStyleAnswerStatus, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataAnswerStatus, },
    { field: 'feedback_result_exist', headerName: '登録状態', sortable:true, filter:false, resizable:true, cellStyle: cellStyleAnswerStatus, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataAnswerStatus, },
    { field: 'feedback_release_status', headerName: '公開状態', sortable:true, filter:false, resizable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataReleaseStatus, },

];

const feedback_release_columns = [    
    { field: 'pertinent_month', headerName: '該当年月', sortable:true, filter:true, },
    { field: 'user_info.detail_info.company_info.company_name', headerName: '企業名', sortable:true, filter:true, resizable:true, hide: true,},
    { field: 'username', headerName: 'ユーザ名', sortable:true, filter:true, resizable:true, },
    { field: 'release_status', headerName: '公開状態', sortable:true, filter:false, resizable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataReleaseStatus, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate =  '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

class ManagerFeedbackManagerPage extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            complete_load: false,
            //取引先情報
            partner_user_columns: partner_user_columns,
            partner_user_infos: null,
            selected_username: null,
            
            //毛髪
            user_answer_status_manager_open: false,
            user_answer_status_columns:user_answer_status_columns,
            user_answer_status_infos: null,

            //フィードバック
            feedback_release_manager_open: false,
            feedback_release_columns:feedback_release_columns,
            feedback_release_infos:null,

            snackbar_message: "",
            snackbar_open: false,            
        };
    }
    
    componentDidMount(props){
        //ユーザ情報リスト取得
        Axios(GetUserListOptions).then((res) => {
            const tempUserList = res.data;
                
            Object.keys(tempUserList).forEach(function (key) {
                var user = tempUserList[key];
                user.id = user._id;
                console.log(user);
            });

            const userList = tempUserList;

            this.setState({
                complete_load: true,
                partner_user_infos: userList, 
            });   
        }).catch(err => {
            console.log(err)
        });    
    }

    showUserAnswerStatusManager = (event) =>  {
        const rowData = event.data;
        
        const params = {
            username:rowData.username,
        }
        GetUserAnswerStatusOptions.params = params;

        Axios(GetUserAnswerStatusOptions).then((res) => {
            // console.log(res.data);
            this.setState({
                selected_username: rowData.username,
                user_answer_status_infos: res.data,
            });   
        }).catch(err => {
            console.log(err);
        });
        

        this.setState({
            user_answer_status_manager_open:true,
        });
    }

    showFeedbackReleaseManager = (event) =>  {
        Axios(GetAllFeedbackListOptions).then((res) => {
            // console.log(res.data);
            this.setState({
                feedback_release_infos: res.data.feedback_result_list,
            });     
        }).catch(err => {
            console.log(err);
        });

        this.setState({
            feedback_release_manager_open:true,
        });
    }

    handleCloseAnswerStatusManager = (event) => {
        this.setState({
            user_answer_status_manager_open: false,
            user_answer_status_infos: null,
        });
    };

    handleCloseReleaseManager = (event) => {
        this.setState({
            feedback_release_manager_open: false,
            feedback_release_infos: null,
        });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { classes } = this.props;
        
        return (
            <div className={classes.root}>
                <Container maxWidth="md">
                    <Grid container spacing={2} justify="space-between">
                        <Grid item xs={12}>
                            <Button onClick={this.showFeedbackReleaseManager} color="primary" >フィードバック公開管理</Button>                                                  
                            <div 
                                className="ag-theme-material" 
                                style={{
                                    // width:800, 
                                    height:400,
                                }}
                            >
                                <AgGridReact
                                    columnDefs={this.state.partner_user_columns}
                                    rowData={this.state.partner_user_infos}
                                    // onRowDoubleClicked={this.showUserAnswerStatusManager}
                                    onRowClicked={this.showUserAnswerStatusManager}
                                    enableMultiRowDragging={true}
                                    onGridReady={(params) =>{ 
                                        this.gridApi = params.api
                                        this.gridApiColumns = params.columnApi
                                    }}
                                    overlayLoadingTemplate={overlayLoadingTemplate}
                                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    onRowDataChanged={(params) => {
                                        const allColumnIds = [];
                                        this.gridApiColumns.getAllColumns().forEach(function (column) {
                                            allColumnIds.push(column.colId);
                                        });
                                        this.gridApiColumns.autoSizeColumns(allColumnIds, false);
                                    }}
                                />
                            </div>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" >
                                戻る
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                {/* ユーザ別のフィードバック一覧 */}
                <Dialog maxWidth="md" onClose={this.handleCloseAnswerStatusManager} aria-labelledby="customized-dialog-title" open={this.state.user_answer_status_manager_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseAnswerStatusManager}>
                        フィードバック一覧
                    </DialogTitle>
                    <DialogContent dividers>
                        <div 
                            className="ag-theme-material" 
                            style={{
                                width:800, 
                                height:400,
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.user_answer_status_columns}
                                rowData={this.state.user_answer_status_infos}
                                enableMultiRowDragging={true}
                                onGridReady={(params) =>{ 
                                    this.gridApiUserHair = params.api
                                    this.gridApiUserHairColumns = params.columnApi
                                }}
                                loadingCellRendererParams
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                overlayNoRowsTemplate={overlayNoRowsTemplate}
                                onRowDataChanged={(params) => {
                                    const allColumnIds = [];
                                    this.gridApiUserHairColumns.getAllColumns().forEach(function (column) {
                                        allColumnIds.push(column.colId);
                                    });
                                    this.gridApiUserHairColumns.autoSizeColumns(allColumnIds, false);
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions/>
                </Dialog>

                <Dialog maxWidth="md" onClose={this.handleCloseReleaseManager} aria-labelledby="customized-dialog-title" open={this.state.feedback_release_manager_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseReleaseManager}>
                        フィードバック公開管理
                    </DialogTitle>
                    <DialogContent dividers>
                        <div 
                            className="ag-theme-material" 
                            style={{
                                width:800, 
                                height:400,
                            }}
                        >                            
                            <AgGridReact
                                columnDefs={this.state.feedback_release_columns}
                                rowData={this.state.feedback_release_infos}
                                enableMultiRowDragging={true}
                                onGridReady={(params) =>{ 
                                    this.gridReleaseApi = params.api
                                    this.gridReleaseApiColumns = params.columnApi
                                }}
                                rowSelection="multiple"            
                                enableCellChangeFlash={true}
                                loadingCellRendererParams         
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                overlayNoRowsTemplate={overlayNoRowsTemplate}
                                onRowDataChanged={(params) => {
                                    const allColumnIds = [];
                                    this.gridReleaseApiColumns.getAllColumns().forEach(function (column) {
                                        allColumnIds.push(column.colId);
                                    });
                                    this.gridReleaseApiColumns.autoSizeColumns(allColumnIds, false);
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions/>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ManagerFeedbackManagerPage);