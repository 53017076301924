import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';


const styles = (theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  typography: {
    backgroundColor: "#ddddddff",
    padding: theme.spacing(1),
  },
  divider: {
    background: "#2f2fffff",
  },
  
}));

class PrivacyPolicyPage extends React.Component {
    constructor(props){
      super(props);
     
    }
  
    render(){
      const { classes } = this.props;
      
      return (
        <div className={classes.root}>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              
              <Grid item xs={12}>
                <Typography variant="h7">
                  株式会社イヴケア（以下、「当社」といいます。）は、当社の運営する各ウェブサイト（以下、「本サイト」といいます。）及び当社の各事業等において収集する個⼈情報について、個⼈情報の保護に関する法律（平成15年法律第57号。以下、「個⼈情報保護法」といいます。）を遵守するとともに、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めて遵守します。
                </Typography>
              </Grid>
              
              {/* <Grid item xs={12}>
                <Divider fullWidth className={classes.divider}/>
              </Grid> */}

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第１条（個⼈情報）
                </Typography>
                <Typography variant="h7">
                  <ol>
                    <li>本ポリシーにおいて、「個人情報」「個人データ」「匿名加工情報」とは、個人情報保護法に定めるものをいいます。</li>
                    <li>本ポリシーにおいて、「履歴情報および特性情報」とは、前項に定める「個人情報」以外のものであって、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのＩＰアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。</li>
                    <li>本ポリシーにおいて、「ユーザー」とは、本サイトの利用者又は当社が提供するサービス（以下、「本サービス」といいます。）の利用者（求職者を含みます。）をいいます。</li>
                  </ol>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第２条（個⼈情報の収集⽅法）
                </Typography>
                <Typography variant="h7">
                  <ol>
                    <li>当社は、以下に掲げる場合の他、当社の各事業等に関し、適法かつ公正な⼿段によって個⼈情報を取得します。</li>
                    (1)ユーザーが、本サービスの利⽤登録を⾏う場合<br/>
                    (2)ユーザーが、本サービスを利⽤する場合<br/>
                    (3)ユーザーと提携先などとの間でなされたユーザーの個⼈情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから取得する場合<br/>
                    (4)お問い合わせ窓⼝を含む本サイト上の⼊⼒<br/>
                    (5)採⽤活動において、履歴書等の電⼦データ、書⾯等を当社に提出した場合<br/>
                    <li>当社は、ユーザーが本サイトまたは提携先のサービスを利⽤しまたはページを閲覧する際に、ユーザーの履歴情報及び特性情報を収集します。</li>
                    
                  </ol>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第３条（個⼈情報を収集・利⽤する⽬的）
                </Typography>
                <Typography variant="h7">
                  <p>当社が個⼈情報を収集・利⽤する⽬的は、以下のとおりです。</p>
                    (1)本サービスの提供<br/>
                    (2)本サービスに関するお知らせ、当社からのご連絡、お問い合わせ対応<br/>
                    (3)ユーザーの本⼈確認<br/>
                    (4)ユーザーに対する代⾦請求<br/>
                    (5)ユーザーが簡便にデータを⼊⼒できるようにするために、当社に登録されている情報を⼊⼒画⾯に表⽰させたり、ユーザーのご指⽰に基づいて他のサービスなど（提携先が提供するものも含みます）に転送するため<br/>
                    (6)本サービスの利⽤規約その他当社が別に定める規約等に違反したユーザーや、不正・不当な⽬的でサービスを利⽤しようとするユーザーの調査確認<br/>
                    (7)ユーザーのサービス利⽤状況の確認<br/>
                    (8)本サービスの運営・管理・改善、新規サービスの研究開発、マーケティング<br/>
                    (9)当社がユーザーに有益と判断するサービスや商品の情報（第三者が提供するものも含みます）の提供<br/>
                    (10)当社が、当社又は第三者のために商品・サービスの開発、製造、販売、研究、分析、統計等に利用（当社が個人を特定できない加工等をした統計データ等（以下「統計情報」といいます）を第三者が利用する場合を含みます）する場合<br/>
                    (11)当社の採⽤活動のため（⼊社後の⼈事管理を含む）<br/>
                    (12)上記の利⽤⽬的に付随する⽬的<br/>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第４条（個⼈データの第三者提供）
                </Typography>
                <Typography variant="h7">
                  <p>当社は、個⼈情報保護法その他法令で認められる場合を除き、あらかじめユーザーの同意を得ることなく、第三者に個⼈データを提供することはありません。</p>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第５条（匿名加工情報及び統計情報）
                </Typography>
                <Typography variant="h7">
                  <ol>
                    <li>本ポリシーにおいて、「個人情報」「個人データ」「匿名加工情報」とは、個人情報保護法に定めるものをいいます。</li>
                    <li>本ポリシーにおいて、「履歴情報および特性情報」とは、前項に定める「個人情報」以外のものであって、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのＩＰアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。</li>
                    <li>本ポリシーにおいて、「ユーザー」とは、本サイトの利用者又は当社が提供するサービス（以下、「本サービス」といいます。）の利用者（求職者を含みます。）をいいます。</li>
                  </ol>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第６条（個人情報の開示）
                </Typography>
                <Typography variant="h7">
                  <p>当社は、ユーザーから提供を受けた個人情報をもとに、匿名加工情報を利用する場合には、個人情報保護法その他関連する法令等を遵守し、適切な手続きに従って匿名加工情報を作成します。また、匿名加工情報を第三者に提供したり、第三者から匿名加工情報の提供を受けたりする場合も、個人情報保護法その他関連する法令等を遵守し、適切な手続きに従って実施します。</p>
                  <p>なお、当社は、個人情報を、匿名加工情報又は統計情報として利用する場合には、取得の際の利用目的に限らず様々な目的で利用したり、第三者に提供したりすることがあります。統計情報については、公表することもあります。</p>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第７条（個人情報の訂正および削除）
                </Typography>
                <Typography variant="h7">
                  <ol>
                    <li>ユーザーは、当社の保有する⾃⼰の個⼈情報が誤った情報である場合には、当社が定める⼿続きにより、当社に対して個⼈情報の訂正または削除を請求することができます。</li>
                    <li>当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個⼈情報の訂正または削除を⾏い、これをユーザーに通知します。</li>
                  </ol>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第８条（個人情報の利用停止等）
                </Typography>
                <Typography variant="h7">
                  <ol>
                    <li>本ポリシーにおいて、「個人情報」「個人データ」「匿名加工情報」とは、個人情報保護法に定めるものをいいます。</li>
                    <li>本ポリシーにおいて、「履歴情報および特性情報」とは、前項に定める「個人情報」以外のものであって、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのＩＰアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。</li>
                    <li>本ポリシーにおいて、「ユーザー」とは、本サイトの利用者又は当社が提供するサービス（以下、「本サービス」といいます。）の利用者（求職者を含みます。）をいいます。</li>
                  </ol>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第９条（本ポリシーの変更）
                </Typography>
                <Typography variant="h7">
                  <ol>
                    <li>本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。ただし、変更した場合には、本サイト上に掲載する等適宜の⽅法により通知いたします。</li>
                    <li>当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効⼒を⽣じるものとします。</li>
                  </ol>
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={4}>
                <Divider fullWidth className={classes.divider}/>
                <Typography className={classes.typography} variant="h6">
                  第１０条（お問い合わせ窓⼝）
                </Typography>
                <Typography variant="h7">
                  <p>本ポリシーに関するお問い合わせは、下記の窓⼝までお願いいたします。</p>
                  住所　：滋賀県大津市平津2-5-1<br/>
                  社名　：株式会社イヴケア<br/>
                  Eメールアドレス　：info@evcare.co.jp<br/>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }
}

export default withStyles(styles)(PrivacyPolicyPage);