import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import AuthService from '../../_services/authService';

const styles = (theme => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },

}));


class ManagerMainPage extends React.Component {
    constructor(props){
      super(props);
      
    }
  
    render(){
      const { classes } = this.props;
      return (
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
    
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center" direction="column">
                <Grid item>
                  <Button variant="outlined" color="primary" fullWidth={true} onClick={()=>this.props.history.push("/managerusermanager")}>
                    利用ユーザ管理
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" fullWidth={true} onClick={()=>this.props.history.push("/managerfeedbackmanager")}>                   
                    フィードバック管理
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      );
    }
}

export default withStyles(styles)(ManagerMainPage);