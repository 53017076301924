// import React from 'react';
import React, { useState } from 'react';
import AxiosBase from 'axios';
import { Formik } from 'formik';
import queryString from 'query-string';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { Authority } from '../../_helper/Authority';
import { Gender } from '../../_helper/Gender';
import RegHelper from '../../_helper/RegexHelper';

import LogoImage from '../../img/logo.png';

import PrivacyPolicyPage from "../PrivacyPolicy";

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
  headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',   
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

const RegistUserOptions = {
  method: 'POST',
  withCredentials: true,
  data: null,
  url: `${BaseURL}/user_manual_register`,
}

const styles = (theme => ({
  paper: {
    // marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {    
    marginTop: theme.spacing(8),    
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  warning: {
    color: 'red',
  },
}));

const authorityList = [
  {
    key: 0,
    value: Authority.User,
    label:'一般ユーザ',
  },
  {
    key: 1,
    value: Authority.Manager,
    label:'ユーザマネージャ',
  },
  {
    key: 2,
    value: Authority.Admin,
    label:'管理者',
  },
]

const genderList = [
  {
    key: 0,
    value: Gender.Male,
    label:'男性',
  },
  {
    key: 1,
    value: Gender.Female,
    label:'女性',
  },
]

const textLabels = {
  LABEL_FIRST_NAME : "名",
  LABEL_LAST_NAME : "姓",
  LABEL_BIRTHDAY : "生年月日",
  LABEL_COMPANY_NAME : "企業名",
  LABEL_EMAIL_ADDRESS : "Eメールアドレス",
  LABEL_PASSWORD : "パスワード",
  LABEL_USER_AUTHORITY : "ユーザ権限",
  LABEL_USER_REGIST : "ユーザ登録",
  LABEL_GENDER: "性別",
}

const registResults = {
  REGIST_RESULT_NOT_EMAIL_FORMAT: "メールアドレスのフォーマットではありません",
  REGIST_RESULT_ALREADY_EXIST_ACCOUNT : "すでに存在しているアカウントです",
  REGIST_RESULT_FAILED_REGIST : "アカウント登録に失敗しました",
}

class UserSignUpManual extends React.Component {
  constructor(props){
    super(props);
    
    const qs = queryString.parse(this.props.location.search);
    const company_name = qs.company;

    // console.log(qs);

    this.state = {
      company_name: company_name,
      regist_result: null,
      check_agree: false,
      is_submitting: false,
    }
  }

  componentDidMount(props) {
    
  }

  register = (data) => {
    const params = data;

    if(!RegHelper.checkEmail(params.username)){
      this.setState({
        regist_result: registResults.REGIST_RESULT_NOT_EMAIL_FORMAT,
      });
      
      return;
    }
  
    RegistUserOptions.data = params;

    this.setState({is_submitting:true});
    Axios(RegistUserOptions).then((res) => {
      console.log(res.data);
      
      this.setState({is_submitting:false});
      window.location.href = "/signin";
      
    }).catch((err) => {
      const responseResult = err.response.data;

      const result = responseResult.result;
      const reason = responseResult.reason;

      var regist_result = null;

      if(reason == "User Already Exists"){
        regist_result = registResults.REGIST_RESULT_ALREADY_EXIST_ACCOUNT;
      }else{        
        regist_result = registResults.REGIST_RESULT_FAILED_REGIST;
      }
      
      this.setState({
        is_submitting: false,
        regist_result: regist_result,
      });
    });
  }  

  render() {
    const { classes } = this.props;
    
    return (
      <div>
        <CssBaseline />
        <div className={classes.paper}>          
          <Formik
            initialValues={
              {
                // lastname:"lastname", 
                // firstname:firstname, 
                // email:email, 
                // password:password, 
                // authority:authority,
              }
            }
            onChange={(values) => {
              //handleChangeを登録しても動作しない
              // console.log("onChange");
              console.log(values);
            }}
            onSubmit={async (data, {setSubmitting}) => {                              
              const regist_data = {
                company_name: this.state.company_name,
                username: data.username,
                lastname: data.lastName,
                firstname: data.firstName,
                gender: data.gender,
                birthday: data.birthday,
                password: data.password,
              }
              await this.register(regist_data);
              
            }}
            // render={(props) => ()}
          >
            {(props) => (
              <form id="testForm" className={classes.form} onSubmit={props.handleSubmit}>
                <Container maxWidth="xs">        
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>            
                      <div className={classes.header}>
                        <img src={LogoImage} alt="Evcare Logo" width={"300"} />
                        <Typography component="h2" variant="h5">
                          アカウント作成
                        </Typography>
                      </div>
                    </Grid> 
                    <Grid item xs={12}>
                      <Typography variant="h6" color="primary">
                        以下の情報を登録し、サインインしてください。
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="outlined-disabled"
                        label={textLabels.LABEL_COMPANY_NAME}
                        name="company_name"
                        defaultValue={this.state.company_name}
                        inputProps={{
                          readOnly:true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label={textLabels.LABEL_EMAIL_ADDRESS}
                        id="username"
                        name="username"
                        onChange={props.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label={textLabels.LABEL_LAST_NAME}
                        name="lastName"                   //データ識別
                        autoComplete="lname"
                        onChange={props.handleChange}
                        // value={props.values.lastname}
                        // onBlur={e => console.log(e.target.value)}
                        // onChange={e => setRegisterLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        autoComplete="fname"
                        name="firstName"                 //データ識別
                        variant="outlined"
                        id="firstName"
                        label={textLabels.LABEL_FIRST_NAME}
                        onChange={props.handleChange}
                        // value={props.values.firstname}
                        // onChange={e => setRegisterFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        label={textLabels.LABEL_GENDER}
                        id="gender"
                        name="gender"
                        onChange={props.handleChange}
                        helperText="性別を選択してください"                
                        defaultValue={""}
                      >
                        {genderList.map((option) => (
                          <MenuItem key={option.key} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="birthday"
                        name="birthday"
                        label={textLabels.LABEL_BIRTHDAY}
                        type="date"
                        className={classes.textField}
                        onChange={props.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label={textLabels.LABEL_PASSWORD}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={props.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Container>
                
                <Container maxWidth="md">        
                  <Grid container spacing={2} style={{marginTop:"10px"}}>                      
                    <Grid item xs={12} md={12}>
                      <div style={{height:300, overflow: "scroll", "overflow-x": "hidden", border:"solid black 1px", padding:"13px"}}>
                        <PrivacyPolicyPage />
                      </div>
                    </Grid>
                  </Grid>
                </Container>

                <Container maxWidth="xs">        
                  <Grid container spacing={2}>
                    <Grid item xs={12}>              
                      <FormControlLabel
                        control={<Checkbox value="agree" color="primary" onChange={(e) => {this.setState({check_agree:e.target.checked})} }/>}
                        label="プライバシーポリシーの内容を確認しました"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={this.state.is_submitting || !this.state.check_agree}
                        // onClick={register}
                      >
                        {textLabels.LABEL_USER_REGIST}
                      </Button>
                    </Grid>
                    
                    <Grid container justify="flex-end">
                      <Box className={classes.warning} display="block" displayPrint="none">
                        {this.state.regist_result}
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </form>
            )}
          </Formik>
        </div>
      </div>      
    )
  }
}

export default withStyles(styles)(UserSignUpManual);