// import React from 'react';
import React, { useState } from 'react';
import AxiosBase from 'axios';
import { Formik } from 'formik';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { Authority } from '../_helper/Authority';
import { Gender } from '../_helper/Gender';
import RegHelper from '../_helper/RegexHelper';

import LogoImage from '../img/logo.png';
import { Check } from '@material-ui/icons';

import PrivacyPolicyPage from "./PrivacyPolicy";


const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

const RegistUserOptions = {
  method: 'POST',
  withCredentials: true,
  data: null,
  url: `${BaseURL}/register`,
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  warning: {
    color: 'red',
  },
}));
const authorityList = [
  {
    key: 0,
    value: Authority.User,
    label: '一般ユーザ',
  },
  {
    key: 1,
    value: Authority.Manager,
    label: 'ユーザマネージャ',
  },
  {
    key: 2,
    value: Authority.Admin,
    label: '管理者',
  },
]

const genderList = [
  {
    key: 0,
    value: Gender.Male,
    label: '男性',
  },
  {
    key: 1,
    value: Gender.Female,
    label: '女性',
  },
]

const textLabels = {
  LABEL_FIRST_NAME: "名",
  LABEL_LAST_NAME: "姓",
  LABEL_BIRTHDAY: "生年月日",
  LABEL_EMAIL_ADDRESS: "Eメールアドレス",
  LABEL_PASSWORD: "パスワード",
  LABEL_USER_AUTHORITY: "ユーザ権限",
  LABEL_USER_REGIST: "ユーザ登録",
  LABEL_GENDER: "性別",
}

const registResults = {
  REGIST_RESULT_NOT_EMAIL_FORMAT: "メールアドレスのフォーマットではありません",
  REGIST_RESULT_ALREADY_EXIST_ACCOUNT: "すでに存在しているアカウントです",
  REGIST_RESULT_FAILED_REGIST: "アカウント登録に失敗しました",
}


const RegisterForm = () => {
  const classes = useStyles();

  const [registerResult, setRegisterResult] = useState();
  const [checkAgree, setCheckAgree] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const register = (props) => {

    if (!RegHelper.checkEmail(props.email)) {
      setRegisterResult(registResults.REGIST_RESULT_NOT_EMAIL_FORMAT);
      return;
    }

    RegistUserOptions.data = {
      firstname: props.firstName,
      lastname: props.lastName,
      username: props.email,
      password: props.password,
      authority: "admin_authority",
      birthday: props.birthday,
      gender: props.gender,
    };

    setIsSubmitting(true);

    Axios(RegistUserOptions).then((res) => {
      const responseResult = res.data;
      const result = responseResult.result;
      setIsSubmitting(false);
      window.location.href = "/signin";
    }).catch((err) => {
      const responseResult = err.response.data;
      const result = responseResult.result;
      const reason = responseResult.reason;

      if (reason === "User Already Exists") {
        setRegisterResult(registResults.REGIST_RESULT_ALREADY_EXIST_ACCOUNT);
      } else {
        setRegisterResult(registResults.REGIST_RESULT_FAILED_REGIST);
      }

      setIsSubmitting(false);
    });

  }


  return (
    <Formik
      // enableReinitialize={true} 
      initialValues={
        {
          // lastname:"lastname", 
          // firstname:firstname, 
          // email:email, 
          // password:password, 
          // authority:authority,
        }
      }
      onChange={(values) => {
        //handleChangeを登録しても動作しない
        // console.log("onChange");
        console.log(values);
      }}
      onSubmit={async (data, { setSubmitting }) => {
        await register(data);
      }}
    // render={(props) => ()}
    >
      {(props) => (
        <form id="testForm" className={classes.form} onSubmit={props.handleSubmit}>
          <Container component="main" maxWidth="xs">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.header}>
                  <img src={LogoImage} alt="Evcare Logo" width={"300"} />
                  <Typography component="h2" variant="h5">
                    アカウント作成
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  以下の情報を登録し、サインインしてください。
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label={textLabels.LABEL_LAST_NAME}
                  name="lastName"                   //データ識別
                  autoComplete="lname"
                  value={props.values.lastname}
                  onChange={props.handleChange}
                // onBlur={e => console.log(e.target.value)}
                // onChange={e => setRegisterLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  autoComplete="fname"
                  name="firstName"                 //データ識別
                  variant="outlined"
                  id="firstName"
                  label={textLabels.LABEL_FIRST_NAME}
                  onChange={props.handleChange}
                // value={props.values.firstname}
                // onChange={e => setRegisterFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  label={textLabels.LABEL_GENDER}
                  id="gender"
                  name="gender"
                  onChange={props.handleChange}
                  helperText="性別を選択してください"
                  defaultValue={""}
                >
                  {genderList.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="birthday"
                  name="birthday"
                  label={textLabels.LABEL_BIRTHDAY}
                  type="date"
                  className={classes.textField}
                  onChange={props.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label={textLabels.LABEL_EMAIL_ADDRESS}
                  name="email"
                  autoComplete="email"
                  // value={props.values.email}
                  onChange={props.handleChange}
                // onChange={e => setRegisterEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label={textLabels.LABEL_PASSWORD}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={props.handleChange}
                // value={props.values.password}
                // onChange={e => setRegisterPassword(e.target.value)}
                />
              </Grid>
            </Grid>

          </Container>

          <Container maxWidth="md">
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12} md={12}>
                <div style={{ height: 300, overflow: "scroll", "overflow-x": "hidden", border: "solid black 1px", padding: "10px" }}>
                  <PrivacyPolicyPage />
                </div>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="xs">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="agree" color="primary" onChange={(e) => { setCheckAgree(e.target.checked) }} />}
                  label="プライバシーポリシーの内容を確認しました"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting || !checkAgree}
                // onClick={register}
                >
                  {textLabels.LABEL_USER_REGIST}
                </Button>
              </Grid>

              <Grid container justify="flex-end">
                <Box className={classes.warning} display="block" displayPrint="none">
                  {registerResult}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
    </Formik>
  )
}

export default function SignUp() {
  const classes = useStyles();

  return (

    <div>
      <CssBaseline />
      <RegisterForm />
    </div>
  );
}
