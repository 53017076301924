import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import {
    ResponsiveContainer,
    ComposedChart, Line, XAxis, YAxis, CartesianGrid, Legend, Bar,
    RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Label, Tooltip as RechartTooltip
}
    from 'recharts';

import FeedbackImage from '../../img/Feedback.jpg';
import LogoImage from '../../img/logo.png';

import ArrowYourPosition from '../../img/arrow_yourposition.png'
import MonitoringAnswer from '../../img/monitor/monitoring_answer.png'
import MonitoringDraw from '../../img/monitor/monitoring_draw.png'
import MonitoringBlank from '../../img/monitor/monitoring_blank.png'
import MonitoringHair from '../../img/monitor/monitoring_hair.png'
import IconObjectiveStress from '../../img/icon_objective_stress.png';
import IconSubjectiveStress from '../../img/icon_subjective_stress.png';


import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AuthService from '../../_services/authService';

import AxiosBase from 'axios';

const textLabels = {
    LABELS_STRESS_LEVEL: [
        "低め", "やや低め", "標準", "やや高め", "高め",
    ],
    LABEL_OBJECTIVE_COMMENT: "所見",
    LABEL_SUBJECTIVE_COMMENT: "所見",
    LABEL_TOTAL_COMMENT: "総評",
}

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

//AXIOS Options
//フィードバック計算結果情報取得用
const GetCalculateStressResultOptions = {
    method: 'GET',
    params: null,
    withCredentials: true,
    url: `${BaseURL}/admin/get_calculate_stress_result`,
}

//フィードバック登録用
const UpdateUserFeedbackDataOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/regist_user_feedback_data`,
}

const styles = (theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

        border: '5px solid #fccbcc',
        borderRadius: 20,
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),

        border: '2px solid #fccbcc',
        borderRadius: 20,

        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: 'none',
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    box: {
        borderRadius: 5,
        border: 1,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        height: 40,
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: '#fca2cc',
    },
    objective_box_1: {
        // background: 'linear-gradient(#fc8ccc, #8a8a8a)',
        backgroundColor: '#fccbcc'
    },
    objective_box_2: {
        // background: 'linear-gradient(#f968a8, #fc8ccc)',
        backgroundColor: '#fca2cc'
    },
    objective_box_3: {
        // background: 'linear-gradient(#f968a8, #f70790, #f968a8)',
        backgroundColor: '#fc72cc'
    },
    objective_box_4: {
        // background: 'linear-gradient(#fc8ccc, #f968a8)',
        backgroundColor: '#fca2cc'
    },
    objective_box_5: {
        // background: 'linear-gradient(#8a8a8a, #fc8ccc)',
        backgroundColor: '#fccbcc',
    },
    //主観的ボックス
    subjective_box_1: {
        // background: 'linear-gradient(#fcaacf, #dcdcdc)',
        backgroundColor: '#fccbcc',
    },
    subjective_box_2: {
        // background: 'linear-gradient(#f968a8, #fcaacf)',
        backgroundColor: '#fca2cc',
    },
    subjective_box_3: {
        // background: 'linear-gradient(#fc8ccc, #f968a8)',
        backgroundColor: '#fc72cc',
    },
    subjective_box_4: {
        // background: 'linear-gradient(#8a8a8a, #fc8ccc)',
        backgroundColor: '#fca2cc',
    },
    subjective_box_5: {
        // background: 'linear-gradient(#787878, #8a8a8a)',
        backgroundColor: '#fccbcc',
    },
}));

// //データ管理用カラム
const video_columns = [
    { field: 'video_id', headerName: '動画番号', sortable: true, filter: true, resizable: true, checkboxSelection: true, /*rowGroup: true*/ },
    { field: 'video_name', headerName: '動画名', sortable: true, filter: true, resizable: true, },
    { field: 'url', headerName: 'URL', sortable: true, filter: true, resizable: true, },
];

const objective_stress_explain = "この項目では、毛髪に蓄積されたストレスホルモンを測定し３つの視点から評価を行っています。\n黄色い矢印で、母集団と比較したときのあなたの位置を示しています。";
const subjective_stress_explain = "この項目では、質問紙によって測定された主観的ストレス評価を４つの視点から行っています。\n棒グラフでは、黄色い矢印で、母集団と比較したときのあなたの位置を示しています。";
const monitoring_explain = "この項目では、客観的ストレスと主観的ストレスのバランスで、あなたのストレスモニタリングタイプを評価しています。モニタリングタイプは、最適モニタリング、過少モニタリング、過剰モニタリングの３つ分類されます。最適モニタリングタイプの方は、主観的なストレス評価と客観的なストレス評価がある程度一致されている方です。過少モニタリングタイプの方は、客観的なストレス評価に、過剰モニタリングタイプの方は主観的なストレス評価に偏りがある傾向を示しています。";

const cortidol_level_explain = "この項目では、測定した期間の間にどのくらいストレスに曝されたかという、ストレス曝露量を示しています。このホルモンは、免疫系や、代謝系などに作用を及ぼすと言われています。中央が平均で、そこから上に向かうほどストレスホルモン値が高く、下に向かうほどストレスホルモン値が低いことを示しています。";
const dhea_level_explain = "ストレス反応と対になる作用をもたらす抗ストレス反応の項目です。抗ストレス反応とは、ストレス反応による身体への影響を和らげようとする力の大きさを示すものです。平均が中央で、上に向かうほど抗ストレスホルモン値が高く、下に向かうほど抗ストレスホルモン値が低いことを示しています。";
const cortidol_dhea_level_explain = "これは、ストレス反応と抗ストレス反応の比率より算出した値です。ストレス反応が高いと良くないのでは、と思われる方は多くいらっしゃるかもしれません。しかし、実はストレス反応がたとえ高くても、抗ストレス反応がきちんと作用をしていれば、問題はないと言われています。そのため、この指標は、私たちの心身の健康を守るうえで最も重要な指標とされています。ストレスに打ち勝ち、逆境を跳ね返す力を「レジリエンス」といいますが、そのレジリエンスの指標であるとも言えます。平均が中央で、上に向かうほどレジリエンスが高く、下に向かうほどレジリエンスが低いことを示しています。"

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate = '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

class AdminFeedbackRegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            objective_stress_box_list_cortidol: null,
            objective_stress_box_list_dhea: null,
            objective_stress_box_list_cortidol_dhea: null,
            subjective_stress_box_list_total_stress: null,

            calculate_loading: true,
            calculate_result: null,
            convert_to_format_date: null,

            monitoring_image: null,
            objective_stress_data: [],
            subjective_stress_data: [],

            previous_feedback_list: [],
            username: null,
            company_name: null,
            age: null,

            alert_dialog_open: false,
            alert_dialog_message: "",
            alert_dialog_title: "",

            video_list_columns: video_columns,
            video_list: null,

            hair_default_feedback: "",
            enquete_default_feedback: "",

        };
        // this.showUserHairManager = this.showUserHairManager.bind(this);
    }

    componentDidMount(props) {
        const { classes } = this.props;

        const username = this.props.location.state.username;
        const pertinent_month = this.props.location.state.pertinent_month;
        const answer_status = this.props.location.state.answer_status;

        console.log(`User Name:${username}`);
        console.log(`Pertinent Month:${pertinent_month}`);
        console.log(answer_status);

        const params = {
            username: username,
            pertinent_month: pertinent_month,
        }
        GetCalculateStressResultOptions.params = params;

        //計算
        Axios(GetCalculateStressResultOptions).then((res) => {
            const responseResult = res.data;

            if (res.data.result == "success1")
                this.setState({
                    hair_default_feedback: "（毛髪データがありません。）",
                })
            else if (res.data.result == "success2") {
                this.setState({
                    enquete_default_feedback: "（アンケートデータがありません。）",
                })
            } else
                this.setState({
                    hair_default_feedback: "",
                    enquete_default_feedback: "",
                })

            console.log(responseResult)

            // const previous_feedback_list = responseResult.data.previous_feedback_result;
            const previous_hair_infos = responseResult.data.previous_hair_infos;
            const calculate_result = responseResult.data.calculate_result;
            const video_result = responseResult.data.video_result;

            const objectiveBoxClasses = [classes.objective_box_1, classes.objective_box_2, classes.objective_box_3, classes.objective_box_4, classes.objective_box_5,];
            const subjectiveBoxClasses = [classes.subjective_box_1, classes.subjective_box_2, classes.subjective_box_3, classes.subjective_box_4, classes.subjective_box_5,];

            const objective_stress_box_list_cortidol = [];
            const objective_stress_box_list_dhea = [];
            const objective_stress_box_list_cortidol_dhea = [];
            const subjective_stress_box_list_total_stress = [];

            const labels_stress_level = textLabels.LABELS_STRESS_LEVEL;

            for (var i = 4; i >= 0; i--) {
                objective_stress_box_list_cortidol.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {calculate_result.objective_stress.cortidol_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);

                objective_stress_box_list_dhea.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {calculate_result.objective_stress.dhea_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);

                objective_stress_box_list_cortidol_dhea.push(<Box className={`${classes.box} ${objectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {calculate_result.objective_stress.cortidol_dhea_level === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);
            }

            for (var i = 4; i >= 0; i--) {
                subjective_stress_box_list_total_stress.push(<Box className={`${classes.box} ${subjectiveBoxClasses[i]}`} textAlign="center">
                    <Typography style={{ color: "#ffffff", }} variant="subtitle1" align="center" display="inline" >{labels_stress_level[i]}</Typography>
                    {calculate_result.subjective_stress.total_stress === (i + 1) && <img src={ArrowYourPosition} alt="Yout Position" height="40" align="right" />}
                </Box>);
            }

            console.log(calculate_result);

            const objective_stress_data = [];

            previous_hair_infos.map((user_hair_info) => {
                const cortidol = user_hair_info.hair_info.cortidol;
                const dhea = user_hair_info.hair_info.dhea;
                const cortidol_dhea = cortidol / dhea;
                const graphRowData = {
                    month: user_hair_info.pertinent_month,
                    ストレス反応値: cortidol,
                    抗ストレス反応値: dhea,
                    反応のバランス値: cortidol_dhea,
                }
                objective_stress_data.push(graphRowData);
            });
            console.log(objective_stress_data);

            const subjective_stress_data = [
                { status: "身体バランス調整不全", level: calculate_result.subjective_stress.body_balance, standard_level: 3, fullMark: 5 },
                { status: "疲労身体反応", level: calculate_result.subjective_stress.fatigue, standard_level: 3, fullMark: 5 },
                { status: "不安不確実感", level: calculate_result.subjective_stress.anxiety, standard_level: 3, fullMark: 5 },
                { status: "うつ気分不全感", level: calculate_result.subjective_stress.fell_depression, standard_level: 3, fullMark: 5 },
            ];

            var monitoring_image = MonitoringDraw;

            console.log(res.data.result)

            if (calculate_result.subjective_stress.total_stress == -1 || calculate_result.objective_stress.cortidol_level == -1) {
                monitoring_image = MonitoringBlank

            } else {
                if (calculate_result.subjective_stress.total_stress > calculate_result.objective_stress.cortidol_level) {
                    monitoring_image = MonitoringAnswer;

                } else if (calculate_result.subjective_stress.total_stress < calculate_result.objective_stress.cortidol_level) {
                    monitoring_image = MonitoringHair;

                }
            }

            this.setState({
                objective_stress_box_list_cortidol: objective_stress_box_list_cortidol,
                objective_stress_box_list_dhea: objective_stress_box_list_dhea,
                objective_stress_box_list_cortidol_dhea: objective_stress_box_list_cortidol_dhea,
                subjective_stress_box_list_total_stress: subjective_stress_box_list_total_stress,

                monitoring_image: monitoring_image,
                objective_stress_data: objective_stress_data,
                subjective_stress_data: subjective_stress_data,

                calculate_loading: false,
                calculate_result: calculate_result,
                convert_to_format_date: new Date(calculate_result.regist_date),

                username: calculate_result.username,
                company_name: calculate_result.user_info.detail_info.company_info != undefined ? calculate_result.user_info.detail_info.company_info.company_name : "",
                age: calculate_result.age,

                video_list: video_result,

            })
        }).catch(err => {
            console.log(err)
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handleCloseAlertDialog = (event) => {
        this.setState({
            alert_dialog_open: false,
        })
    }

    showAlertDialog = (title, message) => {
        this.setState({
            alert_dialog_title: title,
            alert_dialog_message: message,
            alert_dialog_open: true,
        })
    }

    registFeedbackData = (data) => {
        console.log(data);

        if (data.objective_comment === "" || data.subjective_comment === "" || data.total_comment === "") {
            this.showAlertDialog("注意", "「所見」または「総評」が空欄です");
            return;
        }

        const selectedNodes = this.gridApiVideoList.getSelectedNodes();
        const selectedVideos = [];
        selectedNodes.map((node) => { selectedVideos.push(node.data); });

        const calculate_result = this.state.calculate_result;
        calculate_result.objective_comment = data.objective_comment;
        calculate_result.subjective_comment = data.subjective_comment;
        calculate_result.total_comment = data.total_comment;
        calculate_result.video_list = selectedVideos;

        console.log(calculate_result);

        UpdateUserFeedbackDataOptions.data = calculate_result;

        Axios(UpdateUserFeedbackDataOptions).then((res) => {
            const result = res.data.result;
            console.log(result);
            this.props.history.push("/");
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Formik
                initialValues={{ objective_comment: "", subjective_comment: "", total_comment: "", }}
                onSubmit={async (data, { setSubmitting }) => {
                    setSubmitting(true);

                    if (data.objective_comment === "") {
                        data.objective_comment = this.state.hair_default_feedback
                    } else if (data.subjective_comment === "") {
                        data.subjective_comment = this.state.enquete_default_feedback
                    }

                    await this.registFeedbackData(data);

                    setSubmitting(false);
                }}
                onChange={(values) => {
                    // console.log("onChange");
                    // console.log(values);
                }}
                render={(props) => (
                    <form className={classes.form} onSubmit={props.handleSubmit}>
                        <div className={classes.root}>
                            <Container maxWidth="md" className={classes.container}>
                                {this.state.calculate_loading && <Grid container justify="center"><CircularProgress /></Grid>}
                                {this.state.calculate_result &&
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={8} md={8} >
                                                <Typography variant="h4" display="inline" color="primary">
                                                    Stress Monitoring&nbsp;
                                                </Typography>
                                                <Typography variant="h4" display="inline">
                                                    Feedback Sheet
                                                </Typography>
                                                <Typography variant="h7" display="block">
                                                    ストレスモニタリング・フィードバックシート
                                                </Typography>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead} component="th" scope="row">
                                                                <div style={{ color: "#ffffff" }}>ID</div>
                                                            </TableCell>
                                                            <TableCell align="left">{this.state.username}</TableCell>
                                                            <TableCell className={classes.tableHead} component="th" scope="row">
                                                                <div style={{ color: "#ffffff" }}>作成日</div>
                                                            </TableCell>
                                                            <TableCell align="left">{`${this.state.convert_to_format_date.getFullYear()}-${this.state.convert_to_format_date.getMonth() + 1}-${this.state.convert_to_format_date.getDate()}`}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead} component="th" scope="row">
                                                                <div style={{ color: "#ffffff" }}>年代</div>
                                                            </TableCell>
                                                            <TableCell align="left">{this.state.age}</TableCell>
                                                            <TableCell className={classes.tableHead} component="th" scope="row">
                                                                <div style={{ color: "#ffffff" }}>分析期間</div>
                                                            </TableCell>
                                                            <TableCell align="left">{this.state.calculate_result.pertinent_month}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>

                                            <Grid item xs={12} sm={4} md={4} >
                                                <Table style={{ border: '1px solid #fccbcc', borderRadius: 20, }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="center" className={classes.tableHead} component="th" scope="row">
                                                                <div style={{ color: "#ffffff" }}>
                                                                    モニタリング・タイプ

                                                                    <Tooltip title={monitoring_explain}>
                                                                        <LiveHelpIcon />
                                                                    </Tooltip>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="center"><img src={this.state.monitoring_image} alt="Show Result" height="80" /></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>

                                        <Paper className={classes.paper}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography component="h2" variant="h6" color="primary" gutterBottom inline>
                                                        <img src={IconObjectiveStress} height="30" />
                                                        客観的ストレス
                                                        <Tooltip title={objective_stress_explain}>
                                                            <LiveHelpIcon />
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} md={4} lg={4} >
                                                    {this.state.objective_stress_box_list_cortidol}
                                                    <Typography variant="subtitle1" align="center" >
                                                        ストレス反応
                                                        <Tooltip title={cortidol_level_explain}>
                                                            <LiveHelpIcon />
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item xs={1} md={1} lg={1} >
                                                    {this.state.objective_stress_position_list_cortidol}
                                                </Grid> */}
                                                <Grid item xs={4} md={4} lg={4} >
                                                    {this.state.objective_stress_box_list_dhea}
                                                    <Typography variant="subtitle1" align="center" >
                                                        抗ストレス反応
                                                        <Tooltip title={dhea_level_explain}>
                                                            <LiveHelpIcon />
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item xs={1} md={1} lg={1} >
                                                    {this.state.objective_stress_box_list_dhea}
                                                </Grid> */}
                                                <Grid item xs={4} md={4} lg={4} >
                                                    {this.state.objective_stress_box_list_cortidol_dhea}
                                                    <Typography variant="subtitle1" align="center" >
                                                        反応のバランス
                                                        <Tooltip title={cortidol_dhea_level_explain}>
                                                            <LiveHelpIcon />
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {this.state.previous_feedback_list != undefined &&
                                                        <ResponsiveContainer height={300}>
                                                            <ComposedChart data={this.state.objective_stress_data} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="month" dy={10} />
                                                                <YAxis yAxisId="left" tick={{ fontSize: 10 }}>
                                                                    <Label angle={-90} value="バランス値" />
                                                                </YAxis>
                                                                <YAxis type="number" domain={[0, 1]} yAxisId="right" orientation="right" tick={{ fontSize: 10 }} >
                                                                    <Label angle={-90} value="反応値" />
                                                                </YAxis>
                                                                <RechartTooltip />
                                                                <Legend />
                                                                <Bar yAxisId="right" dataKey="ストレス反応値" fill="#8884d8" barSize={20} />
                                                                <Bar yAxisId="right" dataKey="抗ストレス反応値" fill="#82ca9d" barSize={20} />
                                                                <Line yAxisId="left" type="monotone" dataKey="反応のバランス値" stroke="#ff7300" />
                                                            </ComposedChart>
                                                        </ResponsiveContainer>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">
                                                        所見
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        id="standard-multiline-static"
                                                        name="objective_comment"
                                                        multiline
                                                        rows={4}
                                                        variant="outlined"
                                                        defaultValue={this.state.hair_default_feedback}
                                                        onChange={props.handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                        <Paper className={classes.paper}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <Typography align="middle" component="h2" variant="h6" color="primary" gutterBottom inline>
                                                        <img src={IconSubjectiveStress} height="30" />
                                                        主観的ストレス
                                                        <Tooltip title={subjective_stress_explain}>
                                                            <LiveHelpIcon />
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    {this.state.subjective_stress_box_list_total_stress}
                                                    <Typography variant="subtitle1" align="center" margin={50}>
                                                        ストレス反応
                                                        <Tooltip title={cortidol_level_explain}>
                                                            <LiveHelpIcon />
                                                        </Tooltip>
                                                    </Typography>

                                                </Grid>

                                                <Grid item xs={12} md={7} lg={7}>
                                                    <ResponsiveContainer height={250}>
                                                        <RadarChart data={this.state.subjective_stress_data} >
                                                            <PolarGrid />
                                                            <PolarAngleAxis dataKey="status" />
                                                            <PolarRadiusAxis orientation="left" scale="linear" angle={90} domain={[0, 5]} />
                                                            <Radar name="あなた" dataKey="level" stroke="#fc72cc" fill="#fccbcc" fillOpacity={0.3} />
                                                            <Radar name="標準" dataKey="standard_level" strokeDasharray="3 3" stroke="#21ff35" fillOpacity={0.0} />
                                                            <Legend />
                                                        </RadarChart>
                                                    </ResponsiveContainer>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography variant="h6">
                                                        所見
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        id="standard-multiline-static"
                                                        name="subjective_comment"
                                                        multiline
                                                        rows={4}
                                                        variant="outlined"
                                                        defaultValue={this.state.enquete_default_feedback}
                                                        onChange={props.handleChange}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography variant="h6">
                                                        総評
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-multiline-static"
                                                        name="total_comment"
                                                        multiline
                                                        rows={4}
                                                        variant="outlined"
                                                        onChange={props.handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                        <Paper className={classes.paper}>
                                            <Grid item xs={12}>
                                                <div
                                                    className="ag-theme-material"
                                                    style={{
                                                        height: 400,
                                                    }}
                                                >
                                                    <AgGridReact
                                                        columnDefs={this.state.video_list_columns}
                                                        rowData={this.state.video_list}
                                                        enableMultiRowDragging={true}
                                                        onGridReady={(params) => {
                                                            this.gridApiVideoList = params.api;
                                                        }}
                                                        loadingCellRendererParams
                                                        singleClickEdit={true}
                                                        onRowSelected={false}
                                                        onSelectionChanged
                                                        rowSelection="multiple"
                                                        overlayLoadingTemplate={overlayLoadingTemplate}
                                                        overlayNoRowsTemplate={overlayNoRowsTemplate}
                                                        onRowDataChanged={(params) => {
                                                            const allColumnIds = [];
                                                            const columnApi = params.columnApi
                                                            columnApi.getAllColumns().forEach(function (column) {
                                                                allColumnIds.push(column.colId);
                                                            });
                                                            columnApi.autoSizeColumns(allColumnIds, false);
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Paper>

                                        <Grid container justify="space-between" spacing={2}>
                                            <Button onClick={this.goBack} variant="outlined" color="primary" align="left">
                                                戻る
                                            </Button>
                                            <Button type="submit" variant="outlined" color="primary" align="right">
                                                登録
                                            </Button>
                                        </Grid>
                                    </div>
                                }
                                <Dialog
                                    open={this.state.alert_dialog_open}
                                    onClose={this.handleCloseAlertDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <MuiDialogTitle id="alert-dialog-title">{this.state.alert_dialog_title}</MuiDialogTitle>
                                    <MuiDialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {this.state.alert_dialog_message}
                                        </DialogContentText>
                                    </MuiDialogContent>
                                    <MuiDialogActions>

                                        <Button onClick={this.handleCloseAlertDialog} color="primary" autoFocus>
                                            確認
                                        </Button>
                                    </MuiDialogActions>
                                </Dialog>
                            </Container>
                        </div>
                    </form>
                )}
            />
        );
    }
}

export default withStyles(styles)(AdminFeedbackRegisterPage);

