import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AxiosBase from 'axios';

import ExcelJS from "exceljs";
import encoding from "encoding-japanese";

const BaseURL = process.env.REACT_APP_SERVER_URL;
const history = createBrowserHistory();

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});


//企業情報取得用
const GetPartnerCompanyListOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BaseURL}/admin/get_partner_company_list`,
}

//企業ユーザアンケート情報取得用
const GetPartnerUserListOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/get_partner_user_list`,
}

const DownloadQuestions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/download_questions`,
}

const styles = (theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 2,
        zIndex: theme.zIndex.drawer + 2000,
        color: '#fff',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


class CheckboxRenderer extends React.Component {

    constructor(props) {
        super(props);
        this.checkedHandler = this.checkedHandler.bind(this);
    }
    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.props.column.colId;
        console.log(this.props.node);
        this.props.node.setDataValue(colId, checked);
    }
    render() {
        return (
            <input type="checkbox" onClick={this.checkedHandler} checked={this.props.value} />
        );
    }
}

//AG-Gridデータ管理用カラム
//企業情報リスト
const company_columns = [
    { field: 'company_id', headerName: '企業番号', sortable: true, filter: true, resizable: true, checkboxSelection: false, },
    { field: 'company_name', headerName: '企業名', sortable: true, filter: true, resizable: true },
];

const company_enquete_list = [
    { field: 'company_id', headerName: '企業番号', sortable: true, filter: false, resizable: true, hide: true, },
    { field: 'company_name', headerName: '企業名', sortable: true, filter: false, resizable: true, hide: true, },
    { field: 'user_name', headerName: 'ユーザ名', sortable: true, filter: true, resizable: true, },
    { field: 'enquete_date', headerName: '回答年度', sortable: true, filter: true, resizable: true, },
    { field: 'enquete_data', headerName: 'アンケートデータ', sortable: true, filter: false, resizable: true, hide: true, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate = '<span style="ag-overlay-no-rows-center">データが存在しません</span>';


function createUserData(company_id, company_name, user_name, enquete_date, enquete_data) {
    return { company_id, company_name, user_name, enquete_date, enquete_data };
}

//ページの全体構造
class AdminEnquetePage extends React.Component {
    constructor(props) {
        super(props);

        //ステータス初期化
        this.state = {
            company_list_columns: company_columns,
            company_list: null,
            complete_load: false,

            frameworkComponents: {
                checkboxRenderer: CheckboxRenderer
            },

            company_enquete_list: company_enquete_list,

            company_enquete_open: false,

            company_enquete: null,
            company_id: null,
            company_name: null,
            date: null,

            backdrop_open: false,


            loading: false,
            error: null,

            selectDate: null,

            dialog_open: false,

            value: null,

        };
    }


    componentDidMount(props) {
        //企業情報リスト取得
        Axios(GetPartnerCompanyListOptions).then((res) => {
            if (res.data !== "Unauthorized") {
                const tempCompanyList = res.data;

                Object.keys(tempCompanyList).forEach(function (key) {
                    var company = tempCompanyList[key];
                    company.id = company._id;

                });

                const companyList = tempCompanyList;

                this.setState({
                    complete_load: true,
                    company_list: companyList,
                });

            } else {
                this.setState({
                    complete_load: true,
                })
                console.log("認証失敗")
            }
        }).catch(err => console.log(err));
    }

    //
    handleCloseCompanyEnqueteDialog = (event) => {
        this.setState({
            company_enquete_open: false,
            manager_list: null,
        });
    };

    //
    handleCellClicked = (event) => {

        const company_id = event.data.company_id;
        const company_name = event.data.company_name;

        console.log(company_id);
        console.log(company_name);


        if (event.column.getColId() === 'company_name') {

            this.setState({
                company_name: event.data.company_name,
            });

            const data = {
                company_id: company_id,
                company_name: company_name,
            }

            GetPartnerUserListOptions.data = data

            Axios(GetPartnerUserListOptions).then((res) => {

                if (res.data !== "Unauthorized") {

                    const tempUserList = res.data;

                    if (tempUserList.length === 0) {

                    } else {
                        const userlist = [];

                        // Object.keys(tempUserList).forEach(function (key) {
                        //     var user = tempUserList[key];
                        //     user.id = company._id;
                        //     user.id = company._id;
                        //     console.log(company);
                        // });

                        for (var a = 0; a < tempUserList.length; a++) {
                            const row = createUserData(
                                company_id,
                                company_name,
                                tempUserList[a].username,
                                tempUserList[a].answer_date,
                                tempUserList[a].answer.result.Stress_Level

                            )
                            userlist.push(row)
                        }



                        const companyEnqueteList = userlist;

                        this.setState({

                            company_enquete_open: true,
                            company_enquete: companyEnqueteList,
                            complete_load: true,

                        });
                        console.log(this.state.company_enquete);
                    }

                } else {
                    this.setState({
                        complete_load: false,
                    })

                    console.log("認証失敗")
                }
            }).catch(err => console.log(err));
        }
    }

    DownloadSetDate = (event) => {
        console.log("DownloadSetDate");
        this.setState({
            dialog_open: true,
        })
    }

    deleteVideoInfoDialogClose = (event) => {
        this.setState({
            dialog_open: false,
        });
    };



    DownloadServeyResultData = async () => {

        //全てのデータ
        // const rowDatas = [];
        // this.gridApiManagerList.forEachNode(node => { rowDatas.push(node.data) });
        // console.log(rowDatas)

        //選択データ
        const rowDatas = [];
        this.gridApiManagerList.forEachNodeAfterFilterAndSort(node => { rowDatas.push(node.data) });
        console.log(rowDatas)

        if (rowDatas.length == 0)
            return;

        console.log(rowDatas[0])
        const rowData = rowDatas[0]
        const fileName = rowDatas[0].company_name + rowDatas[0].enquete_date
        const first_enquete_data = rowData.enquete_data
        const columns = Object.keys(first_enquete_data);


        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet("sheet1");
        const worksheet = workbook.getWorksheet("sheet1");

        //
        const worksheet_columns = [
            { header: "企業名", key: "company_name" },
            { header: "ユーザ名", key: "user_name" },
            { header: "回答年度", key: "enquete_date" },
        ];

        columns.map((column) => {
            const column_data = {
                header: `${column}番目`, key: `${column}`
            }
            worksheet_columns.push(column_data);
        })

        console.log(worksheet_columns)

        worksheet.columns = worksheet_columns

        const result_data = []

        //rows
        for (var i = 0; i < rowDatas.length; i++) {
            const temporary_data = {};
            const question_result = rowDatas[i];

            const enquete_data = question_result.enquete_data;
            const columns = Object.keys(enquete_data);

            for (var j = 0; j < columns.length; j++) {
                const column = columns[j];
                temporary_data[`${column}`] = enquete_data[`${column}`]
            }

            temporary_data.company_name = question_result.company_name;
            temporary_data.user_name = question_result.user_name;
            temporary_data.enquete_date = question_result.enquete_date.split('-')[0] + "年" + question_result.enquete_date.split('-')[1] + "月";

            console.log(question_result.enquete_date)

            result_data.push(temporary_data)
            //console.log(temporary_data);
        }

        worksheet.addRows(result_data)


        const uint8Array = new Uint8Array(
            encoding.convert(await workbook.csv.writeBuffer(), {
                from: "UTF8",
                to: "SJIS"
            })
        )

        const blob = new Blob([uint8Array], { type: "application/octet-binary" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName + ".csv"; //フォーマットによってファイル拡張子を変えている
        a.click();
        a.remove();
    }

    handleChange = () => {
        console.log("date")
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Container maxWidth="md">
                    <Grid container spacing={6} justify="space-between">
                        <Grid item xs={12}>
                            <div
                                className="ag-theme-material"
                                style={{
                                    height: 400,
                                }}>
                                <AgGridReact
                                    columnDefs={this.state.company_list_columns}
                                    rowData={this.state.company_list}
                                    enableMultiRowDragging={true}
                                    onGridReady={(params) => {
                                        this.gridApiCompanyList = params.api
                                        this.gridApiCompanyColumns = params.columnApi
                                    }}
                                    loadingCellRendererParams
                                    singleClickEdit={true}
                                    frameworkComponents={this.state.frameworkComponents}
                                    overlayLoadingTemplate={overlayLoadingTemplate}
                                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    onCellClicked={this.handleCellClicked}
                                // onRowDataChanged={(params) => {
                                //     const allColumnIds = [];
                                //     this.gridApiCompanyColumns.getAllColumns().forEach(function (column) {
                                //         allColumnIds.push(column.colId);
                                //     });
                                //     this.gridApiCompanyColumns.autoSizeColumns(allColumnIds, false);
                                // }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" >
                                戻る
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
                <Backdrop className={classes.backdrop} open={this.state.backdrop_open} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* <CompanyEnquete /> */}

                <Dialog maxWidth="md" onClose={this.handleCloseCompanyEnqueteDialog} aria-labelledby="customized-dialog-title" open={this.state.company_enquete_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseCompanyEnqueteDialog}>
                        {this.state.company_name}アンケート一覧
                    </DialogTitle>
                    <DialogContent dividers>
                        <Button onClick={this.DownloadServeyResultData} color="primary">ダウンロード</Button>
                        <div
                            className="ag-theme-material"
                            style={{
                                width: 800,
                                height: 400,
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.company_enquete_list}
                                rowData={this.state.company_enquete}
                                enableMultiRowDragging={true}
                                onGridReady={(params) => {
                                    this.gridApiManagerList = params.api
                                    this.gridApiManagerListColumns = params.columnApi
                                }}
                                loadingCellRendererParams
                                singleClickEdit={true}
                                rowSelection="multiple"
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                overlayNoRowsTemplate={overlayNoRowsTemplate}
                                frameworkComponents={this.state.frameworkComponents}
                            // onRowDataChanged={(params) => {
                            //     const allColumnIds = [];
                            //     this.gridApiManagerListColumns.getAllColumns().forEach(function (column) {
                            //         allColumnIds.push(column.colId);
                            //     });
                            //     this.gridApiManagerListColumns.autoSizeColumns(allColumnIds, false);
                            // }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AdminEnquetePage);