import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import ViewList from '@material-ui/icons/ViewList';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import GroupAdd from '@material-ui/icons/GroupAdd';
import Business from '@material-ui/icons/Business';
import Group from '@material-ui/icons/Group';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PolicyIcon from '@material-ui/icons/Policy';

import { withStyles, useTheme } from '@material-ui/core/styles';

// import Moment, { now } from 'moment';
// import Image from "material-ui-image";
import LogoImage from '../img/logo.png';

import { Authority } from '../_helper/Authority';
import AuthService from '../_services/authService';

import { createBrowserHistory } from 'history';
import { MenuItem } from '@material-ui/core';

const history = createBrowserHistory();

const drawerWidth = 280;

const adminMenuList = ['利用企業管理', '利用ユーザ管理', '毛髪情報管理', 'フィードバック管理', '動画管理'];
const managerMenuList = ['利用ユーザ管理', 'フィードバック管理'];
const userMenuList = ['フィードバックシート一覧', '質問表入力'];

const styles = ((theme) => ({
  toolbar: {
    borderBottom: `1px solid #6E6E6E`,
    color: '#6E6E6E'
  },
  toolbarTitle: {
    flex: 1,
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer_open: false,
      authority: null,
    }
  }

  componentDidMount() {
    const sessionInfo = AuthService.currentUserValue;
    const authority = sessionInfo.authority;
    this.setState({
      authority: authority,
    })
    // console.log(sessionInfo);
  }

  toggleDrawer = (event) => {
    console.log(event);
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    };

    this.setState({
      drawer_open: false,
    });
  }

  handleDrawerOpen = (event) => {
    this.setState({
      drawer_open: true,
    });
  };

  handleDrawerClose = (event) => {
    this.setState({
      drawer_open: false,
    });
  };

  logout = () => {
    AuthService.logout();
    this.props.history.push('/signin');
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              // className={clsx(classes.menuButton, open && classes.hide)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>EVCARE</Typography>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.state.drawer_open}
          onClose={this.handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            role="presentation"
            onClick={this.toggleDrawer}
            onKeyDown={this.toggleDrawer}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
                {/* {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
              </IconButton>
            </div>

            <div>
              <ListItem button key="Home" onClick={() => this.props.history.push("/")}>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="ホーム" />
              </ListItem>
            </div>

            <Divider />

            {this.state.authority == "user_authority" &&
              <div>
                <ListItem button key="FeedbackList" onClick={() => this.props.history.push("/userfeedbacklist")}>
                  <ListItemIcon><ViewList /></ListItemIcon>
                  <ListItemText primary="フィードバックシート一覧" />
                </ListItem>

                <ListItem button key="AnswerSurvey" onClick={() => this.props.history.push("/userquestion")}>
                  <ListItemIcon><QuestionAnswer /></ListItemIcon>
                  <ListItemText primary="質問票入力" />
                </ListItem>
              </div>
            }

            {this.state.authority == "manager_authority" &&
              <div>
                <ListItem button key="ManageUser" onClick={() => this.props.history.push("/managerusermanager")}>
                  <ListItemIcon><GroupAdd /></ListItemIcon>
                  <ListItemText primary="利用ユーザ管理" />
                </ListItem>

                <ListItem button key="ManageFeedback" onClick={() => this.props.history.push("/managerfeedbackmanager")}>
                  <ListItemIcon><ViewList /></ListItemIcon>
                  <ListItemText primary="フィードバック管理" />
                </ListItem>

              </div>
            }

            {this.state.authority == "admin_authority" &&
              <div>
                <ListItem button key="ManageCompany" onClick={() => this.props.history.push("/admincompanymanager")}>
                  <ListItemIcon><Business /></ListItemIcon>
                  <ListItemText primary="利用企業管理" />
                </ListItem>

                <ListItem button key="ManageUser" onClick={() => this.props.history.push("/admincompanylist_usermanager")}>
                  <ListItemIcon><Group /></ListItemIcon>
                  <ListItemText primary="利用ユーザ管理" />
                </ListItem>

                <ListItem button key="ManageHairInfo" onClick={() => this.props.history.push("/admincompanylist_hairmanager")}>
                  <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                  <ListItemText primary="毛髪情報管理" />
                </ListItem>

                <ListItem button key="ManageFeedback" onClick={() => this.props.history.push("/admincompanylist_feedbackmanager")}>
                  <ListItemIcon><PlaylistAdd /></ListItemIcon>
                  <ListItemText primary="フィードバック管理" />
                </ListItem>

                <ListItem button key="ManageVideo" onClick={() => this.props.history.push("/adminvideolist")}>
                  <ListItemIcon><VideoLibrary /></ListItemIcon>
                  <ListItemText primary="動画管理" />
                </ListItem>

                <ListItem button key="ManageEnquete" onClick={() => this.props.history.push("/adminenquete")}>
                  <ListItemIcon><FileCopyIcon /></ListItemIcon>
                  <ListItemText primary="アンケート結果出力" />
                </ListItem>
              </div>
            }

            <Divider />
            <ListItem button key="PrivacyPolicy" onClick={() => this.props.history.push("/privacypolicy")}>
              <ListItemIcon><PolicyIcon /></ListItemIcon>
              <ListItemText primary="プライバシーポリシー" />
            </ListItem>

            <ListItem button key="Logout" onClick={this.logout}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItem>
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

// export default Header;
export default withStyles(styles)(Header);