

import { useState, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs'
import AxiosBase from 'axios';


const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',   
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));
 
const authenticationService = {
    login,
    logout,
    getUser,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { 
        // console.log(currentUserSubject);
        const user = getUser();
        // console.log("currentUserValue");
        // console.log(user);

        return currentUserSubject.value;
    },
}

async function login(username, password){
    return await Axios({
        method: 'POST',
        data: {
          username: username,
          password: password,
        },
        withCredentials: true,
        url: `${BaseURL}/login`,

    }).then((res) => {
        console.log(res);

        const data = res.data;
        if(data.result === "success"){
            const user = data.user;

            sessionStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        }
    });
    
    //AXIOS以外の方法
    /*
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        body: JSON.stringify({ username, password })
    }

    return fetch(`${BaseURL}/login`, requestOptions)
        .then(handleResponse)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem('currentUser', JSON.stringify(response));
            currentUserSubject.next(response);

            return response;
        });
    */
}

async function logout(){
    await Axios({
        method: 'GET',
        withCredentials: true,
        url: `${BaseURL}/logout`,

    }).then((res) => {
        // console.log("logout");
        // console.log(res.data);

        sessionStorage.removeItem('currentUser');
        
        currentUserSubject.next(null);
    });   
}

function handleResponse(response) {
    
    return response.text().then(text => {
        console.log(text);
        const data = text !== "Unauthorized" && JSON.parse(text).user;

        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getUser(){
    return Axios({
        method: 'GET',
        withCredentials: true,
        url: `${BaseURL}/get_user_info`,

    }).then((res) => {
        // console.log("getUser");
        const user = res.data;
        
        return user;
    });    
}

export default authenticationService;