import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, Paper, Box, Typography, Toolbar } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  footer: {
    // bottom: 0,
    // backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(2),
    // borderTop: "1px solid #F9A4BD",
    align: "center",
    textAlign: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}{new Date().getFullYear()}{' '}
      <Link color="inherit" href="https://evcare.co.jp/">
      株式会社イヴケア
      </Link>{' '}
      All Rights Reserved
      {'.'}
    </Typography>
  );
} 
 
export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Copyright />
     </footer> 
  );
}
