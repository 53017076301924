import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


import AxiosBase from 'axios';


const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

//AXIOS Options
//ユーザリスト取得用
const GetUserListOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BaseURL}/admin/get_selected_user_list`,
}

//ユーザ毛髪データ取得用
const GetUserHairInfosOptions = {
    method: 'GET',
    params: null,
    withCredentials: true,
    url: `${BaseURL}/admin/get_user_hair_infos`,
};

//ユーザ毛髪データ追加用
const AddUserHairInfoOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/add_user_hair_info`,
}

//ユーザ毛髪データ更新用
const UpdateUserHairInfosOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/update_user_hair_infos`,
}

//ユーザ毛髪データ削除用
const DeleteUserHairInfosOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/delete_user_hair_infos`,
};

//ユーザ毛髪データ更新用
const UpdateHairStressLevelOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/update_hair_stress_level`,
}

const styles = (theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: 'none',
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 2,
        zIndex: theme.zIndex.drawer + 2000,
        color: '#fff',
    },
}));


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const cellEditorParamsGender = { values: ["male", "female",] };
const refDataGender = { male: "男性", female: "女性", };

const cellEditorParamsAgeGroup = { values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100,] };
const refDataAgeGroup = { 0: "0代", 10: "10代", 20: "20代", 30: "30代", 40: "40代", 50: "50代", 60: "60代", 70: "70代", 80: "80代", 90: "90代", 100: "100代", };


//データ管理用カラム
//ユーザリストカラム
const partner_user_columns = [
    // { field: 'detail_info.company_info.company_id', headerName: '企業番号', /*width: 150,*/ sortable:true, filter:true, hide:true, },
    // { field: 'detail_info.company_info.company_name', headerName: '企業名', /*width: 150,*/ sortable:true, filter:true, /*rowGroup:true*/},
    { field: 'username', headerName: 'ユーザ名', /*width: 150,*/ sortable: true, filter: true, resizable: true, },
    { field: 'lastname', headerName: '姓', /*width: 150,*/ sortable: true, filter: true, resizable: true, },
    { field: 'firstname', headerName: '名前', /*width: 150,*/ sortable: true, filter: true, resizable: true, },
];

//ユーザ毛髪データカラム
const user_hair_info_columns = [
    { field: 'username', headerName: 'ユーザ名', sortable: true, filter: true, resizable: true, hide: true, },
    { field: 'pertinent_month', headerName: '年月', sortable: true, filter: true, resizable: true, checkboxSelection: true, },
    { field: 'hair_info.cortidol', headerName: 'Cortidol', sortable: true, filter: true, resizable: true, editable: true, cellRenderer: formatDouble, },
    { field: 'hair_info.dhea', headerName: 'DHEA', sortable: true, filter: true, resizable: true, editable: true, cellRenderer: formatDouble, },
    { field: 'cortidol_level', headerName: 'Cortidolレベル', sortable: true, filter: true, resizable: true, editable: false, },
    { field: 'dhea_level', headerName: 'DHEAレベル', sortable: true, filter: true, resizable: true, editable: false, },
    { field: 'cortidol_dhea_level', headerName: 'Cortidol/DHEAレベル', sortable: true, filter: true, resizable: true, editable: false, },
];


const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate = '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

const textLabels = {
    LABEL_INPUT_PERTINENT_MONTH: "該当年月",
    LABEL_INPUT_DHEA: "DHEA",
    LABEL_INPUT_CORTIDOL: "Cortidol",
}

function formatDouble(data) {
    // console.log(data.value);
    return parseFloat(data.value);
}

function checkDate(strDate) {
    if (!strDate.match(/^\d{4}\-\d{2}$/)) {
        return false;
    }

    const y = strDate.split("-")[0];
    const m = strDate.split("-")[1] - 1;
    const date = new Date(y, m);

    if (date.getFullYear() != y || date.getMonth() != m) {
        return false;
    }
    return true;
}


class AdminHairManagerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            complete_load: false,
            //取引先情報
            partner_user_columns: partner_user_columns,
            partner_user_infos: null,
            selected_username: null,
            //各ユーザ毛髪データ関連
            user_hair_manager_open: false,
            user_hair_info_columns: user_hair_info_columns,
            user_hair_infos: null,

            add_user_hair_info_dialog_open: false,

            add_pertinent_month: "",
            add_cortidol: "",
            add_dhea: "",

            alert_dialog_title: "",
            alert_dialog_message: "",
            alert_dialog_open: false,

            backdrop_open: false,
        };

        // this.showUserHairManager = this.showUserHairManager.bind(this);
    }

    componentDidMount(props) {
        const { classes } = this.props;
        const company_id = this.props.location.state.company_id;
        const company_name = this.props.location.state.company_name;

        console.log(`company_id:${company_id}`);
        console.log(`company_name:${company_name}`);

        const params = {
            company_id: company_id,
            company_name: company_name,
        }
        GetUserListOptions.params = params;

        //ユーザ情報リスト取得
        Axios(GetUserListOptions).then((res) => {
            if (res.data === "Unauthorized") {
                this.setState({
                    complete_load: true,
                })

                console.log("認証失敗");
            } else if (res.data === "") {

            } else {
                const tempUserList = res.data;

                Object.keys(tempUserList).forEach(function (key) {
                    var user = tempUserList[key];
                    user.id = user._id;
                    console.log(user);
                });

                const userList = tempUserList;

                this.setState({
                    complete_load: true,
                    partner_user_infos: userList,
                });
            }
        }).catch(err => {
            console.log(err)
        });
    }

    showUserHairManager = (event) => {
        const rowData = event.data;

        const params = {
            username: rowData.username,
        }
        GetUserHairInfosOptions.params = params;

        Axios(GetUserHairInfosOptions).then((res) => {
            // console.log(res.data);
            const result = res.data;

            this.setState({
                selected_username: rowData.username,
                // user_hair_infos: res.data,
                user_hair_infos: result.data,
            });

            // if(res.data === "Unauthorized"){
            //     this.setState({
            //         complete_load: true, 
            //     })

            //     console.log("認証失敗");
            // }else if(res.data === ""){

            // }else{         
            // }
        }).catch(err => {
            console.log(err);
        });


        this.setState({
            user_hair_manager_open: true,
        });
    }

    showAddUserHairInfoDialog = () => {
        this.setState({
            add_user_hair_info_dialog_open: true,

        });
    }

    showAlertDialog = (title, message) => {
        this.setState({
            alert_dialog_title: title,
            alert_dialog_message: message,
            alert_dialog_open: true,
        })
    }

    showBackdrop = () => {
        this.setState({
            backdrop_open: true,
        })
    }

    addUserHairInfo = () => {
        const add_pertinent_month = this.state.add_pertinent_month;
        const add_cortidol = this.state.add_cortidol;
        const add_dhea = this.state.add_dhea;

        if (add_pertinent_month === "" || add_cortidol === "" || add_dhea === "") {
            this.showAlertDialog("お知らせ", "情報記入欄に空欄があります");
            return;
        } else if (!checkDate(add_pertinent_month)) {
            this.showAlertDialog("お知らせ", "年月フォーマットではありません");
            return;
        } else if (isNaN(add_cortidol) || isNaN(add_dhea)) {
            this.showAlertDialog("お知らせ", "CortidolまたはDHEAが数値ではありません");
            return;
        }

        const username = this.state.selected_username;
        const add_double_cortidol = parseFloat(add_cortidol);
        const add_double_dhea = parseFloat(add_dhea);
        const add_pertinent_date = new Date(add_pertinent_month);
        const nowDate = new Date();

        const newRowData = {
            username: username,
            regist_date: nowDate,
            pertinent_month: add_pertinent_month,
            pertinent_date: add_pertinent_date,
            hair_info: {
                cortidol: add_double_cortidol,
                dhea: add_double_dhea,
            }
        };

        AddUserHairInfoOptions.data = newRowData;
        this.showBackdrop();
        Axios(AddUserHairInfoOptions).then((res) => {
            console.log(res);
            const result = res.data.result;

            if (result == "success") {
                const newHairInfo = res.data.data;

                const newHairInfoList = this.state.user_hair_infos.slice();
                const newId = this.state.user_hair_infos.length === 0 ? 0 : this.state.user_hair_infos[this.state.user_hair_infos.length - 1].id + 1;
                newHairInfo.id = newId;

                newHairInfoList.push(newHairInfo);
                this.setState({
                    user_hair_infos: newHairInfoList,
                    add_user_hair_info_dialog_open: false,
                });
                this.showAlertDialog("お知らせ", "追加を成功しました");
                this.handleCloseBackdrop();
                this.setState({
                    add_pertinent_month: "",
                    add_cortidol: "",
                    add_dhea: "",
                });
            } else if (result == "failed") {
                const reason = res.data.reason;
                if (reason == "User Hair Info Already Exists") {
                    this.showAlertDialog("お知らせ", "すでに存在する毛髪情報です");
                    this.handleCloseBackdrop();
                }
            }
        }).catch(err => {
            console.log(err);
            this.showAlertDialog("お知らせ", "毛髪情報追加に失敗しました");
            this.handleCloseBackdrop();
        });
    }

    updateUserHairInfos = () => {
        const rowDatas = [];
        const gridApiData = this.gridApiUserHair;

        gridApiData.forEachNode(node => {
            const cortidol = node.data.hair_info.cortidol;
            const dhea = node.data.hair_info.dhea;

            node.data.hair_info.cortidol = parseFloat(cortidol);
            node.data.hair_info.dhea = parseFloat(dhea);
            rowDatas.push(node.data)
        });
        // console.log(rowDatas);

        const username = this.state.selected_username;
        const data = {
            username: username,
            hair_infos: rowDatas,
        }
        console.log(data);

        UpdateUserHairInfosOptions.data = data;

        this.showBackdrop();
        Axios(UpdateUserHairInfosOptions).then((res) => {
            const responseResult = res.data;
            if (responseResult.result == "success") {
                this.showAlertDialog("お知らせ", "更新を成功しました");
            } else {
                this.showAlertDialog("お知らせ", "更新を失敗しました");
            }
            this.handleCloseBackdrop();
        }).catch(err => {
            console.log(err);
            this.showAlertDialog("お知らせ", "更新を失敗しました");
            this.handleCloseBackdrop();
        });
    }

    updateHairStressLevels = () => {
        const rowDatas = [];
        const gridApiData = this.gridApiUserHair;

        const selectedNodes = this.gridApiUserHair.getSelectedNodes();
        const selectedDatas = selectedNodes.map((node) => {
            const cortidol = node.data.hair_info.cortidol;
            const dhea = node.data.hair_info.dhea;

            node.data.hair_info.cortidol = parseFloat(cortidol);
            node.data.hair_info.dhea = parseFloat(dhea);
            return node.data;
        });
        // console.log(selectedData);

        // gridApiData.forEachNode(node => {
        //     const cortidol = node.data.hair_info.cortidol;
        //     const dhea = node.data.hair_info.dhea;

        //     node.data.hair_info.cortidol = parseFloat(cortidol);
        //     node.data.hair_info.dhea = parseFloat(dhea);
        //     rowDatas.push(node.data)
        // });

        if (selectedDatas.length == 0) {
            this.showAlertDialog("お知らせ", "項目が選択されていません")
            return;
        }

        const username = this.state.selected_username;
        const data = {
            username: username,
            hair_infos: selectedDatas,
        }

        UpdateHairStressLevelOptions.data = data;

        this.showBackdrop();
        Axios(UpdateHairStressLevelOptions).then((res) => {
            const responseResult = res.data;
            if (responseResult.result == "success") {
                const updatedDatas = responseResult.data;

                selectedNodes.map((node) => {
                    const nodeData = node.data;
                    updatedDatas.map((updatedData) => {
                        if (nodeData.pertinent_month == updatedData.pertinent_month) {
                            node.setDataValue("cortidol_level", updatedData.cortidol_level);
                            node.setDataValue("dhea_level", updatedData.dhea_level);
                            node.setDataValue("cortidol_dhea_level", updatedData.cortidol_dhea_level);
                        }
                    });
                })
                this.showAlertDialog("お知らせ", "更新を成功しました");
            } else {
                this.showAlertDialog("お知らせ", "更新を失敗しました");
            }
            this.handleCloseBackdrop();
        }).catch(err => {
            console.log(err);
            this.showAlertDialog("お知らせ", "更新を失敗しました");
            this.handleCloseBackdrop();
        });
    }

    deleteUserHairInfos = () => {
        const selectedNodes = this.gridApiUserHair.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);

        if (selectedData.length == 0) {
            this.showAlertDialog("お知らせ", "項目が選択されていません")
            return;
        }

        const data = {
            hair_infos: selectedData,
        }

        DeleteUserHairInfosOptions.data = data;

        this.showBackdrop();
        Axios(DeleteUserHairInfosOptions).then((res) => {
            const responseResult = res.data;

            if (responseResult.result == "success") {
                window.location.reload();
                //this.showAlertDialog("お知らせ", "削除に成功しました");
                //this.gridApiUserHair.applyTransaction({remove:selectedData});
            } else if (responseResult.result == "failed") {
                this.showAlertDialog("お知らせ", "削除に失敗しました");
            }

            this.handleCloseBackdrop();
        }).catch(err => {
            this.showAlertDialog("お知らせ", "削除に失敗しました");
            this.handleCloseBackdrop();
        });
    }

    handleCloseUserHairManager = (event) => {

        this.setState({
            user_hair_manager_open: false,
            user_hair_infos: null,
        });
    };

    handleCloseAddUserHairInfoDialog = (event) => {
        this.setState({
            add_user_hair_info_dialog_open: false,
        });
    };

    handleCloseAlertDialog = (event) => {
        this.setState({
            alert_dialog_open: false,
        });
    };

    handleCloseBackdrop = () => {
        this.setState({
            backdrop_open: false,
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Container maxWidth="md">
                    <Grid container spacing={2} justify="space-between">
                        {/* <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                InputProps={{readOnly: true,}}
                                size="small"                                
                                fullWidth={true}
                            />           
                            <input accept="image/*" id="contained-button-file" multiple type="file" className={classes.input} />
                            <label htmlFor="contained-button-file">
                                <Button variant="outlined" component="span" color="primary" InputProps={{ readOnly: true, }} fullWidth={true} >
                                    参照
                                </Button>
                            </label>
                            <input accept="image/*" id="contained-button-file" multiple type="file" className={classes.input} />
                            <label htmlFor="contained-button-file">
                                <Button variant="outlined" component="span" color="primary" InputProps={{ readOnly: true, }} fullWidth={true} >
                                    ファイル取り込み
                                </Button>
                            </label>
                        </Grid> */}

                        <Grid item xs={12}>
                            <div
                                className="ag-theme-material"
                                style={{
                                    // width:800, 
                                    height: 400,
                                }}
                            >
                                <AgGridReact
                                    columnDefs={this.state.partner_user_columns}
                                    rowData={this.state.partner_user_infos}
                                    onRowDoubleClicked={this.showUserHairManager}
                                    //   rowSelection="multiple"
                                    enableMultiRowDragging={true}
                                    onGridReady={(params) => {
                                        this.gridApi = params.api
                                    }}
                                    overlayLoadingTemplate={overlayLoadingTemplate}
                                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    onRowDataChanged={(params) => {
                                        const allColumnIds = [];
                                        const columnApi = params.columnApi
                                        columnApi.getAllColumns().forEach(function (column) {
                                            allColumnIds.push(column.colId);
                                        });
                                        columnApi.autoSizeColumns(allColumnIds, false);
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" >
                                戻る
                            </Button>
                        </Grid>
                    </Grid>
                </Container>


                {/* 各ユーザの毛髪データ管理ダイアログ */}
                <Dialog maxWidth="md" onClose={this.handleCloseUserHairManager} aria-labelledby="customized-dialog-title" open={this.state.user_hair_manager_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseUserHairManager}>
                        ユーザ毛髪情報管理
                    </DialogTitle>
                    <DialogContent dividers>
                        <Button onClick={this.showAddUserHairInfoDialog} color="primary">毛髪情報追加</Button>
                        <Button onClick={this.deleteUserHairInfos} color="primary">毛髪情報削除</Button>
                        <Button onClick={this.updateUserHairInfos} color="primary">毛髪一覧更新</Button>
                        <Button onClick={this.updateHairStressLevels} color="primary">毛髪ストレス演算</Button>
                        <div
                            className="ag-theme-material"
                            style={{
                                width: 800,
                                height: 400,
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.user_hair_info_columns}
                                rowData={this.state.user_hair_infos}
                                enableMultiRowDragging={true}
                                onGridReady={(params) => {
                                    this.gridApiUserHair = params.api;
                                }}
                                rowSelection="multiple"
                                loadingCellRendererParams
                                singleClickEdit={true}
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                overlayNoRowsTemplate={overlayNoRowsTemplate}
                                onRowDataChanged={(params) => {
                                    const allColumnIds = [];
                                    const columnApi = params.columnApi
                                    columnApi.getAllColumns().forEach(function (column) {
                                        allColumnIds.push(column.colId);
                                    });
                                    columnApi.autoSizeColumns(allColumnIds, false);
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog maxWidth="xs" onClose={this.handleCloseAddUserHairInfoDialog} aria-labelledby="customized-dialog-title" open={this.state.add_user_hair_info_dialog_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseAddUserHairInfoDialog}>
                        毛髪情報追加
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2} justify="space-between">
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    onChange={(e) => {
                                        this.setState({
                                            add_pertinent_month: e.target.value,
                                        })
                                    }}
                                    label={textLabels.LABEL_INPUT_PERTINENT_MONTH}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    onChange={(e) => {
                                        this.setState({
                                            add_cortidol: e.target.value,
                                        })
                                    }}
                                    label={textLabels.LABEL_INPUT_CORTIDOL}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    onChange={(e) => {
                                        this.setState({
                                            add_dhea: e.target.value,
                                        })
                                    }}
                                    label={textLabels.LABEL_INPUT_DHEA}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.addUserHairInfo} color="primary">
                            登録
                        </Button>
                        <Button onClick={this.handleCloseAddUserHairInfoDialog} color="primary">
                            戻る
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* 単純なお知らせのダイアログ */}
                <Dialog
                    open={this.state.alert_dialog_open}
                    onClose={this.handleCloseAlertDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle id="alert-dialog-title">{this.state.alert_dialog_title}</MuiDialogTitle>
                    <MuiDialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.alert_dialog_message}
                        </DialogContentText>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={this.handleCloseAlertDialog} color="primary" autoFocus>
                            確認
                        </Button>
                    </MuiDialogActions>
                </Dialog>

                <Backdrop className={classes.backdrop} open={this.state.backdrop_open} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default withStyles(styles)(AdminHairManagerPage);