import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AuthService from '../../_services/authService';

import AxiosBase from 'axios';
import { green } from '@material-ui/core/colors';

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }  

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

//AXIOS Options
//ユーザリスト取得用
const GetUserListOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BaseURL}/admin/get_selected_user_list`,
}

//ユーザ回答状況取得用
const GetUserAnswerStatusOptions = {
    method: 'GET',
    params: null,
    withCredentials: true,
    url: `${BaseURL}/admin/get_user_answer_status`,
};

//全てのフィードバックリスト取得用
const GetAllFeedbackListOptions = {
    method: 'GET',
    params: null,
    withCredentials: true,
    url: `${BaseURL}/admin/get_feedback_list`,
};

//登録しているフィードバックの公開・非公開更新用
const UpdateFeedbackReleaseOptions = {
    method: 'POST',
    data: null,
    withCredentials: true,
    url: `${BaseURL}/admin/update_feedback_release_status`,
};

const styles = (theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 6),
    },
    bottomButtons: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: 'none',
    },
    muiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const cellEditorParamsAnswerStatus = { values: [true, false] };
const refDataAnswerStatus = { true: "あり", false: "なし", };
const refDataReleaseStatus = { released: "公開中", private: "非公開" };
const cellStyleAnswerStatus = (data) => {
    return data.value ? { color: "green" } : { color: "red" };
}

class BtnCellRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        console.log(this.props.value);
        this.props.clicked(this.props.value);
    }
    render() {
        return (
            <Button onClick={this.btnClickedHandler} variant="outlined" color="primary" >Click Me!</Button>
        )
    }
}


//データ管理用カラム
//ユーザリストカラム
const partner_user_columns = [
    // { field: 'detail_info.company_info.company_id', headerName: '企業番号', /*width: 150,*/ sortable:true, filter:true, hide:true, },
    // { field: 'detail_info.company_info.company_name', headerName: '企業名', /*width: 150,*/ sortable:true, filter:true, /*rowGroup:true*/},
    { field: 'username', headerName: 'ユーザ名', /*width: 150,*/ sortable: true, filter: true, resizable: true, },
    { field: 'lastname', headerName: '姓', /*width: 150,*/ sortable: true, filter: true, resizable: true, },
    { field: 'firstname', headerName: '名前', /*width: 150,*/ sortable: true, filter: true, resizable: true, },
];

//毛髪情報・アンケート回答状態カラム
const user_answer_status_columns = [
    { field: 'username', headerName: 'ユーザ名', sortable: true, filter: true, resizable: true, hide: true, },
    { field: 'pertinent_month', headerName: '該当年月', sortable: true, filter: true, resizable: true, },
    // { field: 'hair_info_exist', headerName: '毛髪データ登録状態', sortable:true, filter:true, resizable:true, cellStyle: cellStyleAnswerStatus, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataAnswerStatus, },
    { field: 'hair_info_exist', headerName: '毛髪ストレス演算結果', sortable: true, filter: false, resizable: true, cellStyle: cellStyleAnswerStatus, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataAnswerStatus, },
    { field: 'survey_answer_status', headerName: 'アンケート回答状態', sortable: true, filter: false, resizable: true, cellStyle: cellStyleAnswerStatus, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataAnswerStatus, },
    { field: 'feedback_result_exist', headerName: '登録状態', sortable: true, filter: false, resizable: true, cellStyle: cellStyleAnswerStatus, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataAnswerStatus, },
    { field: 'feedback_release_status', headerName: '公開状態', sortable: true, filter: false, resizable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataReleaseStatus, },

];

//
const feedback_release_columns = [
    { field: 'pertinent_month', headerName: '該当年月', sortable: true, filter: true, checkboxSelection: true },
    { field: 'username', headerName: 'ユーザ名', sortable: true, filter: true, resizable: true, },
    { field: 'release_status', headerName: '公開状態', sortable: true, filter: false, resizable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAnswerStatus, refData: refDataReleaseStatus, },
];

const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate = '<span style="ag-overlay-no-rows-center">データが存在しません</span>';


class AdminFeedbackManagerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected_company_id: null,
            selected_company_name: null,

            complete_load: false,
            //取引先情報
            partner_user_columns: partner_user_columns,
            partner_user_infos: null,
            selected_username: null,

            //毛髪
            user_answer_status_manager_open: false,
            user_answer_status_columns: user_answer_status_columns,
            user_answer_status_infos: null,

            //フィードバック
            feedback_release_manager_open: false,
            feedback_release_columns: feedback_release_columns,
            feedback_release_infos: null,

            snackbar_message: "",
            snackbar_open: false,

            alert_dialog_open: false,
            alert_dialog_message: "",
            alert_dialog_title: "",

            update_release_reconfirm_dialog_open: false,
            update_release_reconfirm_dialog_message: "",
            update_release_reconfirm_dialog_title: "注意",


            frameworkBtnComponents: {
                btnCellRenderer: BtnCellRenderer
            },
        };

        // this.showUserHairManager = this.showUserHairManager.bind(this);
    }

    componentDidMount(props) {
        const { classes } = this.props;
        const company_id = this.props.location.state.company_id;
        const company_name = this.props.location.state.company_name;

        this.setState({
            selected_company_id: company_id,
            selected_company_name: company_name,
        })

        const params = {
            company_id: company_id,
            company_name: company_name,
        }

        GetUserListOptions.params = params;

        //ユーザ情報リスト取得
        Axios(GetUserListOptions).then((res) => {
            if (res.data === "Unauthorized") {
                this.setState({
                    complete_load: true,
                })

                console.log("認証失敗");
            } else if (res.data === "") {

            } else {
                const tempUserList = res.data;

                Object.keys(tempUserList).forEach(function (key) {
                    var user = tempUserList[key];
                    user.id = user._id;
                    console.log(user);
                });

                const userList = tempUserList;

                this.setState({
                    complete_load: true,
                    partner_user_infos: userList,
                });
            }
        }).catch(err => {
            console.log(err)
        });
    }

    showUserAnswerStatusManager = (event) => {
        const rowData = event.data;

        const params = {
            username: rowData.username,
        }
        GetUserAnswerStatusOptions.params = params;

        Axios(GetUserAnswerStatusOptions).then((res) => {
            console.log(res);

            if (res.data === "Unauthorized") {
                this.setState({
                    complete_load: true,
                })

                console.log("認証失敗");
            } else if (res.data === "") {

            } else {
                console.log(res.data);
                this.setState({
                    selected_username: rowData.username,
                    user_answer_status_infos: res.data,
                });
            }
        }).catch(err => {
            console.log(err);
        });


        this.setState({
            user_answer_status_manager_open: true,
        });
    }

    showFeedbackReleaseManager = (event) => {
        const params = {
            company_id: this.state.selected_company_id,
            company_name: this.state.selected_company_name,
        }

        GetAllFeedbackListOptions.params = params;

        Axios(GetAllFeedbackListOptions).then((res) => {
            console.log(res.data);
            this.setState({
                feedback_release_infos: res.data.feedback_result_list,
            });
        }).catch(err => {
            console.log(err);
        });


        this.setState({
            feedback_release_manager_open: true,
        });
    }

    showFeedbackRegister = (event) => {
        const rowAnswerStatus = event.data;
        if (!rowAnswerStatus.survey_answer_status && !rowAnswerStatus.hair_info_exist) {
            this.showAlertDialog("注意", "該当年月の毛髪情報とアンケート回答情報が存在しないため、フィードバック登録ができません");
        } else if (rowAnswerStatus.feedback_result_exist && rowAnswerStatus.feedback_release_status == "released") {
            this.showAlertDialog("注意", "フィードバックは公開中のため、編集できません");
        } else if (rowAnswerStatus.feedback_result_exist) {
            this.props.history.push({
                pathname: '/adminfeedbackupdate',
                state: {
                    username: event.data.username,
                    pertinent_month: rowAnswerStatus.pertinent_month,
                    answer_status: rowAnswerStatus,
                }
            });
        } else {
            this.props.history.push({
                pathname: '/adminfeedbackregister',
                state: {
                    username: event.data.username,
                    pertinent_month: rowAnswerStatus.pertinent_month,
                    answer_status: rowAnswerStatus,
                }
            });
        }
    }

    updateReleaseSelectedFeedback = (event) => {
        const selectedNodes = this.gridReleaseApi.getSelectedNodes();
        const selectedData = [];

        selectedNodes.map((node) => {
            if (node.data.release_status === "private") {
                node.setDataValue("release_status", "released");
                selectedData.push(node.data);
            }
        });

        if (selectedData.length == 0)
            return;

        UpdateFeedbackReleaseOptions.data = selectedData;

        Axios(UpdateFeedbackReleaseOptions).then((res) => {
            console.log(res);

            if (res.data === "Unauthorized") {
                this.setState({
                    complete_load: true,
                })

                console.log("認証失敗");
            } else if (res.data === "") {

            }

            this.setState({
                snackbar_open: true,
                snackbar_message: "公開しました"
            })
        }).catch(err => {
            console.log(err);
        });
    }

    updatePrivateSelectedFeedback = (event) => {
        const selectedNodes = this.gridReleaseApi.getSelectedNodes();
        const selectedData = [];

        selectedNodes.map((node) => {
            if (node.data.release_status === "released") {
                node.setDataValue("release_status", "private");
                selectedData.push(node.data);
            }
        });

        if (selectedData.length == 0)
            return;

        UpdateFeedbackReleaseOptions.data = selectedData;

        Axios(UpdateFeedbackReleaseOptions).then((res) => {
            console.log(res);

            if (res.data === "Unauthorized") {
                this.setState({
                    complete_load: true,
                })

                console.log("認証失敗");
            } else if (res.data === "") {

            }

            this.setState({
                snackbar_open: true,
                snackbar_message: "非公開しました"
            })
        }).catch(err => {
            console.log(err);
        });
    }

    showAlertDialog = (title, message) => {
        this.setState({
            alert_dialog_title: title,
            alert_dialog_message: message,
            alert_dialog_open: true,
        })
    }

    handleCloseAnswerStatusManager = (event) => {

        this.setState({
            user_answer_status_manager_open: false,
            user_answer_status_infos: null,
        });
    };

    handleCloseReleaseManager = (event) => {

        this.setState({
            feedback_release_manager_open: false,
            feedback_release_infos: null,
        });
    };

    handleCloseAlertDialog = (event) => {
        this.setState({
            alert_dialog_open: false,
        })
    }

    handleCloseUpdateReleaseReconfirmDialog = (event) => {

    }

    handleSnackbarClose = (event) => {
        this.setState({
            snackbar_message: "",
        });
    };

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {/* ユーザ別のフィードバック一覧 */}
                <Dialog maxWidth="md" onClose={this.handleCloseAnswerStatusManager} aria-labelledby="customized-dialog-title" open={this.state.user_answer_status_manager_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseAnswerStatusManager}>
                        フィードバック一覧
                    </DialogTitle>
                    <DialogContent dividers>
                        <div
                            className="ag-theme-material"
                            style={{
                                width: 800,
                                height: 400,
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.user_answer_status_columns}
                                rowData={this.state.user_answer_status_infos}
                                enableMultiRowDragging={true}
                                onGridReady={(params) => {
                                    this.gridApiUserHair = params.api;
                                }}
                                onRowDoubleClicked={this.showFeedbackRegister}
                                frameworkComponents={this.state.frameworkBtnComponents}
                                loadingCellRendererParams
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                overlayNoRowsTemplate={overlayNoRowsTemplate}
                                onRowDataChanged={(params) => {
                                    const allColumnIds = [];
                                    const columnApi = params.columnApi
                                    columnApi.getAllColumns().forEach(function (column) {
                                        allColumnIds.push(column.colId);
                                    });
                                    columnApi.autoSizeColumns(allColumnIds, false);
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions />
                </Dialog>

                <Dialog maxWidth="md" onClose={this.handleCloseReleaseManager} aria-labelledby="customized-dialog-title" open={this.state.feedback_release_manager_open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseReleaseManager}>
                        フィードバック公開管理
                    </DialogTitle>
                    <DialogContent dividers>
                        <Button onClick={this.updateReleaseSelectedFeedback} color="primary" >公開</Button>
                        <Button onClick={this.updatePrivateSelectedFeedback} color="primary" >非公開</Button>
                        <div
                            className="ag-theme-material"
                            style={{
                                width: 800,
                                height: 400,
                            }}
                        >

                            <AgGridReact
                                columnDefs={this.state.feedback_release_columns}
                                rowData={this.state.feedback_release_infos}
                                enableMultiRowDragging={true}
                                onGridReady={(params) => {
                                    this.gridReleaseApi = params.api
                                    this.gridReleaseColumns = params.columnApi
                                }}
                                rowSelection="multiple"
                                // onRowDoubleClicked={this.}
                                enableCellChangeFlash={true}
                                frameworkComponents={this.state.frameworkBtnComponents}
                                loadingCellRendererParams
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                overlayNoRowsTemplate={overlayNoRowsTemplate}
                                onRowDataChanged={(params) => {
                                    const allColumnIds = [];
                                    this.gridReleaseColumns.getAllColumns().forEach(function (column) {
                                        allColumnIds.push(column.colId);
                                    });
                                    this.gridReleaseColumns.autoSizeColumns(allColumnIds, false);
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions />
                </Dialog>

                <Container maxWidth="md">
                    <Grid container spacing={2} justify="space-between">
                        <Grid item xs={12}>
                            <Button onClick={this.showFeedbackReleaseManager} color="primary" >フィードバック公開管理</Button>
                            <div
                                className="ag-theme-material"
                                style={{
                                    // width:800, 
                                    height: 400,
                                }}
                            >
                                <AgGridReact
                                    columnDefs={this.state.partner_user_columns}
                                    rowData={this.state.partner_user_infos}
                                    onRowDoubleClicked={this.showUserAnswerStatusManager}
                                    //   rowSelection="multiple"
                                    enableMultiRowDragging={true}
                                    onGridReady={(params) => {
                                        this.gridApi = params.api
                                    }}
                                    overlayLoadingTemplate={overlayLoadingTemplate}
                                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    onRowDataChanged={(params) => {
                                        const allColumnIds = [];
                                        const columnApi = params.columnApi
                                        columnApi.getAllColumns().forEach(function (column) {
                                            allColumnIds.push(column.colId);
                                        });
                                        columnApi.autoSizeColumns(allColumnIds, false);
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Button onClick={this.goBack} variant="outlined" color="primary" >
                                戻る
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                <Dialog
                    open={this.state.alert_dialog_open}
                    onClose={this.handleCloseAlertDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle id="alert-dialog-title">{this.state.alert_dialog_title}</MuiDialogTitle>
                    <MuiDialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.alert_dialog_message}
                        </DialogContentText>
                    </MuiDialogContent>
                    <MuiDialogActions>

                        <Button onClick={this.handleCloseAlertDialog} color="primary" autoFocus>
                            確認
                        </Button>
                    </MuiDialogActions>
                </Dialog>

                <Dialog
                    open={this.state.update_release_reconfirm_dialog_open}
                    onClose={this.handleCloseUpdateReleaseReconfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle id="alert-dialog-title">{this.state.update_release_reconfirm_dialog_title}</MuiDialogTitle>
                    <MuiDialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.update_release_reconfirm_dialog_message}
                        </DialogContentText>
                    </MuiDialogContent>
                    <MuiDialogActions>

                        <Button onClick={this.handleCloseUpdateReleaseReconfirmDialog} color="primary" autoFocus>
                            確認
                        </Button>
                    </MuiDialogActions>
                </Dialog>

                {/* <Snackbar open={snackbar_open} autoHideDuration={2000} onClose={this.handleSnackbarClose}>
                    <Alert onClose={this.handleSnackbarClose} severity="success">
                        {this.state.snackbar_message}
                    </Alert>
                </Snackbar> */}
            </div>
        );
    }
}

export default withStyles(styles)(AdminFeedbackManagerPage);