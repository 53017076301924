import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { AgGridReact } from 'ag-grid-react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import AxiosBase from 'axios';

const BaseURL = process.env.REACT_APP_SERVER_URL;

const Axios = AxiosBase.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

//AXIOS Options
const GetRunningPartnerCompanyListOptions = {
  method: 'GET',
  withCredentials: true,
  url: `${BaseURL}/admin/get_running_partner_company_list`,
}

//基準毛髪データ取得用
const GetReferenceHairInfosOptions = {
  method: 'GET',
  params: null,
  withCredentials: true,
  url: `${BaseURL}/admin/get_reference_hair_infos`,
};

const styles = (theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
      <MuiDialogTitle disableTypography className={classes.muiDialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
          </IconButton>
      ) : null}
      </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
      padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
      margin: 0,
      padding: theme.spacing(1),
  },
}))(MuiDialogActions);

//ドロップダウンの表記フォーマット設定
//企業側のサービス「稼動・停止」を表示
const cellEditorParamsStatus = { values: ["running", "stopped"] };
const refDataStatus = { running: "稼働中", stopped: "停止中" };

const cellEditorParamsGender = { values: ["male", "female",] };
const refDataGender = { male:"男性", female:"女性", };

const cellEditorParamsAgeGroup = { values: [0, 10, 20, 30, 40, 50,60, 70, 80, 90, 100,] };
const refDataAgeGroup = {0:"0代", 10:"10代", 20:"20代", 30:"30代", 40:"40代", 50:"50代", 60:"60代", 70:"70代", 80:"80代", 90:"90代", 100:"100代", };


//AG-Gridデータ管理用カラム
//企業情報リスト
const company_columns = [
  { field: 'company_id', headerName: '企業番号', sortable: true, filter: true, resizable: true, },
  { field: 'company_name', headerName: '企業名', sortable: true, filter: true, resizable: true, },
  { field: 'status', headerName: 'ステータス', sortable: true, filter: false, resizable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsStatus, refData: refDataStatus },
];

//基準毛髪データカラム
const reference_hair_info_columns = [
  { field: 'gender', headerName: '性別', sortable:true, filter:true, resizable:true, editable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsGender, refData: refDataGender, },
  { field: 'age_group', headerName: '年代', sortable:true, filter:true,  resizable:true, editable:true, cellEditor: 'agSelectCellEditor', cellEditorParams: cellEditorParamsAgeGroup, refData: refDataAgeGroup},
  { field: 'cortidol', headerName: 'Cortidol', sortable:true, filter:true, resizable:true, editable:true, hide:true, cellRenderer: formatDouble, },
  { field: 'dhea', headerName: 'DHEA', sortable:true, filter:true, resizable:true, editable:true, hide:true, cellRenderer: formatDouble, },
  { field: 'average_cortidol', headerName: '平均Cortidol', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
  { field: 'average_dhea', headerName: '平均DHEA', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
  { field: 'sd_cortidol', headerName: 'SD Cortidol', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
  { field: 'sd_dhea', headerName: 'SD DHEA', sortable:true, filter:true, resizable:true, editable:true, cellRenderer: formatDouble, },
];


const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">読み込み中</span>';
const overlayNoRowsTemplate =  '<span style="ag-overlay-no-rows-center">データが存在しません</span>';

function formatDouble(data) {
  // console.log(data.value);
  return parseFloat(data.value);
}

//ページの全体構造
class AdminCompanyList_FeedbackManagerPage extends React.Component {
  constructor(props) {
    super(props);

    //ステータス初期化
    this.state = {
      columnDefs: company_columns,
      rowData: null,
      complete_load: false,
      
      //基準毛髪データ関連
      reference_hair_manager_open: false,
      reference_hair_data_columns: reference_hair_info_columns,
      reference_hair_data: null,
      
    };
  }

  componentDidMount(props) {
    Axios(GetRunningPartnerCompanyListOptions).then((res) => {
      if (res.data !== "Unauthorized") {
        const tempCompanyList = res.data;

        Object.keys(tempCompanyList).forEach(function (key) {
          var company = tempCompanyList[key];
          company.id = company._id;
          console.log(company);
        });

        const companyList = tempCompanyList;

        this.setState({
          complete_load: true,
          rowData: companyList,
        });
        console.log(this.state.rowData);
      } else {
        this.setState({
          complete_load: true,
        })
        console.log("認証失敗")
      }
    }).catch(err => console.log(err));
  }

  componentWillUnmount(props){
    
  }

  showAdminFeedbackManager = (event) =>  {
    const rowAnswerStatus = event.data;
    this.props.history.push({
        pathname:'/adminfeedbackmanager',
        state: {
            company_id:event.data.company_id,
            company_name:event.data.company_name,
        }
    }); 
  }

  showReferenceHairManager = () => {
      Axios(GetReferenceHairInfosOptions).then((res) => {
          console.log(res);

          this.setState({
              reference_hair_data: res.data,
          })
      }).catch(err => { 
          console.log(err)
      
      });    

      this.setState({
          reference_hair_manager_open:true,
      })
  };

  
  handleCloseReferenceHairManager = (event) => {      
    this.setState({
        reference_hair_manager_open: false,
        reference_hair_data: null,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={12}>
              {/* <Button onClick={this.showReferenceHairManager} color="primary">基準毛髪データ管理</Button> */}
              <div
                className="ag-theme-material"
                style={{
                  height: 400,
                }}>
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  enableMultiRowDragging={true}
                  onGridReady={(params) =>{ 
                    this.gridApiCompanyList = params.api
                  }}
                  loadingCellRendererParams
                  singleClickEdit={true}
                  overlayLoadingTemplate={overlayLoadingTemplate}
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                  onRowClicked={this.showAdminFeedbackManager}
                  onRowDataChanged={(params) => {
                    const allColumnIds = [];
                    const columnApi = params.columnApi
                    columnApi.getAllColumns().forEach(function (column) {
                      allColumnIds.push(column.colId);
                    });
                    columnApi.autoSizeColumns(allColumnIds, false);
                  }}                              
                />                
              </div>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={this.goBack} variant="outlined" color="primary" >
                戻る
                  </Button>
            </Grid>
            <Grid item xs={9} />
          </Grid>
        </Container>
        {/* 基準毛髪データ管理ダイアログ */}
        <Dialog maxWidth="md" onClose={this.handleCloseReferenceHairManager} aria-labelledby="customized-dialog-title" open={this.state.reference_hair_manager_open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseReferenceHairManager}>
              基準毛髪データ管理
          </DialogTitle>
          <DialogContent dividers>                        
              <Button onClick={this.addReferenceHairData} color="primary">基準毛髪データ追加</Button>
              {/* <Button onClick={this.deleteReferenceHairData} color="primary">基準毛髪データ削除</Button> */}
              <Button onClick={this.updateReferenceHairData} color="primary">基準毛髪データ更新</Button>
              <div 
                  className="ag-theme-material" 
                  style={{
                      width:800, 
                      height:400,
                  }}
              >
                  <AgGridReact
                      columnDefs={this.state.reference_hair_data_columns}
                      rowData={this.state.reference_hair_data}
                      enableMultiRowDragging={true}
                      onGridReady={(params) =>{ 
                          this.gridApiReferenceHair = params.api
                      }}
                      loadingCellRendererParams
                      singleClickEdit={true}     
                      overlayLoadingTemplate={overlayLoadingTemplate}
                      overlayNoRowsTemplate={overlayNoRowsTemplate}
                      onRowDataChanged={(params) => {
                        const allColumnIds = [];
                        const columnApi = params.columnApi
                        columnApi.getAllColumns().forEach(function (column) {
                          allColumnIds.push(column.colId);
                        });
                        columnApi.autoSizeColumns(allColumnIds, false);
                      }}                     
                  />
              </div>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.updateReferenceHairData} color="primary">
              更新
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AdminCompanyList_FeedbackManagerPage);